import { useState, useEffect, useContext } from 'react';
import {Card, CardHeader, CardContent, Alert, AlertTitle, Button, Tooltip} from '@mui/material';
import { DataGrid, GridToolbar, useGridApiRef } from '@mui/x-data-grid';		//https://mui.com/x/react-data-grid/ [MIT version]

import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import DeleteIcon from '@mui/icons-material/Delete';

import loadingGif from '../../Assets/loading.gif';
import Api from '../../Services/Api';
import {OPERATOR_ROLE} from '../../Config/constants.js';
import Functions from "../../Services/Functions";
import {PAGE_SIZE, pageSizeOptions} from '../../Config/constants.js';
import SpareUtilizeForm from "../Spare/SpareUtilizeForm";

import FastTextField from "../FastTextField";
import FastInfoBlock from "../FastInfoBlock";
import FormikValidation from "../FormikValidation";
import { ApWoContext } from '../../Contexts/context';

function RenderRepair(props) {
	let {user, appointment, setInEdit, isCancelled, isClosed} = props;

	const [apiResponse, setApiResponse] = useState(null)
	const [rows, setRows] = useState([]);
	const [onEdit, setOnEdit] = useState(false)
	const [loading, setLoading] = useState(false)
	const [displayAlert, setDisplayAlert] = useState(0)
	const [displayUtilize, setDisplayUtilize] = useState(0)
	const [lastUpdate, setLastUpdate] = useState(null);
	const apiRef = useGridApiRef();	//https://mui.com/x/api/data-grid/

	// eslint-disable-next-line
	const { showContextOverlayContext, setShowContextOverlayContext } = useContext(ApWoContext);

	const formik = useFormik({
		initialValues: {
			transfer_hours: apiResponse?.repair.transfer_hours ? moment(apiResponse?.repair.transfer_hours).format('HH:mm') : "",
			work_hours: apiResponse?.repair.work_hours ? moment(apiResponse?.repair.work_hours).format('HH:mm') : "",
			description: apiResponse?.repair.description ?? "",
			warranty: apiResponse?.repair.warranty ?? "",
			price: apiResponse?.repair.price ?? "",
			repair_date: apiResponse?.repair.repair_date ? moment(apiResponse?.repair.repair_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
			return_date: apiResponse?.repair.return_date ? moment(apiResponse?.repair.return_date).format('YYYY-MM-DD') : "",			
			notes: apiResponse?.repair.notes ?? "",			
			technician_id: apiResponse?.tecnician.id ?? (user.roles[0].name === OPERATOR_ROLE ? user.id : ""),
			appointment_id: appointment,
			_method: onEdit ? 'put' : 'post',
		},
		validate : values => {
			setInEdit(true);
			
            let errors = {}
            return errors
        },		
		validationSchema: Yup.object({
			transfer_hours:
				Yup.string(),
			work_hours:
				Yup.string(),
			description:
				Yup.string()
				.max(1000, 'Lunghezza massima 1000 caratteri')
				.required('Campo obbligatorio'),
			warranty:
				Yup.string()
				.max(255, 'Lunghezza massima 255 caratteri'),
			price:
				Yup.number()
				.min(0, 'Valore minimo 0')
				.max(999999.99, 'Valore massimo 999999.99'),
			repair_date:
				Yup.date()
				.required('Campo obbligatorio'),			
			return_date:
				Yup.date(),			
			notes:
				Yup.string()
				.max(1000, 'Lunghezza massima 1000 caratteri'),				
			technician_id:
				Yup.number()
				.integer("Valore non accettato")
				.min(1)
				.max(18446744073709551615, 'Valore non accettato')	//Bigint
				.required('Campo obbligatorio'),
			appointment_id :
				Yup.number()
				.integer("Valore non accettato")
				.min(1)
				.max(18446744073709551615, 'Valore non accettato')	//Bigint
				.required('Campo obbligatorio'),
		}),
		onSubmit: values => {
			try {
				setLoading(true);
				setDisplayAlert(0);
				let currEndpoint = onEdit ? `repair/${apiResponse.repair.id}` : 'repair';
				Api.post({endpoint: currEndpoint, body: values, contentType : 'application/json'})
					.then(response => {
						if (response.data.repair_id) {
							setOnEdit(true);
						}
						setDisplayAlert(1);
						let timer1 = setTimeout(() => {setLoading(false);clearTimeout(timer1)}, 3000);
						
						setLoading(false);
					})
					.catch(error => {
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						else {
							console.log(error);
							alert("Errore inatteso!");
						}
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}
			
			setInEdit(false);
		},
		enableReinitialize: true,
	});

	useEffect(() => {
		try {			
			Api.get({endpoint: `repair/work/${props.appointment}`, contentType : 'application/json'})
				.then(response => {				
					setApiResponse(response.data);
					setLastUpdate(Date.now());
					if (response.data.repair.id && response.data.repair.id !== -1)
						setOnEdit(true);
				})
				.catch(error => {
					console.log(error);
					setApiResponse(null);
					setDisplayAlert(-1);
				});

		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, [onEdit]);	// eslint-disable-line react-hooks/exhaustive-deps

	function newElement() {
		setDisplayUtilize(1);
	}

	function renderSpares() {
		const columns = [
			{
				field: 'quantity',
				headerName: 'Quantità',
				flex: 1,
				valueGetter: (params) => {
					return params.row.pivot.quantity ? Functions.toDecimal(params.row.pivot.quantity) : ""
				},
			},
			{
				field: 'code',
				headerName: 'Codice',
				flex: 1,
			},
			{
				field: 'short_description',
				headerName: 'Ricambio',
				flex: 2,
			},
			{
				field: 'price',
				headerName: 'Prezzo totale',
				flex: 1,
				type:'number',
				align:'right',
				valueGetter: (params) => {
					return params.row.pivot.price ? Functions.toDecimal(params.row.pivot.price) : ""
				},
			},
			{
				field: 'id',
				headerName: 'Azioni',
				flex: 1,
				valueGetter: (params) => {
					return params.row.pivot.id
				},
				renderCell: (params) => {
					function onDelete(e) {
						e.stopPropagation(); // don't select this row after clicking
						if (window.confirm("Confermare l'eliminazione DEFINITIVA dell'Assegnazione?")) {
							if (window.confirm("Eliminare l'Assegnazione?")) {
								try {
									Api.del({endpoint: `spare/utilize/${params.value}`, contentType : 'application/json'})
										.then(response => {
											setDisplayAlert(1);
											setLastUpdate(Date.now());
										})
										.catch(error => {
											console.log(JSON.stringify(error));
											setDisplayAlert(-1);
										});
								} catch (error) {
									console.log(JSON.stringify(error));
									setDisplayAlert(-1);
								}
							}
						}
					};
					return <>
						<Tooltip title="Elimina Assegnazione">
							<Button 
								onClick={onDelete}
								disabled={isClosed}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				}
			},
		]

		return (
			<>
				<h5 className="mt-3">Lista Materiali</h5>
				<DataGrid
					getRowId={(row) => row.idHash}
					//onRowDoubleClick={hook}
					sx={{ minHeight: '300px' }}
					rows={rows}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: PAGE_SIZE,
							},
						},
					}}
					apiRef={apiRef}
					slots={{
						toolbar: GridToolbar,
					}}
					slotProps={{
						columnsPanel: {
							disableHideAllButton: true,
						}
					}}
					pageSizeOptions={pageSizeOptions}
				/>

				<div className="mt-2">
					<Button
						variant="contained"
						onClick={newElement}
						disabled={isClosed}
					>
						Aggiungi Ricambio
					</Button>
				</div>
			</>
		);
	}

	const handleCloseUtilize = () => {
		setDisplayUtilize(0);
		setLastUpdate(Date.now());
	};

	useEffect(() => {
		if (onEdit && apiResponse.repair.id !== -1) {
			try {
				Api.get({endpoint: `spare/lists/${apiResponse.repair.id}/utilizes`, contentType : 'application/json'})				
					.then(response => {
						
						
						
						
						setRows(response.data.utilizes);
					})
					.catch(error => {
						console.log(error);
						setRows([]);
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				return (error.response ? error.response.status : null);
			}
		}
	}, [lastUpdate, onEdit]);	// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{isCancelled && (
				<Alert severity="error" sx={{marginBottom: '10px'}}>
					<AlertTitle>ATTENZIONE</AlertTitle>
					Stai lavorando su un appuntamento che è stato cancellato!
				</Alert>
			)}
			
			{isClosed && (
				<Alert severity="warning" sx={{marginBottom: '10px'}}>
					<AlertTitle>ATTENZIONE</AlertTitle>
					Stai guardando un appuntamento che è stato segnato come CHIUSO!
				</Alert>
			)}
			
			<Card>
				<CardHeader
					title="Ordine di servizio Riparazione"
					subheader="Visualizza e modifica i dati del servizio di Riparazione"
				/>
				<CardContent sx={{ pt: 0 }}>
					<form
						autoComplete="off"
						noValidate
						onSubmit={formik.handleSubmit}
					>
						<div key="transfer_hours_work_hours_technician" className="row styled">
							<div key="transfer_hours" className="col-md-3">
								<FastTextField loading={loading} formik={formik} label="Ore viaggio" field="transfer_hours" type="time" />
							</div>
							<div key="work_hours" className="col-md-3">
								<FastTextField loading={loading} formik={formik} label="Ore di lavoro" field="work_hours" type="time" />
							</div>
							<div key="technician" className="col-md-6">
								<FastInfoBlock label="Tecnico che ha effettuato l'intervento" content={`${apiResponse?.tecnician.surname} ${apiResponse?.tecnician.name}`} {...props} />
							</div>
						</div>
						<div key="r_description" className="row styled">
							<div key="description" className="col-md-12">
								<FastTextField loading={loading} required formik={formik} label="Descrizione dell'intervento" field="description"
									multiline
									rows={4}
								/>
							</div>
						</div>
						<div key="warranty_price_repair_date_return_date" className="row styled">
							<div key="warranty" className="col-md-3">
								<FastTextField loading={loading} formik={formik} label="Garanzia" field="warranty" />
							</div>
							<div key="price" className="col-md-3">
								<FastTextField loading={loading} formik={formik} label="Prezzo manodopera" field="price" type="number" />
							</div>
							<div key="repair_date" className="col-md-3">
								<FastTextField loading={loading} required formik={formik} label="Data intervento" field="repair_date" type="date" />
							</div>
							<div key="return_date" className="col-md-3">
								<FastTextField loading={loading} formik={formik} label="Data prossimo intervento" field="return_date" type="date" />
							</div>							
						</div>
						<div key="r_notes" className="row styled">
							<div key="warranty" className="col-md-12">
								<FastTextField loading={loading} formik={formik} label="Note di intervento" field="notes"
									multiline
									rows={4}
								/>
							</div>
						</div>
						<div className="row styled">
							<div className="col">
								{loading && (
										<>
											<>
											{displayAlert === 0 && (
												<img className="pl-3" src={loadingGif} alt="Loading..."/>
											)}
											</>
											<>
											{displayAlert === -1 && (
												<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
											)}
											</>
											<>
											{displayAlert === 1 && (
												<Alert severity="success">
													{onEdit ? (
														<>Dati modificati con successo!</>
													) : (
														<>Dati salvati con successo!</>
													)}
												</Alert>
											)}
											</>
										</>
								)}
								{!loading && (
									<Button
										variant="contained"
										type="submit"
										disabled={isClosed}
									>
										{onEdit ? (
											<>Modifica dati</>
										) : (
											<>Invia dati</>
										)}
									</Button>
								)}
							</div>
						</div>
					</form>
					<FormikValidation formik={formik} />
					{onEdit &&
						renderSpares()
					}
				</CardContent>
			</Card>
			<FormikValidation formik={formik} tech={true} />
			<SpareUtilizeForm
				open={displayUtilize}
				onClose={handleCloseUtilize}
				repair={apiResponse?.repair}
			/>			
		</>
	)
}

export default RenderRepair;
