import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Dialog, DialogActions, DialogContent,DialogContentText, DialogTitle, Button, Box, Tooltip, TextField, MenuItem } from '@mui/material';
import { DataGrid, itIT, GridToolbar, useGridApiRef } from '@mui/x-data-grid';		//https://mui.com/x/react-data-grid/ [MIT version]
import MoveDownIcon from '@mui/icons-material/MoveDown';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';

import Api from '../Services/Api';
import {PAGE_SIZE, pageSizeOptions} from '../Config/constants.js';

import { timeTableIntervals as optionsTimeTableIntervals } from '../Config/constants.js';

function Timetable(props) {
	const apiRef = useGridApiRef();	//https://mui.com/x/api/data-grid/
	const DEFAULT_VALUE = "30";

	const [rows, setRows] = useState([]);
	const [lastUpdate, setLastUpdate] = useState([]);
	const [displayDialog, setDisplayDialog] = useState(0);
	const [displayDialogContent, setDisplayDialogContent] = useState(null);
	const [days, setDays] = useState(DEFAULT_VALUE);
	const [fromDay, setFromDay] = useState(null);
	const [toDay, setToDay] = useState(null);

	let navigate = useNavigate();
	
	const deadlineTypes = {
		//Keep updated in app\Http\Controllers\AppointmentController.php
		ANN: 'Annotazione cliente',
		REP: 'Ritorno su riparazione',
		DEA: 'Prova fumi/Manutenzione',
	};

	const extraDataTags = {
		appointment_at: 'Data appuntamento|date',
		unique_code: 'Codice impianto|string',
		brand: 'Marca|string',
		notes: 'Note|string',
		ref_date: 'Data di riferimento|date',
		warning: 'Importante|bool',
		annotation: 'Note|string',
	};

	useEffect(() => {
		try {
			Api.get({endpoint: `timetable/lists/${days}`, contentType : 'application/json'})
			.then(response => {
				setRows(response.data.items);
				setFromDay(moment(response.data.from).format('DD/MM/YYYY'));
				setToDay(moment(response.data.to).format('DD/MM/YYYY'));
			})
			.catch(error => {
				console.log(JSON.stringify(error));
				displayDialog(-1);
			});
		} catch (error) {
			console.log(JSON.stringify(error));
			displayDialog(-1);
		}
	}, [lastUpdate, days]);	// eslint-disable-line react-hooks/exhaustive-deps

	const handleClose = () => {
		setDisplayDialog(0);
	};

	function arrayIntervalsResponse() {
		return (
			optionsTimeTableIntervals.map((interval) => (
				<MenuItem key={interval} value={interval}>
					{`${interval} giorni`}
				</MenuItem>
			))
		);
	}

	function extraFilter(selectedValue) {
		setDays(selectedValue);
	}

	function explodeExtraData(extraData) {
		if (!extraData)
			return "---";
		try {
			let extra = JSON.parse(extraData);
			console.log(extra);

			let output = "";
			for (const property in extra) {
				let tag = extraDataTags[property].split('|');
				let prop = tag[1] === "string" ? extra[property].replaceAll(/<\/?[^>]+(>|$)/gi, "") : (tag[1] === "date" ? (extra[property] ? moment(extra[property]).format('DD/MM/YYYY') : "-") : (extra[property] ? "SI" : "NO"))
				output += "<p><strong>" + tag[0] + ":</strong> " + prop + "</p>";
			}
			return output;
		} catch (error) {
			return "N/D";
		}
	}

	function handleManaged(expiration) {
		try {
			if (!window.confirm("Confermi di aver gestito manualmente questa scadenza?"))
				return;
			
			Api.get({endpoint: `timetable/setManaged/${expiration}`, contentType : 'application/json'})
				.then(response => {
					setDisplayDialogContent(null);
					setDisplayDialog(1);
					setLastUpdate(Date.now());
				})
				.catch(error => {
					console.log(JSON.stringify(error));
					setDisplayDialog(-1);
				});
		} catch (error) {
			console.log(JSON.stringify(error));
			setDisplayDialog(-1);
		}			
	}	
	
	function handleDisabled(expiration) {
		try {
			if (!window.confirm("Chiudendo l'impianto non si otterranno più notifiche su di esso\nnello scadenziario. Proseguire?"))
				return;
			
			Api.get({endpoint: `timetable/setDisabled/${expiration}`, contentType : 'application/json'})
				.then(response => {
					setDisplayDialogContent(null);
					setDisplayDialog(1);
					setLastUpdate(Date.now());
				})
				.catch(error => {
					console.log(JSON.stringify(error));
					setDisplayDialog(-1);
				});
		} catch (error) {
			console.log(JSON.stringify(error));
			setDisplayDialog(-1);
		}			
	}
	
	
	const columns = [
		{
			field: 'customer',
			headerName: 'Cliente',
			flex: 1,
		},
		{
			field: 'city',
			headerName: 'Comune',
			flex: 1,
		},
		{
			field: 'area',
			headerName: 'Provincia',
			flex: 0.5,
		},
		{
			field: 'eventDate',
			headerName: 'Scadenza',
			flex: 0.5,
			valueGetter: (params) => {
				return params.row.eventDate ? moment(params.row.eventDate).format('DD/MM/YYYY') : ""
			},
		},
		{
			field: 'tipology',
			headerName: 'Tipologia',
			flex: 0.5,
			valueGetter: (params) => {
				return params.row.tipology ? deadlineTypes[params.row.tipology] : ""
			},
		},
		{
			field: 'managed',
			headerName: 'Gestita',
			type: 'boolean',
			valueGetter: (params) => {
				return params.row.managed ? true : false
			},
		},		
		{
			field: 'id',
			headerName: 'Azioni',
			flex: 1,
			renderCell: (params) => {
				function onShow(e) {
					e.stopPropagation(); // don't select this row after clicking
					let extraData = apiRef.current.getCellValue(params.value, 'extra_data');
					setDisplayDialogContent(explodeExtraData(extraData));
					setDisplayDialog(1);
				};
				function onAppointment(e) {
					navigate(`../appointments/createFromExpire/${params.value}`);
				}				
				function onManaged(e) {
					handleManaged(params.value);
				}				
				function onDisabled(e) {
					handleDisabled(params.value);
				}
				return <>
					<Tooltip title="Visualizza dettagli Scadenza">
						<Button disabled={!apiRef.current.getCellValue(params.value, 'extra_data')} onClick={onShow}>
							<InfoIcon />
						</Button>
					</Tooltip>
					<Tooltip title="Converti in appuntamento">
						<Button disabled={apiRef.current.getCellValue(params.value, 'tipology') === deadlineTypes['ANN'] || apiRef.current.getCellValue(params.value, 'managed')} onClick={onAppointment}>
							<MoveDownIcon />
						</Button>
					</Tooltip>
					<Tooltip title="Segnala come gestita">
						<Button disabled={apiRef.current.getCellValue(params.value, 'managed')} onClick={onManaged}>
							<CheckCircleOutlineIcon />
						</Button>
					</Tooltip>					
					<Tooltip title="Impianto non più attivo">
						<Button disabled={apiRef.current.getCellValue(params.value, 'tipology') === deadlineTypes['ANN'] || apiRef.current.getCellValue(params.value, 'managed')} onClick={onDisabled}>
							<ContentPasteOffIcon />
						</Button>
					</Tooltip>
				</>
			}
		},
	]

	const theme = createTheme({
			palette: {
				primary: { main: '#11cb5f' },
			},
		},
		itIT,
	);

	return (
		<>
			<h5>Scadenziario</h5>
			<div className="row styled">
				<div className="col-md-2">
					<TextField
						fullWidth
						label="Intervallo di visualizzazione"
						name="interval"
						className="styled"
						select
						defaultValue = {DEFAULT_VALUE}
						InputProps={{
							style: {color: theme.palette.primary.darkMain}
						}}
						onChange={(e) => {
							extraFilter(e.target.value);
						}}
					>
						{arrayIntervalsResponse()}
					</TextField>
				</div>
				<div className="col-md-10">
					<p style={{fontSize: '0.8em'}}>
						{fromDay && toDay && (
							<span>Dal: {fromDay}* - Al: {toDay}</span>
						)}
						<br />
						* Vengono visualizzate anche le scadenze (gestite) degli ultimi 5 giorni - Le scadenze NON GESTITE nel passato sono invece sempre tutte visibili<br />
						Per gli impianti 'chiusi' non vengono visualizzate solo le scadenze relative a Prova fumi/Manutenzione
					</p>
				</div>
			</div>

			<Box sx={{ width: '100%', minWidth: '800px' }}>
				<ThemeProvider theme={theme}>
					<DataGrid
						//onRowDoubleClick={hook}
						sx={{ minHeight: '300px' }}
						rows={rows}
						columns={columns}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: PAGE_SIZE,
								},
							},
						}}
						apiRef={apiRef}
						slots={{
							toolbar: GridToolbar,
						}}
						slotProps={{
							columnsPanel: {
								disableHideAllButton: true,
							},
							toolbar: { 
								showQuickFilter: true 
							}
						}}
						pageSizeOptions={pageSizeOptions}
					/>
				</ThemeProvider>
			</Box>


			<Dialog
				open={displayDialog !== 0}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{displayDialogContent && displayDialog !== -1? (
					<>
						<DialogTitle id="alert-dialog-title">
							Dettagli della scadenza
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								<div dangerouslySetInnerHTML={{ __html: displayDialogContent }} />
							</DialogContentText>
						</DialogContent>
					</>
				) : (
					<>
						<DialogTitle id="alert-dialog-title">
							{displayDialog === 1 && "Operazione completata"}
							{displayDialog === -1 && "Errore"}
						</DialogTitle>
						<DialogContent>
								<DialogContentText id="alert-dialog-description">
									{displayDialog === 1 && "Operazione completata con successo!"}
									{displayDialog === -1 && "Si è verificato un errore inatteso"}
								</DialogContentText>
						</DialogContent>
					</>
				)}
				<DialogActions>
					<Button onClick={handleClose}>Chiudi</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default Timetable;
