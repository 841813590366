import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid, itIT, GridToolbar, useGridApiRef } from '@mui/x-data-grid';		//https://mui.com/x/react-data-grid/ [MIT version]
import { Box, Alert, Button, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import moment from 'moment';

import CommentIcon from '@mui/icons-material/Comment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Api from '../../Services/Api';
import {PAGE_SIZE, pageSizeOptions} from '../../Config/constants.js';

function AnnotationList(props) {
	const { hook, lastUpdate, customer } = props;

	const [displayDialog, setDisplayDialog] = useState(0);
	const [displayDialogContent, setDisplayDialogContent] = useState("");

	const apiRef = useGridApiRef();	//https://mui.com/x/api/data-grid/

	const handleClose = () => {
		setDisplayDialog(0);
	};

	const columns = [
		{
			field: 'annotation',
			headerName: 'Annotazione',
			flex: 4,
		},
		{
			field: 'ref_date',
			headerName: 'Data di riferimento',
			flex: 1,
			valueGetter: (params) => {
				return params.row.ref_date ? moment(params.row.ref_date).format('DD/MM/YYYY') : ""
			},
		},
		{
			field: 'expiring_date',
			headerName: 'Data di scadenza',
			flex: 1,
			valueGetter: (params) => {
				return params.row.expiring_date ? moment(params.row.expiring_date).format('DD/MM/YYYY') : ""
			},
		},
		{
			field: 'warning',
			headerName: 'Importante',
			type: 'boolean',
			valueGetter: (params) => {
				return params.row.warning ? true : false
			},
		},
		{
			field: 'managed',
			headerName: 'Gestita',
			type: 'boolean',
			valueGetter: (params) => {
				return params.row.managed ? true : false
			},
		},		
		{
			field: 'id',
			headerName: 'Azioni',
			flex: 1,
			renderCell: (params) => {
				function onShow(e) {
					e.stopPropagation(); // don't select this row after clicking
					let extendedValue = apiRef.current.getCellValue(params.value, 'annotation');
					setDisplayDialogContent(extendedValue);
					setDisplayDialog(1);
				};
				function onEdit(e) {
					e.stopPropagation(); // don't select this row after clicking
					hook("edit", params.value);
				};
				function onDelete(e) {
					e.stopPropagation(); // don't select this row after clicking
					if (window.confirm("Confermare l'eliminazione DEFINITIVA dell'Annotazione?")) {
						if (window.confirm("Eliminare l'Annotazione?")) {
							hook("delete", params.value);
						}
					}
				};
				return <>
					<Tooltip title="Visualizza Annotazione estesa">
						<Button onClick={onShow}>
							<CommentIcon />
						</Button>
					</Tooltip>
					<Tooltip title="Modifica Annotazione">
						<Button onClick={onEdit}>
							<EditIcon />
						</Button>
					</Tooltip>
					<Tooltip title="Elimina Annotazione">
						<Button onClick={onDelete}>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</>
			}
		},
	]

	function newElement() {
		hook("create");
	}

	const [rows, setRows] = useState([]);
	const [displayAlert, setDisplayAlert] = useState(0);

	useEffect(() => {
		try {
			Api.get({endpoint: `annotation/list/${customer}`, contentType : 'application/json'})
			.then(response => {
				setRows(response.data.annotations);
			})
			.catch(error => {
				console.log(JSON.stringify(error));
				setDisplayAlert(-1);
			});
		} catch (error) {
			console.log(JSON.stringify(error));
			setDisplayAlert(-1);
		}
	}, [lastUpdate]);	// eslint-disable-line react-hooks/exhaustive-deps

	const theme = createTheme({
			palette: {
				primary: { main: '#11cb5f' },
			},
		},
		itIT,
	);
	return (
		<>
			<Box sx={{ width: '100%', minWidth: '800px' }}>
				<ThemeProvider theme={theme}>
					<DataGrid
						//onRowDoubleClick={hook}
						sx={{ minHeight: '300px' }}
						rows={rows}
						columns={columns}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: PAGE_SIZE,
								},
							},
						}}
						apiRef={apiRef}
						slots={{
							toolbar: GridToolbar,
						}}
						slotProps={{
							columnsPanel: {
								disableHideAllButton: true,
							}
						}}
						pageSizeOptions={pageSizeOptions}
					/>			
					<div className="mt-2">
						<Button
							variant="contained"
							onClick={newElement}
						>
							Nuova Annotazione
						</Button>
					</div>
				</ThemeProvider>
				{displayAlert === -1 && (
					<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
				)}
			</Box>
			<Dialog
				open={displayDialog !== 0}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Annotazione - visualizzazione estesa</DialogTitle>
				<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{displayDialogContent}
						</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Chiudi</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default AnnotationList;