import React from 'react';
import { TextField } from '@mui/material';

const TextInput = ({ label, value, onChange, onBlur, ...props }) => {
  const handleChange = (event) => {
    onChange(event);
    if (props.formikOnChange) props.formikOnChange(event);
  };

  return (
    <TextField
      label={label}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      fullWidth
      {...props}
    />
  );
};

export default TextInput;
