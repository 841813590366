import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Button, Alert } from '@mui/material';
import { Link } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import loadingGif from '../../Assets/loading.gif';
import Api from '../../Services/Api';
import FastTextField from "../FastTextField";
import FastBoolField from "../FastBoolField";
import FormikValidation from "../FormikValidation";

function AnnotationForm(props) {
	const [apiResponse, setApiResponse] = useState(null)
	const [onEdit] = useState(props.annotation ? true : false)
	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(0);

	let customer = props.customer;
	
	const formik = useFormik({
		initialValues: {
			annotation: apiResponse?.annotation ?? "",
			ref_date: apiResponse?.ref_date ? moment(apiResponse?.ref_date).format('YYYY-MM-DD') : "",
			expiring_date: apiResponse?.expiring_date ? moment(apiResponse?.expiring_date).format('YYYY-MM-DD') : "",			
			managed: apiResponse?.managed ?? "0",			
			warning: apiResponse?.warning ?? "0",
			_method: onEdit ? 'put' : 'post',
			customer_id: customer,
		},
		validationSchema: Yup.object({
			annotation:
				Yup.string()				
				.max(1000, 'Lunghezza massima 1000 caratteri')
				.required('Campo obbligatorio'),
			ref_date:
				Yup.date(),
			expiring_date:
				Yup.date(),
		}),
		onSubmit: values => {
			setLoading(true);

			try {
				let currEndpoint = onEdit ? `annotation/${apiResponse.id}` : 'annotation';
				Api.post({endpoint: currEndpoint, body: values, contentType : 'application/json'})
					.then(response => {
						setDisplayAlert(1);
						//let timer1 = setTimeout(() => {setLoading(false);clearTimeout(timer1)}, 3000);
					})
					.catch(error => {
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						else {
							console.log(error);
							alert("Errore inatteso!");
						}
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}
		},
		enableReinitialize: true,
	});

	function confirmGoBack(checkChanged) {
		if (checkChanged && Object.keys(formik.touched).length > 0) {
			if (!window.confirm("Uscire senza salvare le modifiche?")) {
				return false;
			}
		}
		return true;
	}

	function gotoBack(checkChanged) {
		return (
			<Link to={`/cms/customers/edit/${customer}`} style={{margin: 'auto'}}
				onClick={e => !confirmGoBack(checkChanged) && e.preventDefault()}
			>
				<Button
					variant="outlined"
					sx={{ ml: 3 }}
				>
					Torna al Cliente!
				</Button>
			</Link>
		);
	}

	useEffect(() => {
		try {
			if (onEdit) {
				Api.get({endpoint: `annotation/${props.annotation}`, contentType : 'application/json'})
					.then(response => {
						setApiResponse(response.data.annotation);
					})
					.catch(error => {
						console.log(error);
						setApiResponse(null);
						setDisplayAlert(-1);
					});
			}
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, [onEdit]);	// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<form
			autoComplete="off"
			noValidate
			onSubmit={formik.handleSubmit}
		>
			<Card>
				<CardHeader
					title="Annotazione"
					subheader="Visualizza e modifica i dati dell'Annotazione"
				/>
				<CardContent sx={{ pt: 0 }}>
					<div className="container">
						<div key="r_annotation" className="row styled">
							<div key="annotation" className="col-md-12">
								<FastTextField required loading={loading} formik={formik} label="Annotazione" field="annotation"
									multiline
									rows={4}
								/>
							</div>
						</div>
						<div key="r_ref_date_warning_expiring_date" className="row styled">
							<div key="ref_date_warning" className="col-md-6">
								<div key="r_r_ref_date_warning" className="row">
									<div key="ref_date" className="col-md-6">
										<FastTextField loading={loading} formik={formik} label="Data di riferimento" field="ref_date" type="date"/>
									</div>
									<div key="warning" className="col-md-6">
										<FastBoolField loading={loading} formik={formik} label="Importante" field="warning"/>
									</div>
								</div>
							</div>
							<div key="expiring_date_managed" className="col-md-6">
								<div key="r_expiring_date" className="row">
									<div key="expiring_date" className="col-md-6">
										<FastTextField loading={loading} formik={formik} label="Data di scadenza" field="expiring_date" type="date"/>
									</div>									
									<div key="managed" className="col-md-6">
										<FastBoolField loading={loading} formik={formik} label="Gestita" field="managed"/>
									</div>
								</div>
							</div>							

						</div>
						<div className="row styled">
							<div className="col">
								{loading && (
										<>
											<>
											{displayAlert === 0 && (
												<img className="pl-3" src={loadingGif} alt="Loading..."/>
											)}
											</>
											<>
											{displayAlert === -1 && (
												<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
											)}
											</>
											<>
											{displayAlert === 1 && (
												<Alert severity="success">
													{onEdit ? (
														<>Dati modificati con successo!</>
													) : (
														<>Dati salvati con successo!</>
													)}
													{gotoBack()}
												</Alert>
											)}
											</>
										</>
								)}
								{!loading && (
									<>
										<Button
											variant="contained"
											type="submit"
										>
											{onEdit ? (
												<>Modifica dati</>
											) : (
												<>Invia dati</>
											)}
										</Button>
										{gotoBack(true)}
									</>
								)}
							</div>
						</div>
					</div> {/* container */}
					<FormikValidation formik={formik} />
				</CardContent>
			</Card>
			<FormikValidation formik={formik} tech={true} />
		</form>
	);

}

export default AnnotationForm;