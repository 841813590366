
function NotFound () {
  return (
	<>
		<h2>NotFound</h2>
		<p>This is my errrrrror page</p>
	</>
  );
}

export default NotFound;
