import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useState } from 'react';

import { Dialog, DialogActions, DialogContent,DialogContentText, DialogTitle, Button } from '@mui/material';

import AppointmentList from "../Components/Appointment/AppointmentList";
import AppointmentForm from "../Components/Appointment/AppointmentForm";

import Api from "../Services/Api";

function Appointment() {
	let {appointment, expiration} = useParams();
	let location = useLocation();

	const [displayDialog, setDisplayDialog] = useState(0);
	const [lastChildUpdate, setLastChildUpdate] = useState(null);

	const handleClose = () => {
		setDisplayDialog(0);
	};
	
	async function selectedItem(eventType, id) {
		if (eventType === "edit") {
			navigate(`edit/${id}`);
		}

		if (eventType === "create") {
			navigate("create");
		}

		if (eventType === "delete") {
			try {
				Api.del({endpoint: `appointment/${id}`, contentType : 'application/json'})
					.then(response => {
						setDisplayDialog(1);
						setLastChildUpdate(Date.now());
					})
					.catch(error => {
						console.log(JSON.stringify(error));
						setDisplayDialog(-1);
					});
			} catch (error) {
				console.log(JSON.stringify(error));
				setDisplayDialog(-1);
			}
		}
	};

	let navigate = useNavigate();

	return (
		<>			
			{appointment && <AppointmentForm appointment={appointment} />}
			{!appointment && (
				<>
				{location.pathname.endsWith("/create") || expiration ? (
					<AppointmentForm expiration={expiration} />
				) : (
					<AppointmentList hook={selectedItem} lastUpdate={lastChildUpdate} />
				)}
				</>
			)}
			<Dialog
				open={displayDialog !== 0}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{displayDialog === 1 && "Operazione completata"}
					{displayDialog === -1 && "Errore"}
				</DialogTitle>
				<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{displayDialog === 1 && "Operazione completata con successo!"}
							{displayDialog === -1 && "Si è verificato un errore inatteso"}
						</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Chiudi</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default Appointment;
