import { useState, useEffect } from 'react';
import { TextField, Typography, Popover, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { myLog } from "../Services/Functions";

function FastTextField (props) {
    const { meta, field, label, type, formik, loading, child, required } = props;

	const [anchorEl, setAnchorEl] = useState(null);

	const handlePopoverSwitch = (event) => {
		if (anchorEl) {
			setAnchorEl(null);
		}
		else {
			setAnchorEl(event.currentTarget);
		}
	};
	const handlePopoverClose = (event) => {
		setAnchorEl(null);
	};
	const openPopover = Boolean(anchorEl);

	let myType = "text";

    useEffect(() => {
        myLog(formik.values, "FastTextField.useEffect.formik_values");
    }, [formik.values]);	// eslint-disable-line react-hooks/exhaustive-deps
    
	if (meta) {
		let showHelp = meta.label.length > 30;
		if (type) {
			myType = type	//Overwrite with explicit declared type
		}
		else {
			if (meta.data_type === "decimal" || meta.data_type === "tinyint" || meta.data_type === "smallint") {
				myType = "number";
			}
			if (meta.data_type === "datetime" || meta.data_type === "date") {
				myType = "date";
			}
			if (meta.data_type === "enum") {
				myType = "select";
			}
		}
		return (
			<>
				<TextField
					fullWidth
                    value={formik.values[meta.name]}
                    onChange={formik.handleChange}
					helperText={formik.touched[meta.name] && formik.errors[meta.name] ? formik.errors[meta.name] : null}
					error={Boolean(formik.touched[meta.name] && formik.errors[meta.name])}
					label={meta.label}
					name={meta.name}
                    key={meta.name}
					required={meta.nullable === "NO"}
					disabled={Boolean(loading)}
					className="styled"
					type={myType}
					sx={{
						width: showHelp ? '85%' : '100%',
					}}
					{...props}
				>
				{
					child
				}
				</TextField>
				{showHelp &&
					<IconButton
						aria-label="switch popover"
						onClick={handlePopoverSwitch}
						edge="end"
					>
						<InfoOutlinedIcon/>
					</IconButton>
				}
				<Popover
					id="mouse-over-popover"
					sx={{
						pointerEvents: 'none',
					}}
					open={openPopover}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					onClose={handlePopoverClose}
					disableRestoreFocus
				>
					<Typography sx={{ p: 1 }}>{meta.label}</Typography>
				</Popover>
			</>
		);
	}
	if (field) {
		return (
			<TextField
				fullWidth
                value={formik.values[field]}
                onChange={formik.handleChange}
				helperText={formik.touched[field] && formik.errors[field] ? formik.errors[field] : null}
				error={Boolean(formik.touched[field] && formik.errors[field])}
				label={label}
				name={field}
                key={field}
				required={required}
				disabled={Boolean(loading)}
				className="styled"
				{...props}
			>
			{
				child
			}
			</TextField>
		);
	}
}

export default FastTextField;


