import React, { useState, useEffect } from "react";
import packageJson from "../../../package.json";
import moment from "moment";

const buildDateGreaterThan = (latestDate, currentDate) => {
    const momLatestDateTime = moment(latestDate);
    const momCurrentDateTime = moment(currentDate);

    if (momLatestDateTime.isAfter(momCurrentDateTime)) {
        return true;
    } else {
        return false;
    }
};

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

const getCacheMeta = async () => {
    return await fetch("/meta.json?" + makeid(20)).then(res=>res.json());
}

const shouldForceRefresh = async () => {
    const meta  = await getCacheMeta();
    const latestVersionDate = meta.buildDate;
    const currentVersionDate = packageJson.buildDate;
    return {
        shouldRefresh: buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
        ),
        buildDate: latestVersionDate
    }
}

const refreshCacheAndReload = () => {
    if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
            for (const name of names) {
                caches.delete(name);
            }
        });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
};

function withClearCache(Component) {
    function ClearCacheComponent() {
        const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);
        const [tsBuildDate, setTsBuildDate] = useState(null);
        useEffect(() => {
            shouldForceRefresh().then(res=>{
                setTsBuildDate(res.buildDate);
                if (res.shouldRefresh) {
                    setIsLatestBuildDate(false);
                    refreshCacheAndReload();		  
                } else {
                    setIsLatestBuildDate(true);
                }
            });
        }, []);
        return (
            <React.Fragment>
                {isLatestBuildDate ? <Component buildDate = {tsBuildDate} /> : null}
            </React.Fragment>
        );
    }
    return ClearCacheComponent;
}

export function checkForUpdates() {
    shouldForceRefresh().then(res=>{
        if(res.shouldRefresh){
            refreshCacheAndReload();
        }
    })
}

export default withClearCache;