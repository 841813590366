import { useTheme } from '@mui/material/styles';

import { Divider } from '@mui/material';
import LinearScaleIcon from '@mui/icons-material/LinearScale';

function Separator (props) {
	const theme = useTheme();
	
	return (
		<Divider 
			aria-hidden="true" 
			className="my-4" 
			textAlign="center"
			sx={{
				color: theme.palette.primary.main,
			}}
		>
			<LinearScaleIcon className="mx-4"/>
			<LinearScaleIcon className="mx-4"/>
			<LinearScaleIcon className="mx-4"/>
			<LinearScaleIcon className="mx-4"/>
			<LinearScaleIcon className="mx-4"/>
			<LinearScaleIcon className="mx-4"/>
		</Divider>
	);
}

export default Separator;
