import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useState } from 'react';

import { Dialog, DialogActions, DialogContent,DialogContentText, DialogTitle, Button } from '@mui/material';

import SpareList from "../Components/Spare/SpareList";
import SpareForm from "../Components/Spare/SpareForm";
import SparePurchaseForm from "../Components/Spare/SparePurchaseForm";
import SpareTakeReleaseForm from "../Components/Spare/SpareTakeReleaseForm";

import Api from "../Services/Api";

function Spare() {
	let {spare} = useParams();
	let location = useLocation();
	let navigate = useNavigate();

	const [displayDialog, setDisplayDialog] = useState(0);
	const [displayPurchase, setDisplayPurchase] = useState(0);
	const [selectedSpare, setSelectedSpare] = useState(null);
	const [displayTakeRelease, setDisplayTakeRelease] = useState(0);
	const [lastChildUpdate, setLastChildUpdate] = useState(null);

	const handleCloseDialog = () => {
		setDisplayDialog(0);
	};
	const handleClosePurchase = () => {
		setDisplayPurchase(0);
		setLastChildUpdate(Date.now());
	};
	const handleCloseTakeRelease = () => {
		setDisplayTakeRelease(0);
		setLastChildUpdate(Date.now());
	};

	async function selectedItem(eventType, id) {				
		if (eventType === "purchase") {
			setDisplayPurchase(1);
			setSelectedSpare({			
				'id': id.id,
				'description': id.description,				
			});
		}
		
		if (eventType === "takeRelease") {			
			setDisplayTakeRelease(1);
			setSelectedSpare({			
				'id': id.id,
				'description': id.description,				
				'currentQuantity': id.currentQuantity,				
			});			
		}
				
		if (eventType === "edit") {
			navigate(`edit/${id}`);
		}

		if (eventType === "create") {
			navigate("create");
		}

		if (eventType === "delete") {
			try {
				Api.del({endpoint: `spare/${id}`, contentType : 'application/json'})
					.then(response => {
						setDisplayDialog(1);
						setLastChildUpdate(Date.now());
					})
					.catch(error => {
						console.log(JSON.stringify(error));
						setDisplayDialog(-1);
					});
			} catch (error) {
				console.log(JSON.stringify(error));
				setDisplayDialog(-1);
			}
		}
	};

	return (
		<>
			{spare && <SpareForm 
				spare={spare} 
				setDisplayDialog={setDisplayDialog} 
				setDisplayPurchase={setDisplayPurchase}
				setSelectedSpare={setSelectedSpare}	
				lastParentUpdate={lastChildUpdate}
			/>}
			{!spare && (
				<>
				{location.pathname.endsWith("/create") ? (
					<SpareForm />
				) : (
					<SpareList hook={selectedItem} lastUpdate={lastChildUpdate} />
				)}
				</>
			)}	
			<SparePurchaseForm
				open={displayPurchase}
				onClose={handleClosePurchase}
				spare={selectedSpare}
			/>
			<SpareTakeReleaseForm
				open={displayTakeRelease}
				onClose={handleCloseTakeRelease}
				spare={selectedSpare}
			/>			
			<Dialog
				open={displayDialog !== 0}
				onClose={handleCloseDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{displayDialog === 1 && "Operazione completata"}
					{displayDialog === -1 && "Errore"}
				</DialogTitle>
				<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{displayDialog === 1 && "Operazione completata con successo!"}
							{displayDialog === -1 && "Si è verificato un errore inatteso"}
						</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog}>Chiudi</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default Spare;
