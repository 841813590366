import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Button, Alert, MenuItem } from '@mui/material';
import { Link, useParams } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import loadingGif from '../../Assets/loading.gif';
import Api from '../../Services/Api';
import FastTextField from "../FastTextField";
import FastInfoBlock from "../FastInfoBlock";
import FastBoolField from "../FastBoolField";
import FormikValidation from "../FormikValidation";

import { verificationTypes as optionsVerificationTypes } from '../../Config/constants.js';

function DeadlineForm(props) {
	const [apiResponse, setApiResponse] = useState(null)
	const [onEdit] = useState(props.deadline ? true : false)
	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(0);
	const [dashboardInstallation, setDashboardInstallation] = useState(null);
	const [managedOriginalValue, setManagedOriginalValue] = useState(null);

	let {customer, installation} = useParams();	
	
	function dashboard() {
		return !installation || !customer || installation === "0" || customer === "0";
	}
	
	function originating_verification_toString(verification_start, stamp_id) {
		let date = verification_start ? moment(verification_start).format('DD/MM/YYYY') : null;
		let tmpVType = stamp_id ? ` ${stamp_id} [Prova fumi]` : ' [Manutenzione]';
		if (date) {
			return date + tmpVType;
		}
		else {
			return "(Nessuno)";
		}
	}

	const formik = useFormik({
		initialValues: {
			next_verification_date  : apiResponse?.deadline?.next_verification_date ? moment(apiResponse?.deadline.next_verification_date).format('YYYY-MM-DD') : "",
			notes: apiResponse?.deadline.notes ?? "",
			vType: apiResponse?.deadline.vType ?? "",
			managed: apiResponse?.deadline.managed ?? "0",
			manual: onEdit ? (apiResponse?.deadline.manual ?? "1") : "1",
			_method: onEdit ? 'put' : 'post',
			installation_id: installation,
		},
		validationSchema: Yup.object({
			next_verification_date:
				Yup.date()
				.required('Campo obbligatorio'),
			notes:
				Yup.string()
				.max(255, 'Lunghezza massima 255 caratteri'),
			vType:
				Yup.string()
				.required('Campo obbligatorio'),			
		}),
		onSubmit: values => {
			setLoading(true);
			if (dashboard()) {
				values.installation_id = dashboardInstallation;
			}		
			if (values.managed && !managedOriginalValue) {
				values.manually_managed = true;
			}
			else {
				values.manually_managed = false;
			}
			
			try {
				let currEndpoint = onEdit ? `deadline/${apiResponse.deadline.id}` : 'deadline';
				Api.post({endpoint: currEndpoint, body: values, contentType : 'application/json'})
					.then(response => {
						setDisplayAlert(1);
						//let timer1 = setTimeout(() => {setLoading(false);clearTimeout(timer1)}, 3000);
					})
					.catch(error => {
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						else {
							console.log(error);
							alert("Errore inatteso!");
						}
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}
		},
		enableReinitialize: true,
	});

	function confirmGoBack(checkChanged) {
		if (checkChanged && Object.keys(formik.touched).length > 0) {
			if (!window.confirm("Uscire senza salvare le modifiche?")) {
				return false;
			}
		}
		return true;
	}

	function gotoBack(checkChanged) {
		let endpoint = '../';

		if (installation && customer && installation !== "0" && customer !== "0") {
			endpoint =  `../installations/edit/${installation}/${customer}`;
		}
		return (
			<Link to={endpoint} style={{margin: 'auto'}}
				onClick={e => !confirmGoBack(checkChanged) && e.preventDefault()}
			>
				<Button
					variant="outlined"
					sx={{ ml: 3 }}
				>
					Torna alla {dashboard() ? 'Dashboard' : 'lista'}!
				</Button>
			</Link>
		);
	}

	useEffect(() => {
		try {
			if (onEdit) {
				Api.get({endpoint: `deadline/${props.deadline}`, contentType : 'application/json'})
					.then(response => {
						setApiResponse(response.data);						
						setManagedOriginalValue(response.data.deadline.managed);
						if (dashboard()) {
							setDashboardInstallation(response.data.deadline.installation_id);
						}
					})
					.catch(error => {
						console.log(error);
						setApiResponse(null);
						setDisplayAlert(-1);
					});
			}
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, [onEdit]);	// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<form
			autoComplete="off"
			noValidate
			onSubmit={formik.handleSubmit}
		>
			<Card>
				<CardHeader
					title="Scadenza"
					subheader="Visualizza e modifica i dati della Scadenza"
				/>
				<CardContent sx={{ pt: 0 }}>
					{apiResponse?.installation && <p className="subtitle">
						{`${apiResponse.installation.surname} ${apiResponse.installation.name} - Marca: ${apiResponse.installation.brand} - Modello: ${apiResponse.installation.model}`}
					</p>}
					<div key="originating_verification_next_verification_date" className="row styled">
						<div key="originating_verification" className="col-md-9">
							<FastInfoBlock label="Evento precedente" content={`${apiResponse ? originating_verification_toString(apiResponse.deadline.verification_start, apiResponse.deadline.stamp_id) : "N/A"}`} {...props} />
						</div>
						<div key="next_verification_date " className="col-md-3">
							<FastTextField loading={loading} required formik={formik} label="Data" field="next_verification_date" type="date"/>
						</div>
					</div>
					<div key="vType_managed" className="row styled">
						<div key="vType" className="col-md-9">
							<FastTextField loading={loading} required formik={formik} label="Tipologia" field="vType" select
								child = {optionsVerificationTypes.map((oVType) => (
									<MenuItem key={oVType} value={oVType}>
										{oVType}
									</MenuItem>
								))}
							/>
						</div>
						<div key="managed" className="col-md-3">
							<FastBoolField loading={loading} formik={formik} label="Gestita" field="managed"/>
						</div>
					</div>

					<div key="r_notes" className="row styled">
						<div key="notes" className="col-md-12">
							<FastTextField loading={loading} formik={formik} label="Note" field="notes" />
						</div>
					</div>
					<div className="row styled">
						<div className="col">
							{loading && (
									<>
										<>
										{displayAlert === 0 && (
											<img className="pl-3" src={loadingGif} alt="Loading..."/>
										)}
										</>
										<>
										{displayAlert === -1 && (
											<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
										)}
										</>
										<>
										{displayAlert === 1 && (
											<Alert severity="success">
												{onEdit ? (
													<>Dati modificati con successo!</>
												) : (
													<>Dati salvati con successo!</>
												)}
												{gotoBack()}
											</Alert>
										)}
										</>
									</>
							)}
							{!loading && (
								<>
									<Button
										variant="contained"
										type="submit"
									>
										{onEdit ? (
											<>Modifica dati</>
										) : (
											<>Invia dati</>
										)}
									</Button>
									{gotoBack(true)}
								</>
							)}
						</div>
					</div>
					<FormikValidation formik={formik} />
				</CardContent>
			</Card>
			<FormikValidation formik={formik} tech={true} />			
		</form>
	);
}

export default DeadlineForm;