import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes ,Route } from 'react-router-dom';

import './global.css';

import { ProvideAuth } from './Auth/ProvideAuth'
import App from './App';
import CmsLayout from './Layout/CmsLayout';
import Dashboard from './Pages/Dashboard';
import Account from './Pages/Account';
import RouteManager from './RouteManager';
import NotFound from './Pages/NotFound';
import Login from './Pages/Login';

import Customer from './Pages/Customer';
import Annotation from './Pages/Annotation';
import Installation from './Pages/Installation';
import Deadline from './Pages/Deadline';
import Appointment from './Pages/Appointment';
import AppointmentWork from './Pages/AppointmentWork';
import Stamp from './Pages/Stamp';
import Supplier from './Pages/Supplier';
import Category from './Pages/Category';
import Spare from './Pages/Spare';
import Report from './Pages/Report';
import Timetable from './Pages/Timetable';



import Demo from './Demo/Demo';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'font-awesome/css/font-awesome.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ProvideAuth>
			<BrowserRouter >
				<Routes>
					<Route exact path='/login' element={<Login />} />
					<Route exact path='/' element={<App />} />
					<Route exact path='/cms' element={<RouteManager component={CmsLayout} />}>
						<Route exact path="/cms" element={<RouteManager component={Dashboard} />} />	{/* Main content */}								{/* https://localhost:3000/cms */}
						<Route exact path="account" element={<RouteManager component={Account} />} />
                        
                        
                        
                        
                        <Route exact path="demo" element={<RouteManager component={Demo} />} />
                        

						<Route exact path="customers" element={<RouteManager component={Customer} role="backoffice" />} />
						<Route exact path="customers/edit/:customer" element={<RouteManager component={Customer} role="backoffice" />} />
						<Route exact path="customers/create" element={<RouteManager component={Customer} role="backoffice" />} />

						<Route exact path="annotations" element={<RouteManager component={Annotation} role="backoffice" />} />
						<Route exact path="annotations/edit/:annotation/:customer" element={<RouteManager component={Annotation} role="backoffice" />} />
						<Route exact path="annotations/create/:customer" element={<RouteManager component={Annotation} role="backoffice" />} />

						<Route exact path="installations" element={<RouteManager component={Installation} role="backoffice" />} />
						<Route exact path="installations/edit/:installation/:customer" element={<RouteManager component={Installation} role="backoffice" />} />
						<Route exact path="installations/create/:customer" element={<RouteManager component={Installation} role="backoffice" />} />

						{/*<Route exact path="deadlines" element={<RouteManager component={Deadline} role="backoffice" />} />*/}
						<Route exact path="deadlines/edit/:deadline/:installation/:customer" element={<RouteManager component={Deadline} role="backoffice" />} />
						<Route exact path="deadlines/create/:installation/:customer" element={<RouteManager component={Deadline} role="backoffice" />} />

						<Route exact path="appointments" element={<RouteManager component={Appointment} role="backoffice" />} />
						<Route exact path="appointments/edit/:appointment" element={<RouteManager component={Appointment} role="backoffice" />} />
						<Route exact path="appointments/create" element={<RouteManager component={Appointment} role="backoffice" />} />
						<Route exact path="appointments/work/:appointment" element={<RouteManager component={AppointmentWork} />} />
						<Route exact path="appointments/createFromExpire/:expiration" element={<RouteManager component={Appointment} role="backoffice" />} />

						<Route exact path="stamps" element={<RouteManager component={Stamp} role="backoffice" />} />

						<Route exact path="suppliers" element={<RouteManager component={Supplier} role="backoffice" />} />
						<Route exact path="suppliers/edit/:supplier" element={<RouteManager component={Supplier} role="backoffice" />} />
						<Route exact path="suppliers/create" element={<RouteManager component={Supplier} role="backoffice" />} />

						<Route exact path="categories" element={<RouteManager component={Category} role="backoffice" />} />
						<Route exact path="categories/edit/:category" element={<RouteManager component={Category} role="backoffice" />} />
						<Route exact path="categories/create" element={<RouteManager component={Category} role="backoffice" />} />
						
						<Route exact path="spares" element={<RouteManager component={Spare} />} />
						<Route exact path="spares/edit/:spare" element={<RouteManager component={Spare} />} />
						<Route exact path="spares/create" element={<RouteManager component={Spare} role="backoffice" />} />
						
						<Route exact path="reports" element={<RouteManager component={Report} role="backoffice" />} />
						<Route exact path="timetable" element={<RouteManager component={Timetable} role="backoffice" />} />
					</Route>
					<Route path='*' element={<NotFound />} />
				</Routes>
			</BrowserRouter >
		</ProvideAuth>
	</React.StrictMode>
);
