import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

import DeadlineList from "../Components/Deadline/DeadlineList";
import DeadlineForm from "../Components/Deadline/DeadlineForm";

import Api from "../Services/Api";

function Deadline() {	
	let {deadline, customer, installation} = useParams();

	let location = useLocation();
	let navigate = useNavigate();
	
	const pathname = location.pathname;
	
	const [displayDialog, setDisplayDialog] = useState(0);
	const [lastChildUpdate, setLastChildUpdate] = useState(null);

	const handleClose = () => {
		setDisplayDialog(0);
	};
	
	async function selectedItem(eventType, id) {
		if (eventType === "edit") {
			navigate(`../deadlines/edit/${id}/${installation}/${customer}`);
		}

		if (eventType === "create") {
			navigate(`../deadlines/create/${installation}/${customer}`);
		}

		if (eventType === "delete") {
			try {
				Api.del({endpoint: `deadline/${id}`, contentType : 'application/json'})
					.then(response => {
						setDisplayDialog(1);
						setLastChildUpdate(Date.now());
					})
					.catch(error => {
						console.log(JSON.stringify(error));
						setDisplayDialog(-1);
					});
			} catch (error) {
				console.log(JSON.stringify(error));
				setDisplayDialog(-1);
			}
		}
	};	

	return (
		<>
			{deadline && <DeadlineForm deadline={deadline} />}
			{!deadline && (
				<>
				{pathname.includes("/create/") ? (
					<DeadlineForm />
				) : (
					<DeadlineList hook={selectedItem} lastUpdate={lastChildUpdate} />
				)}
				</>
			)}
			<Dialog
				open={displayDialog !== 0}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{displayDialog === 1 && "Operazione completata"}
					{displayDialog === -1 && "Errore"}
				</DialogTitle>
				<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{displayDialog === 1 && "Operazione completata con successo!"}
							{displayDialog === -1 && "Si è verificato un errore inatteso"}
						</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Chiudi</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default Deadline;
