import { useState, useEffect, useMemo } from 'react';

import { Card, CardContent, CardHeader, Button, Alert, AlertTitle, MenuItem, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Link } from 'react-router-dom';

import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import loadingGif from '../../Assets/loading.gif';
import Api from '../../Services/Api';
import { myLog } from "../../Services/Functions";

import FastAutocomplete from "../FastAutocomplete";
import FastInfoBlock from "../FastInfoBlock";
import FastTextField from "../FastTextField";
import FormikValidation from "../FormikValidation";

import { appointmentTypes as optionsAppointmentType, refTypes as optionsRefType } from '../../Config/constants.js';

function AppointmentForm(props) {
	const [apiResponse, setApiResponse] = useState(null)
	const [listCustomersResponse, setListCustomersResponse] = useState(null)
	const [listInstallationsResponse, setListInstallationsResponse] = useState(null)
	const [listTecniciansResponse, setListTecniciansResponse] = useState(null)

	const [listCustomers, setListCustomers] = useState([])
	const [listInstallations, setListInstallations] = useState([])
	const [listTecnicians, setListTecnicians] = useState([])

	const [onEdit] = useState(props.appointment ? true : false)

	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(0);
	const [selectedCustomer, setSelectedCustomer] = useState(null);
	const [selectedInstallation, setSelectedInstallation] = useState(null);
	const [installationIsClosed, setInstallationIsClosed] = useState(false);

	const theme = useTheme();

	let fromExpiration = props.expiration ? true : false;
	const [expiration, setExpiration] = useState(null);

	const handleCancelNow = () => {
		formik.setFieldValue("cancelled_at_d", moment().format('YYYY-MM-DD'));
		formik.setFieldValue("cancelled_at_t", moment().format('HH:mm'));
	};

    const initialValues = useMemo(() => ({
        contacted_at_d: apiResponse?.contacted_at ? moment(apiResponse?.contacted_at).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        contacted_at_t: apiResponse?.contacted_at ? moment(apiResponse?.contacted_at).format('HH:mm') : moment().format('HH:mm'),
        contacted_at: "",
        referent: apiResponse?.referent ?? "",
        referent_type: apiResponse?.referent_type ?? "",
        appointment_at_d: apiResponse?.appointment_at ? moment(apiResponse?.appointment_at).format('YYYY-MM-DD') : "",
        appointment_at_t: apiResponse?.appointment_at ? moment(apiResponse?.appointment_at).format('HH:mm') : "",
        appointment_at: "",
        appointment_type: apiResponse?.appointment_type ?? "Riparazione",
        cancelled_at_d: apiResponse?.cancelled_at ? moment(apiResponse?.cancelled_at).format('YYYY-MM-DD') : "",
        cancelled_at_t: apiResponse?.cancelled_at ? moment(apiResponse?.cancelled_at).format('HH:mm') : "",
        cancelled_at: "",
        cancelled_notes: apiResponse?.cancelled_notes ?? "",
        cancelled_user_id: apiResponse?.cancelled_user_id ?? "",
        notes: "",
        technician_id: apiResponse?.technician_id ?? "",
        customer_id: apiResponse?.customer_id ?? null,
        installation_id: apiResponse?.installation_id ?? null,
        _method: onEdit ? 'put' : 'post',
    }), [apiResponse, onEdit]);  // only re-compute on changes to apiResponse or onEdit

	const formik = useFormik({
		initialValues,
		validate : values => {
            let errors = {}
            if((values.cancelled_at_d && !values.cancelled_at_t) || (!values.cancelled_at_d && values.cancelled_at_t)) {
                errors.cancelled_at_d = "Inserire entrambi o nessun campo";
                errors.cancelled_at_d = "Inserire entrambi o nessun campo";
            }
            if(values.cancelled_notes && !values.cancelled_at_d) {
                errors.cancelled_notes = "Per inserire le note, inserire prima la data di cancellazione";
            }
            return errors
        },
		validationSchema: Yup.object({
			contacted_at_d:
				Yup.date()
				.required('Campo obbligatorio'),
			contacted_at_t:
				Yup.string()
				.required('Campo obbligatorio'),
			referent:
				Yup.string()
				.max(30, 'Lunghezza massima 30 caratteri'),
			referent_type:
				Yup.string(),
			appointment_at_d:
				Yup.date()
				.required('Campo obbligatorio'),
			appointment_at_t:
				Yup.string()
				.required('Campo obbligatorio'),
			appointment_type:
				Yup.string()
				.required('Campo obbligatorio'),
			cancelled_at_d:
				Yup.date(),
			cancelled_at_t:
				Yup.string(),
			cancelled_notes:
				Yup.string()
				.max(1000, 'Lunghezza massima 1000 caratteri'),
			notes:
				Yup.string()
				.max(1000, 'Lunghezza massima 1000 caratteri'),
			technician_id:
				Yup.number()
				.integer("Valore non accettato")
				.min(1)
				.max(18446744073709551615, 'Valore non accettato')	//Bigint
				.required('Campo obbligatorio'),
			customer_id:
				Yup.number()
				.integer("Valore non accettato")
				.min(-1)
				.max(18446744073709551615, 'Valore non accettato')	//Bigint
				.required('Campo obbligatorio'),
			installation_id:
				Yup.number()
				.integer("Valore non accettato")
				.min(-1)
				.max(18446744073709551615, 'Valore non accettato')	//Bigint
				.required('Campo obbligatorio'),
		}),
		onSubmit: values => {
			setLoading(true);

			//formik.setFieldValue("contacted_at", values.contacted_at_d + " " + values.contacted_at_t);	//Is async
			//formik.setFieldValue("appointment_at", values.appointment_at_d + " " + values.appointment_at_t);
			//formik.setFieldValue(("cancelled_at", values.cancelled_at_d + " " + values.cancelled_at_t).trim());
			values.contacted_at = values.contacted_at_d + " " + values.contacted_at_t;
			values.appointment_at = values.appointment_at_d + " " + values.appointment_at_t;
			values.cancelled_at = values.cancelled_at_d && values.cancelled_at_t ? values.cancelled_at_d + " " + values.cancelled_at_t : "";

			if (fromExpiration && expiration) {
				values.expiration = props.expiration;	//Add expiration source - managed in b.e.
			}
			try {
				let currEndpoint = onEdit ? `appointment/${apiResponse.id}` : 'appointment';
				Api.post({endpoint: currEndpoint, body: values, contentType : 'application/json'})
					.then(response => {
						setDisplayAlert(1);
						//let timer1 = setTimeout(() => {setLoading(false);clearTimeout(timer1)}, 3000);
					})
					.catch(error => {
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						else {
							console.log(error);
							alert("Errore inatteso!");
						}
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}
		},
		enableReinitialize: true,
	});

	function confirmGoBack(checkChanged) {
		if (checkChanged && Object.keys(formik.touched).length > 0) {
			if (!window.confirm("Uscire senza salvare le modifiche?")) {
				return false;
			}
		}
		return true;
	}

	function gotoBack(checkChanged) {
		return (
			<Link to={`/cms/appointments`} style={{margin: 'auto'}}
				onClick={e => !confirmGoBack(checkChanged) && e.preventDefault()}
			>
				<Button
					variant="outlined"
					sx={{ ml: 3 }}
				>
					Torna alla lista!
				</Button>
			</Link>
		);
	}

	useEffect(() => {
        myLog(formik.values, "AppointmentForm formik formik.values");
	}, [formik.values]);	// eslint-disable-line react-hooks/exhaustive-deps
    
	useEffect(() => {
        myLog(initialValues, "AppointmentForm array initialvalues");
	}, [initialValues]);	// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        myLog(apiResponse, "AppointmentForm formik apiResponse");
	}, [apiResponse]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
        myLog(formik.values, "AppointmentForm reload");
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		try {
			Api.get({endpoint: `customer?combo=1`, contentType : 'application/json'})
				.then(response => {
					setListCustomersResponse(response.data.customers);

					if (onEdit) {
						Api.get({endpoint: `appointment/${props.appointment}`, contentType : 'application/json'})
							.then(response_a => {
								setApiResponse(response_a.data.appointment);
								if (response.data.customers) {
									setSelectedCustomer(
										response.data.customers.filter(obj => {
											return obj.id === response_a.data.appointment.customer_id;
										})[0]
									)
								}
							})
							.catch(error => {
								console.log(error);
								setApiResponse(null);
								setDisplayAlert(-1);
							});
					}

					if (fromExpiration) {
						Api.get({endpoint: `appointment/fromExpiration/${props.expiration}`, contentType : 'application/json'})
							.then(response_e => {
								setExpiration(response_e.data);
								if (response.data.customers) {
									setSelectedCustomer(
										response.data.customers.filter(obj => {
											return obj.id === response_e.data.expire.customer_id;
										})[0]
									);
									formik.setFieldValue('customer_id', response_e.data.expire.customer_id);
								}
							})
							.catch(error => {
								console.log(error);
								//setExpiration(null);
								setDisplayAlert(-1);
							});
					}
				})
				.catch(error => {
					console.log(error);
					setListCustomersResponse(null);
					setDisplayAlert(-1);
				});

			Api.get({endpoint: `tecnicians`, contentType : 'application/json'})
				.then(response => {
					setListTecniciansResponse(response.data.tecnicians);
				})
				.catch(error => {
					console.log(error);
					setListTecniciansResponse(null);
					setDisplayAlert(-1);
				});
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		try {
			if (!listCustomersResponse) {
				setListCustomers([
					{ id: -1, label: 'Caricamento in corso...' },
				]);
			}
			else {
				setListCustomers(listCustomersResponse);	//Already formatted in controller
			}
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, [listCustomersResponse]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		try {
			if (!listInstallationsResponse) {
				setListInstallations([
					{ id: -1, label: 'Selezionare un cliente...' },
				]);
			}
			else {
				let listInstallationsResponseArray = [];
				listInstallationsResponse.forEach((installation) => {
					listInstallationsResponseArray.push(makeInstallation(installation));
				});
				setListInstallations(listInstallationsResponseArray);
			}
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, [listInstallationsResponse]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		try {
			if (!listTecniciansResponse || !selectedInstallation) {
				setListTecnicians(
					<MenuItem key="-1" value="-1">
						Caricamento in corso...
					</MenuItem>
				);
			}
			else {
				let currentInstallation = null;
				if (listInstallationsResponse) {
					currentInstallation = listInstallationsResponse.filter(obj => {
						return obj.id === selectedInstallation.id;
					})
					if (currentInstallation.length > 0) {
						currentInstallation = currentInstallation[0];
						currentInstallation.area = currentInstallation.installation_area ? currentInstallation.installation_area : currentInstallation.customer_area;
					}
				}
				setListTecnicians(
					listTecniciansResponse.map((tecnician) => (
						<MenuItem key={tecnician.id} value={tecnician.id}>
							<>
								{currentInstallation && JSON.parse(tecnician.preferred_areas).includes(currentInstallation.area) ? (
									`${tecnician.surname} ${tecnician.name} *** CONSIGLIATO ***`
								) : (
									`${tecnician.surname} ${tecnician.name}`
								)}
							</>
						</MenuItem>
					))
				);
			}
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, [selectedInstallation]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		try {
			if (selectedCustomer) {
				Api.get({endpoint: `installation/list/${selectedCustomer.id}?combo=1`, contentType : 'application/json'})
					.then(response => {
						setSelectedInstallation(null);
						setListInstallationsResponse(response.data.installations);
						if (onEdit) {
							if (response.data.installations) {
								let installation_id = apiResponse.installation_id;
								let installation =
									response.data.installations.filter(obj => {
										return obj.id === installation_id;
									})[0];
								if (typeof(installation) !== 'undefined') {
									setSelectedInstallation(makeInstallation(installation));
								}
								//else > customer changed after first load on editing > installation must be selected manually
							}
						}

						if (fromExpiration) {
							if (response.data.installations) {
								let installation_id = expiration.expire.installation_id;
								let installation =
									response.data.installations.filter(obj => {
										return obj.id === installation_id;
									})[0];
								if (typeof(installation) !== 'undefined') {
									setSelectedInstallation(makeInstallation(installation));
									formik.setFieldValue('installation_id', installation_id);
								}
								else {
                                    setInstallationIsClosed(true);
                                }
							}
						}
					})
					.catch(error => {
						console.log(error);
						setListInstallationsResponse(null);
						setDisplayAlert(-1);
					});
			}
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, [selectedCustomer]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		try {
			if (expiration) {
				formik.setFieldValue('appointment_type', expiration.expire.appointment_type);
				formik.setFieldValue('appointment_at_d', expiration.expire.appointment_at_d);
			}
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, [expiration]);	// eslint-disable-line react-hooks/exhaustive-deps

	function makeInstallation(installation) {
		let label = `[${installation.unique_code}] ${installation.model} - ${installation.brand}`;
		if (installation.address && installation.city) {
			label += ` (${installation.installation_address} - ${installation.installation_city})`;
		}
		else {
			label += ` (${installation.customer_address} - ${installation.customer_city})`;
		}
		return {'id': installation.id, 'label': label}
	}

	const customerSelected = (customer) => {
		if (customer?.id) {	//Read FastAutocomplete.handleOnChange notes
			setListInstallationsResponse(null);
			formik.setFieldValue('installation_id', null);
			setSelectedCustomer(customer);
		}
		if (customer === null) {
			setSelectedCustomer(null);
		}
	}

	const installationSelected = (installation) => {
		if (installation?.id) {	//Read FastAutocomplete.handleOnChange notes
			setSelectedInstallation(installation);
		}
		if (installation === null) {
			setSelectedInstallation(null);
		}
	}

	return (
        <FormikProvider value={formik}>
            <form
                autoComplete="off"
                noValidate
                onSubmit={formik.handleSubmit}
            >
                {installationIsClosed && (
                    <Alert severity="warning" sx={{marginBottom: '10px'}}>
                        <AlertTitle>ATTENZIONE</AlertTitle>
                        L'impianto di riferimento è stato chiuso. Impossibile creare un nuovo appuntamento!
                        <Link target="_blank" to={`/cms/customers/edit/${selectedCustomer?.id}`}>
                            Vai alla scheda del Cliente
                        </Link>
                    </Alert>
                )}
                <Card>
                    <CardHeader
                        title="Appuntamento"
                        subheader="Visualizza e modifica i dati dell'Appuntamento"
                    />
                    <CardContent sx={{ pt: 0 }}>
                        {expiration &&  (
                            <div style={{border: `2px solid ${theme.palette.primary.main}`, borderRadius: '4px', padding: '3px'}}>
                                {expiration.expire.source.source_type ? (
                                    <>
                                        Questo appuntamento verrà collegato all'evento: {`${expiration.expire.source.source_type} del ${moment(expiration.expire.source.date).format('DD/MM/YYYY')}`}
                                    </>
                                    ) : (
                                    <>
                                        Questo appuntamento deriva da una scadenza impostata a mano che non ha evento di origine
                                    </>
                                )}

                                {expiration.expire.notes && (
                                    <>
                                        <br />
                                        Note: {expiration.expire.notes}
                                    </>
                                )}
                            </div>
                        )}
                        <Box sx={{ width: '100%' }}>
                            <>
                                <div key="customer_id_installation_id" className="row styled">
                                    <div key="customer_id" className="col-md-6 custom-col">
                                        {fromExpiration ? (
                                            <>
                                                <FastInfoBlock label="Cliente" content={selectedCustomer ? selectedCustomer.label : "Caricamento..."} {...props} />
                                                <FastTextField style={{'display': 'none'}} formik={formik} label="Cliente" field="customer_id" />
                                            </>
                                        ) : (
                                            <FastAutocomplete
                                                field="customer_id"
                                                formik={formik}
                                                options={listCustomers}
                                                onChange={customerSelected}
                                                loading={loading}
                                                label="Cliente"
                                                required={true}
                                            />
                                        )}
                                    </div>
                                    <div key="installation_id" className="col-md-6 custom-col">
                                        {fromExpiration ? (
                                            <>
                                                <FastInfoBlock label="Impianto" content={selectedInstallation ? selectedInstallation.label : "Caricamento..."} {...props} />
                                                <FastTextField style={{'display': 'none'}} formik={formik} label="Impianto" field="installation_id" />
                                            </>
                                        ) : (
                                            <FastAutocomplete
                                                field="installation_id"
                                                formik={formik}
                                                options={listInstallations}
                                                onChange={installationSelected}
                                                loading={loading}
                                                label="Impianto"
                                                required={true}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div key="contacted_at_referent" className="row styled">
                                    <div key="contacted_at" className="col-md-6  custom-col">
                                        <div key="contacted_at_d_contacted_at_t" className="row">
                                            <div key="contacted_at_d" className="col-md-6">
                                                <FastTextField loading={loading} required formik={formik} label="Data contatto" field="contacted_at_d" type="date"/>
                                            </div>
                                            <div key="contacted_at_t" className="col-md-6">
                                                <FastTextField loading={loading} required formik={formik} label="Ora contatto" field="contacted_at_t" type="time"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div key="referent" className="col-md-6 custom-col">
                                        <FastTextField loading={loading} formik={formik} label="Persona contattata" field="referent" />
                                    </div>
                                </div>
                                <div key="referent_type_appointment_at" className="row styled">
                                    <div key="referent_type" className="col-md-6 custom-col">
                                        <FastTextField loading={loading} formik={formik} label="Ruolo persona contattata" field="referent_type" select
                                            child = {optionsRefType.map((rtype) => (
                                                <MenuItem key={rtype} value={rtype}>
                                                    {rtype}
                                                </MenuItem>
                                            ))}
                                        />
                                    </div>
                                    <div key="appointment_at" className="col-md-6 custom-col">
                                        <div key="appointment_at_d_appointment_at_t" className="row">
                                            <div key="appointment_at_d" className="col-md-6">
                                                <FastTextField loading={loading} required formik={formik} label="Data appuntamento" field="appointment_at_d" type="date"/>
                                            </div>
                                            <div key="appointment_at_t" className="col-md-6">
                                                <FastTextField loading={loading} required formik={formik} label="Ora appuntamento" field="appointment_at_t" type="time"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div key="appointment_type_technician_id" className="row styled">
                                    <div key="appointment_type" className="col-md-6">
                                        <FastTextField loading={loading} required formik={formik} label="Tipo intervento" field="appointment_type" select
                                            child = {optionsAppointmentType.map((atype) => (
                                                <MenuItem key={atype} value={atype}>
                                                    {atype}
                                                </MenuItem>
                                            ))}
                                        />
                                    </div>
                                    <div key="technician_id" className="col-md-6">
                                        <FastTextField loading={loading} required formik={formik} label="Tecnico" field="technician_id" select
                                            child = {listTecnicians}
                                        />
                                    </div>
                                </div>
                                <div key="r_notes" className="row styled">
                                    <div key="notes" className="col-md-12">
                                        <FastTextField loading={loading} formik={formik} label="Note per tecnico" field="notes"
                                            multiline
                                            rows={4}
                                        />
                                    </div>
                                </div>
                                <hr />
                                {onEdit &&
                                    <div key="info" className="col-md-12" style={{textAlign: 'center', fontSize: '0.8em'}}>
                                        Inserendo una data di cancellazione, se l'appuntamento è nella giornata odierna, il Tecnico assegnatario riceverà una notifica
                                    </div>
                                }
                                <div key="cancelled_at_cancelled_notes" className="row styled">
                                    <div key="cancelled_at" className="col-md-6 custom-col">
                                        <div key="cancelled_at_d_cancelled_at_t" className="row">
                                            <div key="cancelled_at_d" className="col-md-6">
                                                <FastTextField loading={loading} formik={formik} label="Data cancellazione" field="cancelled_at_d" type="date" disabled={!onEdit} />
                                            </div>
                                            <div key="cancelled_at_t" className="col-md-6">
                                                <FastTextField loading={loading} formik={formik} label="Ora cancellazione" field="cancelled_at_t" type="time" disabled={!onEdit} />
                                            </div>
                                        </div>
                                        <Button className="mb-2" variant="outlined" size="small" onClick={handleCancelNow} disabled={!onEdit}>
                                            Cancella adesso
                                        </Button>
                                    </div>
                                    <div key="cancelled_notes" className="col-md-6 custom-col">
                                        <FastTextField loading={loading || !onEdit} formik={formik} label="Note cancellazione" field="cancelled_notes"
                                            multiline
                                            rows={3}
                                        />
                                    </div>
                                </div>

                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    {!loading && (
                                        <>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                            >
                                                {onEdit ? (
                                                    <>Modifica dati</>
                                                ) : (
                                                    <>Invia dati</>
                                                )}
                                            </Button>
                                            {gotoBack(true)}
                                        </>
                                    )}
                                </Box>
                            </>
                        </Box>
                        {loading && (
                            <div className="row styled">
                                <div className="col">
                                    <>
                                        <>
                                        {displayAlert === 0 && (
                                            <img className="pl-3" src={loadingGif} alt="Loading..."/>
                                        )}
                                        </>
                                        <>
                                        {displayAlert === -1 && (
                                            <Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
                                        )}
                                        </>
                                        <>
                                        {displayAlert === 1 && (
                                            <Alert severity="success">
                                                {onEdit ? (
                                                    <>Dati modificati con successo!!</>
                                                ) : (
                                                    <>Dati salvati con successo!</>
                                                )}
                                                {gotoBack()}
                                            </Alert>
                                        )}
                                        </>
                                    </>
                                </div>
                            </div>
                        )}
                        <FormikValidation formik={formik} />
                    </CardContent>
                </Card>
                <FormikValidation formik={formik} tech={true} />
            </form>
        </FormikProvider>
	);
}

export default AppointmentForm;