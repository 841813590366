import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const SelectInput = ({ label, value, onChange, onBlur, options, ...props }) => {
  const handleChange = (event) => {
    onChange(event);
    if (props.formikOnChange) props.formikOnChange(event);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        {...props}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;