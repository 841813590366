import DashboardIcon from '@mui/icons-material/Dashboard';
import ContactsIcon from '@mui/icons-material/Contacts';
import CategoryIcon from '@mui/icons-material/Category';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ApprovalIcon from '@mui/icons-material/Approval';
import BadgeIcon from '@mui/icons-material/Badge';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

export const basePath = '/cms';

export const mainListItems = [
  {
    title: 'Dashboard',
    path: basePath,
    icon: (
		<DashboardIcon />
    ),
  },
  {
    title: 'Clienti',
    path: basePath+'/customers',
    icon: (
		<ContactsIcon />
    ),
	roles: [
		'backoffice'
	]	
  },
  {
    title: 'Appuntamenti',
    path: basePath+'/appointments',
    icon: (
		<DateRangeIcon />
    ),
	roles: [
		'backoffice'
	]	
  },
  {
    title: 'Scadenziario',
    path: basePath+'/timetable',
    icon: (
		<EventRepeatIcon />
    ),
	roles: [
		'backoffice'
	]	
  },  
  {
    title: 'Reportistica',
    path: basePath+'/reports',
    icon: (
		<AssessmentIcon />
    ),
	roles: [
		'backoffice'
	]	
  },  
];

export const secondaryHeader = "Impostazioni";

export const secondaryListItems = [
  {
    title: 'Fornitori',
    path: basePath+'/suppliers',
    icon: (
		<BadgeIcon />	
    ),
	roles: [
		'backoffice'
	]		
  },
  {
    title: 'Categorie',
    path: basePath+'/categories',
    icon: (
		<CategoryIcon />	
    )
  },   
  {
    title: 'Magazzino Ricambi',
    path: basePath+'/spares',
    icon: (
		<BadgeIcon />	
    ),
  },  
  {
    title: 'Bollini Provincia',
    path: basePath+'/stamps',
    icon: (
		<ApprovalIcon />	
    ),
	roles: [
		'backoffice'
	]		
  }, 
];
