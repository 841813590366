import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid, itIT, GridToolbar, useGridApiRef } from '@mui/x-data-grid';		//https://mui.com/x/react-data-grid/ [MIT version]
import { Box, Alert, Button, Tooltip } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ChatIcon from '@mui/icons-material/Chat';

import Api from '../../Services/Api';
import {PAGE_SIZE, pageSizeOptions, colorDataBackground} from '../../Config/constants.js';

function CustomerList(props) {
	const { hook, lastUpdate } = props;
	const apiRef = useGridApiRef();	//https://mui.com/x/api/data-grid/
    
    const ColorClasses = () => {
        const generatedClasses = colorDataBackground.map((color) => {
            const className = color.name.toLowerCase(); // Convert name to lowercase for class name
            const backgroundColor = color.hex;

            return `.${className} {
                background-color: ${backgroundColor};
            }`;
        });

        return (
            <div className="color-classes-container">
                <style>{generatedClasses.join('\n')}</style>
            </div>
        );
    };


	const columns = [
		{
			field: 'surname',
			headerName: 'Cognome',
			flex: 1,
		},
		{
			field: 'name',
			headerName: 'Nome',
			flex: 1,
		},
		{
			field: 'fiscalVatCode',
			headerName: 'C.F./P.Iva',
			flex: 2,
		},
		{
			field: 'city',
			headerName: 'Città',
			flex: 2,
		},
		{
			field: 'phone_number_1',
			headerName: 'Telefono',
			flex: 1,
		},
		{
			field: 'verified',
			headerName: 'Verificato',
			type: 'boolean',
			valueGetter: (params) => {
				return params.row.verified ? true : false
			},
		},
		{
			field: 'color',
			headerName: 'Colore',
			flex: 1,
		},        
		{
			field: 'id',
			headerName: 'Azioni',
			flex: 1,
			renderCell: (params) => {
				function sendSms(e) {
					e.stopPropagation(); // don't select this row after clicking
					hook("sendSms", params.value);
				};
				function onEdit(e) {
					e.stopPropagation(); // don't select this row after clicking
					hook("edit", params.value);
				};
				function onDelete(e) {
					e.stopPropagation(); // don't select this row after clicking
					if (window.confirm("Confermare l'eliminazione DEFINITIVA del Cliente?")) {
						if (window.confirm("Eliminare il Cliente?")) {
							hook("delete", params.value);
						}
					}
				};
				return <>
					<Tooltip title="Invia SMS">
						<Button onClick={sendSms}>
							<ChatIcon />
						</Button>
					</Tooltip>
					<Tooltip title="Modifica Cliente">
						<Button onClick={onEdit}>
							<EditIcon />
						</Button>
					</Tooltip>
					<Tooltip title="Elimina Cliente">
						<Button onClick={onDelete}>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</>
			}
		},
	]

	function newElement() {
		hook("create");
	}

	const [rows, setRows] = useState([]);
	const [displayAlert, setDisplayAlert] = useState(0);

	useEffect(() => {
		try {
			Api.get({endpoint: `customer`, contentType : 'application/json'})
			.then(response => {
				setRows(response.data.customers);
			})
			.catch(error => {
				console.log(JSON.stringify(error));
				setDisplayAlert(-1);
			});
		} catch (error) {
			console.log(JSON.stringify(error));
			setDisplayAlert(-1);
		}
	}, [lastUpdate]);	// eslint-disable-line react-hooks/exhaustive-deps

	const theme = createTheme({
			palette: {
				primary: { main: '#11cb5f' },
			},
		},
		itIT,
	);

	return (
		<>
            <ColorClasses />
			<h5>Clienti</h5>
			<Box sx={{ width: '100%', minWidth: '800px' }}>
				<ThemeProvider theme={theme}>
					<DataGrid
						//onRowDoubleClick={hook}
						sx={{ minHeight: '300px' }}
						rows={rows}
						columns={columns}						
                        getRowClassName={(params) => `${params.row.color}`}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: PAGE_SIZE,
								},
							},
						}}
						apiRef={apiRef}
						slots={{
							toolbar: GridToolbar
						}}
						slotProps={{
							columnsPanel: {
								disableHideAllButton: true,
							},
							toolbar: {
								showQuickFilter: true
							}
						}}
						pageSizeOptions={pageSizeOptions}
					/>
					<div className="mt-2">
						<Button
							variant="contained"
							onClick={newElement}
						>
							Nuovo Cliente
						</Button>
					</div>
				</ThemeProvider>
				{displayAlert === -1 && (
					<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
				)}
			</Box>
		</>
	);
}

export default CustomerList;