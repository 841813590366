import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

const AutocompleteInput = ({ label, value, onChange, onBlur, name, formikOnChange, options }) => {
  const handleChange = (event, newValue) => {
    formikOnChange({ target: { name, value: newValue } });
    onChange(newValue);
  };

  return (
    <Autocomplete
      options={options}
      value={value}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} label={label} onBlur={onBlur} name={name} />
      )}
    />
  );
};

export default AutocompleteInput;
