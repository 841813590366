import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab/';

import CustomerList from "../Components/Customer/CustomerList";
import CustomerForm from "../Components/Customer/CustomerForm";
import CustomerSmsForm from "../Components/Customer/CustomerSmsForm";

import Annotation from "./Annotation";
import Installation from "./Installation";

import Api from "../Services/Api";

function Customer() {
	let {customer} = useParams();
	
	let location = useLocation();
	let navigate = useNavigate();

	const [displayDialog, setDisplayDialog] = useState(0);
	const [smsDialogCustomerId, setSmsDialogCustomerId] = useState(null);
	const [lastChildUpdate, setLastChildUpdate] = useState(null);
	const [tab, setTab] = useState("C");	//Customer, Annotations, Installations

	const [childInEdit, setChildInEdit] = useState(false);

	const handleChange = (event, newValue) => {
		if (childInEdit && !window.confirm("Uscire senza salvare i dati sulla scheda attuale?")) {
			return;
		}	
		setChildInEdit(false);
		setTab(newValue);
	};
	
	const handleClose = () => {
		setDisplayDialog(0);
	};	
	const handleCloseSmsDialog = () => {
		setSmsDialogCustomerId(null);
	};	

	async function selectedItem(eventType, id) {
		if (eventType === "sendSms") {
			setSmsDialogCustomerId(id);
		}
		
		if (eventType === "edit") {
			navigate(`edit/${id}`);
		}

		if (eventType === "create") {
			navigate("create");
		}

		if (eventType === "delete") {
			try {
				Api.del({endpoint: `customer/${id}`, contentType : 'application/json'})
					.then(response => {
						setDisplayDialog(1);
						setLastChildUpdate(Date.now());
					})
					.catch(error => {
						console.log(JSON.stringify(error));
						setDisplayDialog(-1);
					});
			} catch (error) {
				console.log(JSON.stringify(error));
				setDisplayDialog(-1);
			}
		}
	};

	return (
		<>
			{customer && (
				<TabContext value={tab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChange} aria-label="Customer">
							<Tab label="Anagrafica" value="C" />
							<Tab label="Annotazioni" value="A" />
							<Tab label="Impianti" value="I" />
						</TabList>
					</Box>
					<TabPanel value="C"><CustomerForm customer={customer} setInEdit={setChildInEdit} /></TabPanel>
					<TabPanel value="A"><Annotation /></TabPanel>
					<TabPanel value="I"><Installation /></TabPanel>
				</TabContext>
			)}

			{!customer && (
				<>
				{location.pathname.endsWith("/create") ? (
					<CustomerForm setInEdit={setChildInEdit} />
				) : (
					<CustomerList hook={selectedItem} lastUpdate={lastChildUpdate} />
				)}
				</>
			)}
			<Dialog
				open={displayDialog !== 0}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{displayDialog === 1 && "Operazione completata"}
					{displayDialog === -1 && "Errore"}
				</DialogTitle>
				<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{displayDialog === 1 && "Operazione completata con successo!"}
							{displayDialog === -1 && "Si è verificato un errore inatteso"}
						</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Chiudi</Button>
				</DialogActions>
			</Dialog>
			
			<Dialog
				open={smsDialogCustomerId !== null}
				onClose={handleCloseSmsDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Invia comunicazione via SMS al Cliente
				</DialogTitle>
				<DialogContent>
					<CustomerSmsForm customerId={smsDialogCustomerId} />
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseSmsDialog}>Chiudi</Button>
				</DialogActions>
			</Dialog>			
		</>
	);
}

export default Customer;
