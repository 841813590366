import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid, itIT, GridToolbar, useGridApiRef } from '@mui/x-data-grid';		//https://mui.com/x/react-data-grid/ [MIT version]
import { Box, Alert, Button, Tooltip } from '@mui/material';

import moment from 'moment';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Api from '../../Services/Api';
import {PAGE_SIZE, pageSizeOptions} from '../../Config/constants.js';

function DeadlineList(props) {
	let {installation} = useParams();

	const { hook, lastUpdate, dashboard } = props;
	
	const apiRef = useGridApiRef();	//https://mui.com/x/api/data-grid/

	let defaultColumns = [
		{
			field: 'id',
			headerName: 'Azioni',
			flex: 1,
			renderCell: (params) => {
				function onEdit(e) {
					e.stopPropagation(); // don't select this row after clicking
					hook("edit", params.value);
				};
				function onDelete(e) {
					e.stopPropagation(); // don't select this row after clicking
					if (window.confirm("Confermare l'eliminazione DEFINITIVA della Scadenza?")) {
						if (window.confirm("Eliminare la Scadenza?")) {
							hook("delete", params.value);
						}
					}
				};
				return <>
					<Tooltip title="Modifica Scadenza">
						<Button onClick={onEdit}>
							<EditIcon />
						</Button>
					</Tooltip>
					<Tooltip title="Elimina Scadenza">
						<Button onClick={onDelete}>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</>
			}
		}
	];
	
	if (dashboard) {
		defaultColumns = [
			{
				field: 'id',
				headerName: 'Azioni',
				flex: 1,
				renderCell: (params) => {
					function onEdit(e) {
						e.stopPropagation(); // don't select this row after clicking
						hook("edit", params.value);
					};
					function onDelete(e) {
						e.stopPropagation(); // don't select this row after clicking
						if (window.confirm("Confermare l'eliminazione DEFINITIVA della Scadenza?")) {
							if (window.confirm("Eliminare la Scadenza?")) {
								hook("delete", params.value);
							}
						}
					};
					return <>
						<Tooltip title="Modifica Scadenza">
							<Button onClick={onEdit}>
								<EditIcon />
							</Button>
						</Tooltip>
						<Tooltip title="Elimina Scadenza">
							<Button onClick={onDelete}>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				}
			}
		];	
	}
		
	const columns = [
		{
			field: 'next_verification_date',
			headerName: 'Data',
			flex: 1,
			valueGetter: (params) => {
				return params.row.next_verification_date ? moment(params.row.next_verification_date).format('DD/MM/YYYY') : ""
			},
		},
		{
			field: 'originating_verification',
			headerName: 'Evento precedente',
			flex: 2,
			valueGetter: (params) => {
				if (params.row.verification_start) {
					let date = params.row.verification_start ? moment(params.row.verification_start).format('DD/MM/YYYY') : "";
					let ovType = params.row.stamp_id ? ` ${params.row.stamp_id} [Prova Fumi]` : ' [Manutenzione]';
					return date + ovType;
				}
				else {
					return "";
				}
			},
		},
		{
			field: 'notes',
			headerName: 'Note',
			flex: 2,
		},
		{
			field: 'vType',
			headerName: 'Tipologia',
			flex: 1,
		},
		{
			field: 'managed',
			headerName: 'Gestita',
			type: 'boolean',
			valueGetter: (params) => {
				return params.row.managed ? true : false
			},
		},
		...defaultColumns		
	]

	function newElement() {
		hook("create");
	}

	const [rows, setRows] = useState([]);
	const [displayAlert, setDisplayAlert] = useState(0);

	useEffect(() => {
		let endPoint = `deadline/lists`;
		if (!dashboard) {
			endPoint += `/${installation}`;
		}

		try {
			Api.get({endpoint: endPoint, contentType : 'application/json'})
			.then(response => {
				setRows(response.data.deadlines);
			})
			.catch(error => {
				console.log(JSON.stringify(error));
				setDisplayAlert(-1);
			});
		} catch (error) {
			console.log(JSON.stringify(error));
			setDisplayAlert(-1);
		}
	}, [lastUpdate]);	// eslint-disable-line react-hooks/exhaustive-deps

	const theme = createTheme({
			palette: {
				primary: { main: '#11cb5f' },
			},
		},
		itIT,
	);
	return (
		<>
			<h5 className={dashboard ? 'dashboard' : ''}>{dashboard ? 'Ultime Scadenze generate' : 'Scadenze'}</h5>
			<Box sx={{ width: '100%', minWidth: '800px' }}>
				<ThemeProvider theme={theme}>
					<DataGrid
						//onRowDoubleClick={hook}
						sx={{ minHeight: '300px' }}
						rows={rows}
						columns={columns}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: PAGE_SIZE,
								},
							},
						}}
						apiRef={apiRef}
						slots={{
							toolbar: GridToolbar,
						}}
						slotProps={{
							columnsPanel: {
								disableHideAllButton: true,
							}
						}}
						pageSizeOptions={pageSizeOptions}
					/>
					{!dashboard && (
						<div className="mt-2">
							<Button
								variant="contained"
								onClick={newElement}
							>
								Nuova Scadenza
							</Button>
						</div>
					)}
				</ThemeProvider>
				{displayAlert === -1 && (
					<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
				)}
			</Box>
		</>
	);
}

export default DeadlineList;