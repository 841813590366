export const DEV = process.env.REACT_APP_DEV === "true" ? true : false
export const APP_NAME = process.env.REACT_APP_APP_NAME
export const VERSION = process.env.REACT_APP_VERSION
export const MAIN_SITE = process.env.REACT_APP_MAIN_SITE
export const API_URL = process.env.REACT_APP_API_URL
export const NODE_ENV = process.env.NODE_ENV	//development | production

export const ADMIN_ROLE = 'administrator'
export const BACKOFFICE_ROLE = 'backoffice'
export const OPERATOR_ROLE = 'operator'

export const PAGE_SIZE = 10
export const pageSizeOptions = [
	5,
	10,
	20,
	40,
	50
];

export const persons = [
	'',
	'Privato',
	'Azienda',
];

export const payments = [
	'',
	'Contanti',
	'Pos',
];

export const fuels = [
	'',
	'Metano',
	'Gas naturale',
	'Gpl',
	'Gasolio',
];

export const areas = [
	'',
	'PE',
	'CH',
	'TE',
];

export const liables = [
	'',
	'Proprietario',
	'Occupante',
	'Amministratore di condominio',
	'Terzo responsabile'
];

export const refTypes = [
	'',
	'Intestatario',
	'Madre',
	'Padre',
	'Figlio/a',
	'Parente',
	'Inquilino',
	'Proprietario',
	'Altro',
];

export const appointmentTypes = [
	'',
	'Riparazione',
	'Manutenzione',
	'Prova fumi',
];

export const verificationTypes = [
	'',
	'Prova fumi',
	'Manutenzione',
];

export const generatorTypes = [
	'',
	'Gruppo termico singolo',
	'Gruppo termico modulare',
	'Tubo/nastro radiante',
	'Generatore aria calda',
];

export const exhaustSmokeMethods = [
	'',
	'Naturale',
	'Forzata',
];

export const tripleValueChecks = [
	'',
	'Si',
	'No',
	'NC',
];

export const timeTableIntervals = [
	'5',
	'10',
	'15',
	'20',
	'25',
	'30',
];

export const colorDataBackground = [ //https://toolset.mrw.it/html/colori-del-web.html  - name must be at least 30 chars
    { name: "red", label: "Rosso", hex: "#FF000080" },
    { name: "green", label: "Verde", hex: "#00800080" },
    { name: "blue", label: "Blu", hex: "#1E90FF80" },
    { name: "orange", label: "Arancio", hex: "#FF8C0080" },
    { name: "purple", label: "Porpora", hex: "#FF149380" },
    { name: "yellow", label: "Giallo", hex: "#FFD70080" },
];