import { useState } from "react";

import Api from "../Services/Api";

function useProvideAuth() {
	const [lonyUser, setUser] = useState(localStorage.getItem('lonyUser') ? JSON.parse(localStorage.getItem('lonyUser')) : null);

	async function signin(loginPayload) {
		try {	
			localStorage.setItem('token', null);
			localStorage.setItem('lonyUser', null);
			return Api.post({endpoint: 'login', body: loginPayload, contentType : 'application/json'})
				.then(response => {
					const token = response.data.token;
					if(token){
						localStorage.setItem('token', token);
						localStorage.setItem('lonyUser', JSON.stringify(response.data.lonyUser));
						setUser(response.data.lonyUser);
					}
					return {'status': response.status, 'role' : response.data.lonyUser.roles[0].name};
				})
				.catch(error => {
					console.log(error);
					setUser(null);
					return {
						'status': error.response ? error.response.status : null,
						'error': (error.response && error.response.data)? error.response.data.error : null,
					};
				});
		} catch (error) {
			console.log(error);
			setUser(null);
			return {'status': error.response ? error.response.status : null};
		}
	};

	function signout() {
		try {
			Api.get({endpoint: 'logout', contentType : 'application/json'})
				.then(response => {
					unsetUser();
					return response.status;						
				})
				.catch(error => {
					console.log(error);
					unsetUser();
					return (error.response ? error.response.status : null);				
				});
		} catch (error) {
			console.log(error);
			unsetUser();
			return (error.response ? error.response.status : null);
		}			
	};
	
	function unsetUser() {
		localStorage.removeItem('token');
		localStorage.removeItem('lonyUser');
		setUser(null);		
	}

	return {
		lonyUser,
		signin,
		signout,
	};
}

export default useProvideAuth;