import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Container, Grid } from '@mui/material';
import TextInput from './TextInput';
import DatePickerInput from './DatePickerInput';
import TimePickerInput from './TimePickerInput';
import SelectInput from './SelectInput';
import AutocompleteInput from './AutocompleteInput';

import 'dayjs/locale/it';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const validationSchema = Yup.object({
  nome: Yup.string().max(100, 'Max 100 characters').required('Required'),
  data_nascita: Yup.date().required('Required'),
  ora_nascita: Yup.date().required('Required'),
  sesso: Yup.string().oneOf(['M', 'F', 'ND'], 'Invalid Gender').required('Required'),
  provincia: Yup.string().required('Required'),
});

const initialValues = {
  nome: '',
  data_nascita: null,
  ora_nascita: null,
  sesso: '',
  provincia: '',
};

const province = [
  "Alessandria", "Asti", "Biella", "Cuneo", "Novara", "Torino", "Verbano-Cusio-Ossola", "Vercelli",
  "Bergamo", "Brescia", "Como", "Cremona", "Lecco", "Lodi", "Mantova", "Milano", "Monza e della Brianza", "Pavia", "Sondrio", "Varese"
];

function Demo() {
  const handleAppChange = (handleChange, e) => {
    console.log(`Field: ${e.target.name}, Value: ${e.target.value}`);
    handleChange(e);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <Container maxWidth="sm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ values, handleChange, handleBlur, setFieldValue }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextInput
                    label="Nome"
                    name="nome"
                    value={values.nome}
                    onChange={(e) => handleAppChange(handleChange, e)}
                    onBlur={handleBlur}
                    formikOnChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePickerInput
                    label="Data di Nascita"
                    name="data_nascita"
                    value={values.data_nascita}
                    onChange={(value) => setFieldValue('data_nascita', value)}
                    onBlur={handleBlur}
                    formikOnChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TimePickerInput
                    label="Ora di Nascita"
                    name="ora_nascita"
                    value={values.ora_nascita}
                    onChange={(value) => setFieldValue('ora_nascita', value)}
                    onBlur={handleBlur}
                    formikOnChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectInput
                    label="Sesso"
                    name="sesso"
                    value={values.sesso}
                    onChange={(e) => handleAppChange(handleChange, e)}
                    onBlur={handleBlur}
                    options={[
                      { label: 'Maschio', value: 'M' },
                      { label: 'Femmina', value: 'F' },
                      { label: 'Non Dichiarato', value: 'ND' },
                    ]}
                    formikOnChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteInput
                    label="Provincia"
                    name="provincia"
                    value={values.provincia}
                    onChange={(value) => setFieldValue('provincia', value)}
                    onBlur={handleBlur}
                    options={province}
                    formikOnChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </LocalizationProvider>
  );
}

export default Demo;
