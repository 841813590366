import { TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function FastInfoBlock (props) {
	let {label, content, squeeze} = props;

	const theme = useTheme();

	return (
		<TextField
			fullWidth
			label={label}
			readOnly={true}
			className="styled"
			value={content}
			sx={{
				".MuiInputBase-input": {
					WebkitTextFillColor: theme.palette.primary.readOnly,
				},
				width: squeeze ? `${squeeze}%` : '100%',
			}}
			{...props}
		/>
	);
}

export default FastInfoBlock;


