import { Navigate } from 'react-router-dom';
import { useAuth } from './Auth/ProvideAuth'

import { ADMIN_ROLE } from './Config/constants.js';

/* role nullable or role = "roleA" or role = "roleA|roleB" */
const RouteManager = ({ component: Component, role, ...rest }) => {
	let auth = useAuth();

	if(auth.lonyUser == null) {
		return <Navigate to={{ pathname: '/login' }} />
	}
		
	if (role) {
		role = role.split("|");
		if(auth.lonyUser.roles[0].name !== ADMIN_ROLE && !role.includes(auth.lonyUser.roles[0].name)) {
			return <Navigate to={{ pathname: '/cms' }} />
		}
	}
	
	return <Component {...rest} />
}

export default RouteManager;