import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

const DatePickerInput = ({ label, value, onChange, onBlur, name, formikOnChange }) => {
  const handleChange = (date) => {
    formikOnChange({ target: { name, value: date } });
    onChange(date);
  };

  return (
    <DatePicker
      label={label}
      value={value}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} onBlur={onBlur} name={name} />
      )}
    />
  );
};

export default DatePickerInput;
