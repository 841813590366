import React from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';

const TimePickerInput = ({ label, value, onChange, onBlur, name, formikOnChange }) => {
  const handleChange = (time) => {
    formikOnChange({ target: { name, value: time } });
    onChange(time);
  };

  return (
    <TimePicker
      label={label}
      value={value}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} onBlur={onBlur} name={name} />
      )}
    />
  );
};

export default TimePickerInput;
