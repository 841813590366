import { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Alert, Card, CardContent, CardHeader, Autocomplete, TextField } from '@mui/material';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import loadingGif from '../../Assets/loading.gif';
import Api from '../../Services/Api';
import FastTextField from "../FastTextField";
import FormikValidation from "../FormikValidation";

function SpareUtilizeForm(props) {
	const [listCodesResponse, setListCodesResponse] = useState(null);
	const [listDescriptionsResponse, setListDescriptionsResponse] = useState(null);

	const [selectedCode, setSelectedCode] = useState(null);
	const [selectedDescription, setSelectedDescription] = useState(null);
	const [selectedFilter, setSelectedFilter] = useState(null);
	const [selectError, setSelectError] = useState(null);

	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(0);

	let repair = props.repair;

	//This form can be called only on "CREATE" mode
	const formik = useFormik({		
		initialValues: {
			spare_id: "",
			quantity: "",
			price: "",
			repair_id: repair && repair.id,
			_method: 'post',
		},
		validationSchema: Yup.object({
			spare_id:
				Yup.number()
				.integer("Valore non accettato")
				.min(1)
				.max(18446744073709551615, 'Valore non accettato')	//Bigint
				.required('Campo obbligatorio'),
			quantity:
				Yup.number()
				.min(0, 'Valore minimo 0')
				.max(999999.99, 'Valore massimo 999999.99')
				.required('Campo obbligatorio'),
			price:
				Yup.number()
				.min(0, 'Valore minimo 0')
				.max(999999.99, 'Valore massimo 999999.99'),
		}),
		onSubmit: values => {
			setLoading(true);
			try {
				Api.post({endpoint: `spare/utilize`, body: values, contentType : 'application/json'})
					.then(response => {
						setDisplayAlert(1);
						formik.resetForm();
						let timer1 = setTimeout(() => {
							onClose(true);
							setLoading(false);
							clearTimeout(timer1)
						}, 2000);
					})
					.catch(error => {
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						else {
							console.log(error);
							alert("Errore inatteso!");
						}
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}
		},
		enableReinitialize: true,
	});

	function onClose(force = true) {
		if (!force && Object.keys(formik.touched).length > 0) {
			if (!window.confirm("Uscire senza salvare le modifiche?")) {
				return false;
			}
		}

		setSelectedCode(null);
		setSelectedDescription(null);
		setSelectedFilter(null);
		setSelectError(null);
		formik.resetForm();
		
		props.onClose();
	}

	function suggestPrice() {		
		try {
			let quantity = formik.values.quantity;
			
			if (selectedFilter && quantity) 
			{
				setDisplayAlert(0);
				setLoading(true);
				
				Api.get({endpoint: `spare/${selectedFilter}/lastPrice`, contentType : 'application/json'})
					.then(response => {
						let price = response.data.price;
						if (price) {											
							formik.setFieldValue("price", price * quantity);
						}
						else {
							formik.setFieldValue("price", "");
							alert("Prezzo non presente in archivio");
						}
						setLoading(false);
					})
					.catch(error => {
						console.log(error);
						setDisplayAlert(-1);						
					});
			}
			else {
				if (!selectedFilter)
					alert("Selezionare un Ricambio");
				else
					alert("Inserire un valore valido per la quantità");
			}
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}

	function handleCodeChange(e, value) {
		if (!value || value.id === -1) {
			setSelectedCode(null);
		}
		else
			setSelectedCode(value.id);
		setSelectedFilter(null);
	}

	function handleDescriptionChange(e, value) {
		if (!value || value.id === -1) {
			setSelectedDescription(null);
		}
		else
			setSelectedDescription(value.id);
		setSelectedFilter(null);
	}

	function selectSpare() {
		if (selectedCode && selectedDescription) {
			setSelectError("Selezionare solo uno dei due criteri!");
			setSelectedFilter(null);
			formik.setFieldValue("spare_id", null);
			return;
		}

		setSelectError(null);
		setSelectedFilter(selectedCode ? selectedCode : selectedDescription);		
		formik.setFieldValue("spare_id", selectedCode ? selectedCode : selectedDescription);
	}

	useEffect(() => {
		try {
			Api.get({endpoint: `spare/lists/codes`, contentType : 'application/json'})
				.then(response => {
					setListCodesResponse(response.data.codes);
				})
				.catch(error => {
					console.log(error);
					setListCodesResponse([]);
					setDisplayAlert(-1);
				});
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}

		try {
			Api.get({endpoint: `spare/lists/descriptions`, contentType : 'application/json'})
				.then(response => {
					setListDescriptionsResponse(response.data.descriptions);
				})
				.catch(error => {
					console.log(error);
					setListDescriptionsResponse([]);
					setDisplayAlert(-1);
				});
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps

	function arrayCodesResponse() {
		if (!listCodesResponse) {
			return {
				'label': 'Caricamento in corso...',
				'id': -1,
			};
		}
		else {
			let res = listCodesResponse.map(function(code) {
				return {
					'label': `${code.code} (${code.brand})`,
					'id': code.id,
				}
			});

			return res;
		}
	}

	function arrayDescriptionsResponse() {
		if (!listDescriptionsResponse) {
			return {
				'label': 'Caricamento in corso...',
				'id': -1,
			};
		}
		else {
			let res = listDescriptionsResponse.map(function(desc) {
				return {
					'label': `${desc.short_description} (${desc.code})`,
					'id': desc.id,
				}
			});

			return res;
		}
	}

	return (
		<>
			<Dialog
				open={props.open !== 0}
				onClose={onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Aggiungi Ricambio</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<form
							autoComplete="off"
							noValidate
							onSubmit={formik.handleSubmit}
						>
							<div className="container">
								<Card className="mt-3" variant="outlined">
									<CardHeader
										subheader="Ricerca Ricambio usando uno dei due criteri seguenti:"
									/>
									<CardContent sx={{ pt: 0 }}>
										<div key="r_code" className="row">
											<div key="code" className="col-md-12">
												<Autocomplete
													disablePortal
													disabled={loading}
													id="code"
													options={arrayCodesResponse()}
													onChange={handleCodeChange}
													renderInput={
														(params) => <TextField {...params} label="Codice" />
													}
												/>

											</div>
										</div>
										<div key="r_description" className="row styled">
											<div key="description" className="col-md-12">
												<Autocomplete
													disablePortal
													disabled={loading}
													id="description"
													options={arrayDescriptionsResponse()}
													onChange={handleDescriptionChange}
													renderInput={
														(params) => <TextField {...params} label="Descrizione" />
													}
												/>

											</div>
										</div>
										<div key="r_button" className="row styled">
											<div key="button" className="col-md-12">
												<Button
													variant="outlined"
													onClick={selectSpare}
												>
													Seleziona Ricambio
												</Button>
												{selectError &&
													<Alert className="mt-2" severity="error">{selectError}</Alert>
												}
												{selectedCode && selectedFilter &&
													<Alert className="mt-2" severity="success">Codice accettato</Alert>
												}
												{selectedDescription && selectedFilter &&
													<Alert className="mt-2" severity="success">Descrizione accettata</Alert>
												}
											</div>
										</div>
									</CardContent>
								</Card>
								{formik.errors['spare_id'] &&
									<Alert className="mt-2" severity="error">Ricambio non selezionato!</Alert>
								}
								<div key="r_quantity" className="row styled">
									<div key="quantity" className="col-md-12">
										<FastTextField loading={loading} formik={formik} label="Quantità" field="quantity"	type="number" />
									</div>
								</div>
								<div key="r_price" className="row styled">
									<div key="price" className="col-md-12">
										<FastTextField loading={loading} formik={formik} label="Prezzo totale" field="price" type="number" />
										<Button
											variant="outlined"
											onClick={suggestPrice}
										>
											Suggerisci prezzo (basato sulla quantità)
										</Button>
									</div>
								</div>							
								<div key="r_notes" className="row styled">
									<hr />
									<div key="notes" className="col-md-12">
										<FastTextField loading={loading} formik={formik} label="Note di ritorno / note di avviso" field="notes" />
									</div>
								</div>
								<p>Le note restano visibili in reportistica fino alla loro gestione da parte dell'operatore</p>
								
								<div className="row styled">
									<div className="col">
										{loading && (
												<>
													<>
													{displayAlert === 0 && (
														<img className="pl-3" src={loadingGif} alt="Loading..."/>
													)}
													</>
													<>
													{displayAlert === -1 && (
														<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
													)}
													</>
													<>
													{displayAlert === 1 && (
														<Alert severity="success">Dati salvati con successo!</Alert>
													)}
													</>
												</>
										)}
										{!loading && (
											<>
												<Button
													variant="contained"
													type="submit"
												>
													Invia dati
												</Button>
											</>
										)}
									</div>
								</div>
							</div> {/* container */}
							<FormikValidation formik={formik} />
						</form>
						<FormikValidation formik={formik} tech={true} />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Chiudi</Button>
				</DialogActions>
			</Dialog>
			
		</>
	);
}

export default SpareUtilizeForm;