import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Button, Alert, AlertTitle, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import loadingGif from '../../Assets/loading.gif';
import Api from '../../Services/Api';
import FastTextField from "../FastTextField";
import FastBoolField from "../FastBoolField";
import FormikValidation from "../FormikValidation";

import { fuels as optionsFuel, areas as optionsArea, liables as optionsLiable, generatorTypes as optionsGeneratorType, exhaustSmokeMethods as optionsExhaustSmokeMethod } from '../../Config/constants.js';

function InstallationForm(props) {
	const [apiResponse, setApiResponse] = useState(null)
	const [onEdit] = useState(props.installation ? true : false)
	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(0);
	const [withMaintenance, setWithMaintenance] = useState(false);

	let customer = props.customer;

	const formik = useFormik({
		initialValues: {
			unique_code: apiResponse?.unique_code ?? "",
			brand: apiResponse?.brand ?? "",
			model: apiResponse?.model ?? "",
			serial: apiResponse?.serial ?? "",
			power: apiResponse?.power ?? "",
			liable: apiResponse?.liable ?? "Proprietario",
			with_maintenance: apiResponse?.with_maintenance ?? "0",
			first_poweron: apiResponse?.first_poweron ? moment(apiResponse?.first_poweron).format('YYYY-MM-DD') : "",
			fuel: apiResponse?.fuel ?? "Metano",
			installation_date: apiResponse?.installation_date ? moment(apiResponse?.installation_date).format('YYYY-MM-DD') : "",
			generator_type: apiResponse?.generator_type ?? "",
			fire_power: apiResponse?.fire_power ?? "",
			useful_power: apiResponse?.useful_power ?? "",
			climatization: apiResponse?.climatization ?? "",
			acs_production: apiResponse?.acs_production ?? "",
			exhaust_smoke_method: apiResponse?.exhaust_smoke_method ?? "",
			address: apiResponse?.address ?? "",
			number: apiResponse?.number ?? "",
			floor: apiResponse?.floor ?? "",
			city: apiResponse?.city ?? "",
			zipCode: apiResponse?.zipCode ?? "",
			area: apiResponse?.area ?? "",
			//Only if !onEdit
			next_pf_date: onEdit ? moment().format('YYYY-MM-DD') : "",
			notes_next_pf_date: "",
			next_maintenance_date: "",
			notes_next_maintenance_date: "",
			closed_at: apiResponse?.closed_at ? moment(apiResponse?.closed_at).format('YYYY-MM-DD') : "",
			verified: apiResponse?.verified ?? "1",
			_method: onEdit ? 'put' : 'post',
			customer_id: customer,
		},

		validate : values => {
            let errors = {}

			if(values.next_maintenance_date === "" && values.with_maintenance === "1") {
				errors.next_maintenance_date = "Campo obbligatorio";
			}
            if(values.next_pf_date === values.next_maintenance_date && values.with_maintenance === "1") {
                errors.next_pf_date = "Le due date non possono coincidere - controlla l'anno";
                errors.next_maintenance_date = "Le due date non possono coincidere - controlla l'anno";
            }
            return errors
        },

		validationSchema: Yup.object({
			unique_code:
				Yup.string()
				.max(15, 'Lunghezza massima 15 caratteri')
				.required('Campo obbligatorio'),
			brand:
				Yup.string()
				.max(30, 'Lunghezza massima 30 caratteri'),
			model:
				Yup.string()
				.max(50, 'Lunghezza massima 50 caratteri'),
			serial:
				Yup.string()
				.max(20, 'Lunghezza massima 20 caratteri'),
			power:
				Yup.number()
				.min(0, 'Valore minimo 0')
				.max(999.99, 'Valore massimo 999,99')
				.required('Campo obbligatorio'),
			liable:
				Yup.string()
				.required('Campo obbligatorio'),
			first_poweron:
				Yup.date(),
			fuel:
				Yup.string()
				.required('Campo obbligatorio'),
			installation_date:
				Yup.date(),
			generator_type:
				Yup.string(),
			fire_power:
				Yup.number()
				.min(0, 'Valore minimo 0')
				.max(999.99, 'Valore massimo 999,99'),
			useful_power:
				Yup.number()
				.min(0, 'Valore minimo 0')
				.max(999.99, 'Valore massimo 999,99'),
			exhaust_smoke_method:
				Yup.string(),
			address:
				Yup.string()
				.min(2, 'Lunghezza minima 2 caratteri')
				.min(2, 'Lunghezza minima 2 caratteri')
				.max(50, 'Lunghezza massima 50 caratteri'),
			number:
				Yup.string()
				.max(10, 'Lunghezza massima 10 caratteri'),
			floor:
				Yup.string()
				.max(20, 'Lunghezza massima 20 caratteri'),
			city:
				Yup.string()
				.min(2, 'Lunghezza minima 2 caratteri')
				.max(50, 'Lunghezza massima 50 caratteri'),
			zipCode:
				Yup.string()
				.length(5, 'Lunghezza 5 caratteri'),
			area:
				Yup.string()
				.length(2, 'Lunghezza 2 caratteri'),
			next_pf_date:
				Yup.date()
				.required('Campo obbligatorio'),
			notes_next_pf_date:
				Yup.string()
				.min(2, 'Lunghezza minima 2 caratteri')
				.max(150, 'Lunghezza massima 150 caratteri'),
			next_maintenance_date:
				Yup.date(),
			notes_next_maintenance_date:
				Yup.string()
				.min(2, 'Lunghezza minima 2 caratteri')
				.max(150, 'Lunghezza massima 150 caratteri'),
			closed_at:
				Yup.date(),
		}),
		onSubmit: values => {
			setLoading(true);

			if (formik.values.with_maintenance === "0") {
				values.next_maintenance_date = "";
				values.notes_next_maintenance_date = "";
			}
			try {
				let currEndpoint = onEdit ? `installation/${apiResponse.id}` : 'installation';
				Api.post({endpoint: currEndpoint, body: values, contentType : 'application/json'})
					.then(response => {
						setDisplayAlert(1);
						//let timer1 = setTimeout(() => {setLoading(false);clearTimeout(timer1)}, 3000);
					})
					.catch(error => {
						if (error.response.status === 412) {
							if (error.response.data.code === 1062) {
								alert("Non è possibile impostare le prossime 'Prova fumi' e 'Manutenzione' in contemporanea");
							}
							setDisplayAlert(0);
							setLoading(false);
							return;
						}
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						else {
							console.log(error);
							alert("Errore inatteso!");
						}
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}
		},
		enableReinitialize: true,
	});

	function confirmGoBack(checkChanged) {
		if (checkChanged && Object.keys(formik.touched).length > 0) {
			if (!window.confirm("Uscire senza salvare le modifiche?")) {
				return false;
			}
		}
		return true;
	}

	function gotoBack(checkChanged) {
		return (
			<Link to={`/cms/customers/edit/${customer}`} style={{margin: 'auto'}}
				onClick={e => !confirmGoBack(checkChanged) && e.preventDefault()}
			>
				<Button
					variant="outlined"
					sx={{ ml: 3 }}
				>
					Torna al Cliente!
				</Button>
			</Link>
		);
	}

	useEffect(() => {
		try {
			if (onEdit) {
				Api.get({endpoint: `installation/${props.installation}`, contentType : 'application/json'})
					.then(response => {
						setApiResponse(response.data.installation);
					})
					.catch(error => {
						console.log(error);
						setApiResponse(null);
						setDisplayAlert(-1);
					});
			}
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, [onEdit]);	// eslint-disable-line react-hooks/exhaustive-deps

	function clickMaintenance(value) {
		formik.setFieldValue('with_maintenance', value ?? null);
		setWithMaintenance(value === "1");
	}

	return (
		<form
			autoComplete="off"
			noValidate
			onSubmit={formik.handleSubmit}
		>
			{apiResponse?.closed_at && (
				<Alert severity="warning" sx={{marginBottom: '10px'}}>
					<AlertTitle>ATTENZIONE</AlertTitle>
					Stai guardando un impianto che è stato segnato come CHIUSO!
				</Alert>
			)}
			<Card>
				<CardHeader
					title="Impianto"
					subheader="Visualizza e modifica i dati dell'Impianto"
				/>
				<CardContent sx={{ pt: 0 }}>
					<div key="brand_model" className="row styled">
						<div key="brand" className="col-md-6">
							<FastTextField loading={loading} formik={formik} label="Marca" field="brand"/>
						</div>
						<div key="model" className="col-md-6">
							<FastTextField loading={loading} formik={formik} label="Modello" field="model"/>
						</div>
					</div>
					<div key="r_ref_date_warning" className="row styled">
						<div key="unique_code_serial" className="col-md-6">
							<div key="r_unique_code_serial" className="row">
								<div key="unique_code" className="col-md-6">
									<FastTextField loading={loading} required formik={formik} label="Codice impianto" field="unique_code"/>
								</div>
								<div key="serial" className="col-md-6">
									<FastTextField loading={loading} formik={formik} label="Matricola" field="serial"/>
								</div>
							</div>
						</div>
						<div key="power_with_maintenance" className="col-md-6">
							<div key="r_power_with_maintenance" className="row">
								<div key="power" className="col-md-6">
									<FastTextField loading={loading} required formik={formik} label="Potenza nominale" field="power" type="number" />
								</div>
								<div key="with_maintenance" className="col-md-6">
									<FastBoolField loading={loading} formik={formik} label="Con manutenzione" field="with_maintenance" onChange={(e) => clickMaintenance(e.target.value)}/>
								</div>
							</div>
						</div>
					</div>
					<div key="liable_fuel" className="row styled">
						<div key="liable" className="col-md-6">
							<FastTextField loading={loading} required formik={formik} label="Titolo di responsabilità" field="liable" select
								child = {optionsLiable.map((liable) => (
									<MenuItem key={liable} value={liable}>
										{liable}
									</MenuItem>
								))}
							/>
						</div>
						<div key="fuel" className="col-md-6">
							<FastTextField loading={loading} formik={formik} label="Combustibile" field="fuel" select
								child = {optionsFuel.map((area) => (
									<MenuItem key={area} value={area}>
										{area}
									</MenuItem>
								))}
							/>
						</div>
					</div>
					<div key="installation_first_poweron_generator_type" className="row styled">
						<div key="installation_first_poweron" className="col-md-6">
							<div key="r_installation_first_poweron" className="row">
								<div key="installation_date" className="col-md-6">
									<FastTextField loading={loading} formik={formik} label="Data installazione" field="installation_date" type="date"/>
								</div>
								<div key="first_poweron" className="col-md-6">
									<FastTextField loading={loading} formik={formik} label="Prima accensione" field="first_poweron" type="date"/>
								</div>
							</div>
						</div>
						<div key="generator_type" className="col-md-6">
							<FastTextField loading={loading} formik={formik} label="Generatore" field="generator_type" select
								child = {optionsGeneratorType.map((area) => (
									<MenuItem key={area} value={area}>
										{area}
									</MenuItem>
								))}
							/>
						</div>
					</div>
					<div key="fire_power_useful_power_exhaust_smoke_method" className="row styled">
						<div key="fire_power_useful_power" className="col-md-6">
							<div key="r_fire_power_useful_power" className="row">
								<div key="fire_power" className="col-md-6">
									<FastTextField loading={loading} formik={formik} label="Pot.term. nominale max al focolare" field="fire_power" type="number"/>
								</div>
								<div key="useful_power" className="col-md-6">
									<FastTextField loading={loading} formik={formik} label="Pot.term. nominale utile" field="useful_power" type="number"/>
								</div>
							</div>
						</div>
						<div key="exhaust_smoke_method" className="col-md-6">
							<FastTextField loading={loading} formik={formik} label="Modalità di evacuazione fumi" field="exhaust_smoke_method" select
								child = {optionsExhaustSmokeMethod.map((area) => (
									<MenuItem key={area} value={area}>
										{area}
									</MenuItem>
								))}
							/>
						</div>
					</div>
					<div key="climatization_acs_production_closed_at" className="row styled">
						<div key="climatization_acs_production" className="col-md-6">
							<div key="r_climatization_acs_production" className="row">
								<div key="climatization" className="col-md-6">
									<FastBoolField loading={loading} formik={formik} label="Climatizzazione invernale" field="climatization" nullable />
								</div>
								<div key="acs_production" className="col-md-6">
									<FastBoolField loading={loading} formik={formik} label="Produzione ACS" field="acs_production" nullable />
								</div>
							</div>
						</div>
						<div key="c_closed_at" className="col-md-6">
							<div key="r_closed_at" className="row">
								<div key="closed_at" className="col-md-6">
									<FastTextField loading={loading} formik={formik} label="Data di chiusura" field="closed_at" type="date"/>
								</div>
							</div>
						</div>
					</div>
					<div key="info" className="col-md-12" style={{textAlign: 'center', fontSize: '0.8em'}}>
						Nella modulistica, se i dati dell'indirizzo non sono presenti, vengono considerati quelli del Cliente
					</div>
					<div key="r_address_number_floor" className="row styled">
						<div key="address" className="col-md-6">
							<FastTextField loading={loading} formik={formik} label="Indirizzo" field="address"/>
						</div>
						<div key="number_floor" className="col-md-6">
							<div key="r_number_floor" className="row">
								<div key="number" className="col-md-6">
									<FastTextField loading={loading} formik={formik} label="Civico" field="number"/>
								</div>
								<div key="floor" className="col-md-6">
									<FastTextField loading={loading} formik={formik} label="Piano/Scala" field="floor"/>
								</div>
							</div>
						</div>
					</div>
					<div key="city_zipCode_area" className="row styled">
						<div key="city" className="col-md-6">
							<FastTextField loading={loading} formik={formik} label="Città" field="city"/>
						</div>
						<div key="zipCode_area" className="col-md-6">
							<div key="r_zipCode_area" className="row">
								<div key="zipCode" className="col-md-6">
									<FastTextField loading={loading} formik={formik} label="Cap" field="zipCode"/>
								</div>
								<div key="area" className="col-md-6">
									<FastTextField loading={loading} formik={formik} label="Provincia" field="area" select
										child = {optionsArea.map((area) => (
											<MenuItem key={area} value={area}>
												{area}
											</MenuItem>
										))}
									/>
								</div>
							</div>
						</div>
					</div>
					<div key="r_verified" className="row styled">
						<div key="verified" className="col-md-3">
							<FastBoolField loading={loading} formik={formik} label="Impianto verificato" field="verified"/>
						</div>
					</div>					
					{!onEdit && (
						<>
							<div key="info" className="col-md-12" style={{textAlign: 'center', fontSize: '0.8em'}}>
								Inserire le prime scadenze calcolate/note relative a Prova fumi e Manutenzione (se contrattualizzata)<br />
								<strong>- - - Se pertinente, calcolare la prima Prova Fumi a 4 anni per le nuove installazioni - - -</strong>
							</div>
							<div key="next_pf_date_notes_next_pf_date" className="row styled">
								<div key="next_pf_date" className="col-md-3">
									<FastTextField loading={loading} required formik={formik} label="Prossima Prova fumi" field="next_pf_date" type="date"/>
								</div>
								<div key="notes_next_pf_date" className="col-md-9">
									<FastTextField loading={loading} formik={formik} label="Note prossima Prova fumi" field="notes_next_pf_date"/>
								</div>
							</div>
							{withMaintenance && (
								<div key="next_maintenance_date_notes_next_maintenance_date" className="row styled">
									<div key="next_maintenance_date" className="col-md-3">
										<FastTextField required loading={loading} formik={formik} label="Prossima Manutenzione" field="next_maintenance_date" type="date"/>
									</div>
									<div key="notes_next_maintenance_date" className="col-md-9">
										<FastTextField loading={loading} formik={formik} label="Note prossima Manutenzione" field="notes_next_maintenance_date"/>
									</div>
								</div>
							)}
						</>
					)}
					<div className="row styled">
						<div className="col">
							{loading && (
									<>
										<>
										{displayAlert === 0 && (
											<img className="pl-3" src={loadingGif} alt="Loading..."/>
										)}
										</>
										<>
										{displayAlert === -1 && (
											<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
										)}
										</>
										<>
										{displayAlert === 1 && (
											<Alert severity="success">
												{onEdit ? (
													<>Dati modificati con successo!</>
												) : (
													<>Dati salvati con successo!</>
												)}

												{gotoBack()}
											</Alert>
										)}
										</>
									</>
							)}
							{!loading && (
								<>
									<Button
										variant="contained"
										type="submit"
									>
										{onEdit ? (
											<>Modifica dati</>
										) : (
											<>Invia dati</>
										)}
									</Button>
									{gotoBack(true)}
								</>
							)}
						</div>
					</div>
					<FormikValidation formik={formik} />
				</CardContent>
			</Card>
			<FormikValidation formik={formik} tech={true} />
		</form>
	);

}

export default InstallationForm;