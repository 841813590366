import axios from "axios";

import {API_URL} from "../Config/constants";

require('es6-promise').polyfill();
function getToken(){
	return localStorage.getItem('token') ? localStorage.getItem('token') : null;
}

function get(params) {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
			'Authorization': 'Bearer ' + getToken(),
        }
    };

    if (params.body) {
        params.endpoint += `?${objectToQueryString(params.body)}`
    }
    if (params.responseType) {
        options.responseType = params.responseType;
    }

    if(params.timeout){
        options.timeout = params.timeout;
    }

    return axios.get(`${API_URL}/${params.endpoint}`, options);

}

function post(params, setMultipart = false) {
    const options = {
        headers: {
            'Content-Type': params.contentType ? params.contentType : 'application/json',
        }
    };
	
    if (params.responseType) {
        options.responseType = params.responseType;
    }
    if (params.endpoint !== 'login') {
        options.headers = {...options.headers, 'Authorization': 'Bearer ' + getToken()};
    }    
	if (setMultipart) {
        options.headers = {...options.headers, 'Content-Type': 'multipart/form-data'};
    }
    return axios.post(`${API_URL}/${params.endpoint}`, params.contentType ? params.body : JSON.stringify(params.body), options);
}

function del(params) {
	let newBody = {
		_method: 'DELETE',
	};

	let adaptedParams = {
		...params,
		body: newBody,
	}

	return post(adaptedParams);
}

function remove(params) {

    const options = {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    };
    return axios.delete(`${API_URL}/${params.endpoint}`, options);
}

function objectToQueryString(initialObj) {
    const reducer = (obj, parentPrefix = null) => (prev, key) => {
        const val = obj[key];
        key = encodeURIComponent(key);
        const prefix = parentPrefix ? `${parentPrefix}[${key}]` : key;

        if (val == null || typeof val === 'function') {
            prev.push(`${prefix}=`);
            return prev;
        }

        if (['number', 'boolean', 'string'].includes(typeof val)) {
            prev.push(`${prefix}=${encodeURIComponent(val)}`);
            return prev;
        }

        prev.push(Object.keys(val).reduce(reducer(val, prefix), []).join('&'));
        return prev;
    };

    return Object.keys(initialObj).reduce(reducer(initialObj), []).join('&');
}
/*
async function request(params, options) {
    let response = await fetch(`${API_URL}/${params.endpoint}`, options);
    return (response.ok) ? await response.json() : [];
}
*/
const Api = {
    get,
    post,
	del,
    remove
}

export default Api
