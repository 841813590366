import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { useAuth } from '../Auth/ProvideAuth'

import Separator from "../Components/Separator";
import AppointmentList from "../Components/Appointment/AppointmentList";
import DeadlineList from "../Components/Deadline/DeadlineList";

import Api from "../Services/Api";

function Dashboard() {
	const [lastChildUpdate, setLastChildUpdate] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);
	const [displayDialog, setDisplayDialog] = useState(0);
	
	const [selectedId, setSelectedId] = useState(false);

	let auth = useAuth();
	let navigate = useNavigate();

	function a_selectedItem(eventType, id) {
		if (eventType === "edit") {
			navigate(`appointments/edit/${id}`);
		}

		if (eventType === "work") {
			setSelectedId(id);
			setOpenDialog(true);
		}
	};	
	
	function d_selectedItem(eventType, id) {
		if (eventType === "edit") {
			navigate(`deadlines/edit/${id}/0/0`);
		}

		if (eventType === "delete") {
			try {
				Api.del({endpoint: `deadline/${id}`, contentType : 'application/json'})
					.then(response => {
						setDisplayDialog(1);
						setLastChildUpdate(Date.now());
					})
					.catch(error => {
						console.log(JSON.stringify(error));
						setDisplayDialog(-1);
					});
			} catch (error) {
				console.log(JSON.stringify(error));
				setDisplayDialog(-1);
			}
		}
	};

	const handleClose = () => {
		setOpenDialog(false);
		setDisplayDialog(0);
	};

	const handleOk = () => {
		navigate(`appointments/work/${selectedId}`);
		setOpenDialog(false);
	};

	return (
		<>
			<h1>{`Ciao, ${auth.lonyUser.name}`}</h1>
			<h5>Ecco la tua Dashboard per il controllo delle attività</h5>
			<Separator />			
			<AppointmentList hook={a_selectedItem} lastUpdate={lastChildUpdate} dashboard={true} />
			<Separator />
			<DeadlineList hook={d_selectedItem} lastUpdate={lastChildUpdate} dashboard={true} />
			<Dialog
				open={openDialog}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Procedere con la lavorazione dell'appuntamento?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Procedendo si potrà lavorare l'appuntamento creando un Intervento di [Prova fumi-Manutenzione / Riparazione] o annullarlo con motivazione nel caso non sia possibile procedere all'intervento
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} autoFocus>Annulla</Button>
					<Button onClick={handleOk}>Procedi</Button>
				</DialogActions>
			</Dialog>
			
			<Dialog
				open={displayDialog !== 0}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{displayDialog === 1 && "Operazione completata"}
					{displayDialog === -1 && "Errore"}
				</DialogTitle>
				<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{displayDialog === 1 && "Operazione completata con successo!"}
							{displayDialog === -1 && "Si è verificato un errore inatteso"}
						</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Chiudi</Button>
				</DialogActions>
			</Dialog>			
		</>
	);
}

export default Dashboard;
