import { useState, useEffect } from 'react';
import { RadioGroup, FormControlLabel, Radio, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Alert, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import loadingGif from '../../Assets/loading.gif';
import Api from '../../Services/Api';
import FastTextField from "../FastTextField";
import FormikValidation from "../FormikValidation";

function SpareTakeReleaseForm(props) {
	const [apiResponse, setApiResponse] = useState(null)
	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(0);

	let spare = props.spare;
	const theme = useTheme();
	let currentQuantity = spare ? parseFloat(spare.currentQuantity) : 0;

	useEffect(() => {
		try {
			if (spare) {
				Api.get({endpoint: `spare/takeRelease/${spare.id}`, contentType : 'application/json'})
					.then(response => {
						setApiResponse(response.data.currentlyTaken);
					})
					.catch(error => {
						console.log(error);
						setApiResponse(null);
						setDisplayAlert(-1);
					});
			}
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, [spare]);	// eslint-disable-line react-hooks/exhaustive-deps

	//This form can be called only on "CREATE" mode
	const formik = useFormik({
		initialValues: {
			takeRelease: "1",
			quantity: "",
			notes: "",
			_method: 'post',
		},
		validationSchema: Yup.object({
			quantity:
				Yup.number()
				.min(0, 'Valore minimo 0')
				.max(999999.99, 'Valore massimo 999999.99')
				.required('Campo obbligatorio'),
			document_number:
				Yup.string()
				.max(1000, 'Lunghezza massima 1000 caratteri'),
		}),
		onSubmit: values => {
			if (formik.getFieldProps('quantity').value === 1) {
				if (currentQuantity < parseFloat(formik.getFieldProps('quantity').value)) {
					if (!window.confirm("La quantità scelta è maggiore di quella disponibile a magazzino!\nContinuare?")) {
						formik.resetForm();
						return;
					}
				}
			}
			else {
				if (parseFloat(formik.getFieldProps('quantity').value) > apiResponse.quantity) {
					if (!window.confirm("La quantità scelta è maggiore di quella in carico!\nContinuare?")) {
						formik.resetForm();
						return;
					}
				}
			}

			setLoading(true);

			try {
				Api.post({endpoint: `spare/takeRelease/${spare.id}`, body: values, contentType : 'application/json'})
					.then(response => {
						setDisplayAlert(1);
						formik.resetForm();
						let timer1 = setTimeout(() => {
							props.onClose();
							setLoading(false);
							clearTimeout(timer1)
						}, 2000);
					})
					.catch(error => {
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						else {
							console.log(error);
							alert("Errore inatteso!");
						}
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}
		},
		enableReinitialize: true,
	});

	function onClose() {
		if (Object.keys(formik.touched).length > 0) {
			if (!window.confirm("Uscire senza salvare le modifiche?")) {
				return false;
			}
		}
		props.onClose();
	}
	return (
		<>
			<Dialog
				open={props.open !== 0}
				onClose={props.onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Gestione presa in carico</DialogTitle>
				<DialogContent>
					<strong style={{color: theme.palette.primary.darkMain, fontSize: '0.8em'}}>
						{spare?.description}
					</strong>
					<div className="mt-2" style={{textAlign: 'center'}}>
						<Chip
							variant="outlined"
							color="primary"
							size="small"
							icon={<LocalShippingIcon />}
							label={`Attualmente in carico: ${apiResponse ? apiResponse.quantity : "N/D"}`}
						/>
					</div>
					<DialogContentText id="alert-dialog-description">
						<form
							autoComplete="off"
							noValidate
							onSubmit={formik.handleSubmit}
						>
							<div className="container">

								<div key="r_takeRelease" className="row styled">
									<div key="takeRelease" className="col-md-12">
										<RadioGroup
											aria-labelledby="takeRelease"
											defaultValue="1"
											name="takeRelease"
											{...formik.getFieldProps("takeRelease")}
										>
											<FormControlLabel
												value="1"
												control={<Radio />}
												label="Presa in carico"
											/>
											<FormControlLabel
												value="-1"
												control={<Radio />}
												label="Rilascio"
											/>
										</RadioGroup>
									</div>
								</div>
								<div key="r_quantity" className="row styled">
									<div key="quantity" className="col-md-12">
										<FastTextField required loading={loading} formik={formik} label="Quantità" field="quantity"	type="number" />
									</div>
								</div>
								<div key="r_notes" className="row styled">
									<div key="notes" className="col-md-12">
										<FastTextField loading={loading} formik={formik} label="Note" field="notes"
											multiline
											rows={4}
										/>
									</div>
								</div>
								<div className="row styled">
									<div className="col">
										{loading && (
												<>
													<>
													{displayAlert === 0 && (
														<img className="pl-3" src={loadingGif} alt="Loading..."/>
													)}
													</>
													<>
													{displayAlert === -1 && (
														<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
													)}
													</>
													<>
													{displayAlert === 1 && (
														<Alert severity="success">Dati salvati con successo!</Alert>
													)}
													</>
												</>
										)}
										{!loading && (
											<>
												<Button
													variant="contained"
													type="submit"
												>
													Invia dati
												</Button>
											</>
										)}
									</div>
								</div>
							</div> {/* container */}
							<FormikValidation formik={formik} />
						</form>
						<FormikValidation formik={formik} tech={true} />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Chiudi</Button>
				</DialogActions>
			</Dialog>			
		</>
	);
}

export default SpareTakeReleaseForm;