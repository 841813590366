import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid, itIT, GridToolbar, useGridApiRef } from '@mui/x-data-grid';		//https://mui.com/x/react-data-grid/ [MIT version]
import { Box, Alert, Button, Tooltip } from '@mui/material';


import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Api from '../../Services/Api';
import {PAGE_SIZE, pageSizeOptions} from '../../Config/constants.js';

function SupplierList(props) {
	const { hook, lastUpdate } = props;
	const apiRef = useGridApiRef();	//https://mui.com/x/api/data-grid/
	
	const columns = [
		{
			field: 'brand',
			headerName: 'Marca',
			flex: 2,
		},
		{
			field: 'prefix',
			headerName: 'Prefisso',
			flex: 1,
		},
		{
			field: 'id',
			headerName: 'Azioni',
			flex: 1,
			renderCell: (params) => {
				function onEdit(e) {
					e.stopPropagation(); // don't select this row after clicking
					hook("edit", params.value);
				};
				function onDelete(e) {
					e.stopPropagation(); // don't select this row after clicking
					if (window.confirm("Confermare l'eliminazione DEFINITIVA del Fornitore?")) {
						if (window.confirm("Eliminare il Fornitore?")) {
							hook("delete", params.value);
						}
					}
				};
				return <>
					<Tooltip title="Modifica Fornitore">
						<Button onClick={onEdit}>
							<EditIcon />
						</Button>
					</Tooltip>
					<Tooltip title="Elimina Fornitore">
						<Button onClick={onDelete}>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</>
			}
		}
	]

	function newElement() {
		hook("create");
	}

	const [rows, setRows] = useState([]);
	const [displayAlert, setDisplayAlert] = useState(0);

	useEffect(() => {
		let endPoint = 'supplier';
				
		try {
			Api.get({endpoint: endPoint, contentType : 'application/json'})
			.then(response => {
				setRows(response.data.suppliers);
			})
			.catch(error => {
				console.log(JSON.stringify(error));
				setDisplayAlert(-1);
			});
		} catch (error) {
			console.log(JSON.stringify(error));
			setDisplayAlert(-1);
		}
	}, [lastUpdate]);	// eslint-disable-line react-hooks/exhaustive-deps

	const theme = createTheme({
			palette: {
				primary: { main: '#11cb5f' },
			},
		},
		itIT,
	);
	return (
		<>
			<h5>Fornitori</h5>
			<Box sx={{ width: '100%', minWidth: '800px' }}>
				<ThemeProvider theme={theme}>
					<DataGrid
						//onRowDoubleClick={hook}
						sx={{ minHeight: '300px' }}
						rows={rows}
						columns={columns}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: PAGE_SIZE,
								},
							},
						}}
						apiRef={apiRef}
						slots={{
							toolbar: GridToolbar,
						}}
						slotProps={{
							columnsPanel: {
								disableHideAllButton: true,
							},
							toolbar: { 
								showQuickFilter: true 
							}
						}}
						pageSizeOptions={pageSizeOptions}
					/>
					<div className="mt-2">
						<Button
							variant="contained"
							onClick={newElement}
						>
							Nuovo Fornitore
						</Button>
					</div>
				</ThemeProvider>
				{displayAlert === -1 && (
					<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
				)}
			</Box>
		</>
	);
}

export default SupplierList;