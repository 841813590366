import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, Button, Alert, MenuItem, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid, GridToolbar, useGridApiRef } from '@mui/x-data-grid';		//https://mui.com/x/react-data-grid/ [MIT version]

import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import DeleteIcon from '@mui/icons-material/Delete';

import loadingGif from '../../Assets/loading.gif';
import Api from '../../Services/Api';
import FastTextField from "../FastTextField";
import FormikValidation from "../FormikValidation";
import Functions from "../../Services/Functions";
import {PAGE_SIZE, pageSizeOptions} from '../../Config/constants.js';

function SpareForm(props) {
	const [apiResponse, setApiResponse] = useState(null)
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(0);
	const [lastUpdate, setLastUpdate] = useState(null);
	const [listSuppliersResponse, setListSuppliersResponse] = useState(null)
	const navigate = useNavigate();
	const { spare, setDisplayDialog, setDisplayPurchase, setSelectedSpare, lastParentUpdate } = props;				
	const apiRef = useGridApiRef();	//https://mui.com/x/api/data-grid/
	
	let onEdit = props.spare ? true : false;
	
	const formik = useFormik({
		initialValues: {
			category: apiResponse?.category ?? "",
			code: apiResponse?.code ?? "",
			short_description: apiResponse?.short_description ?? "",
			description: apiResponse?.description ?? "",
			quantity: apiResponse?.quantity ?? "",
			position: apiResponse?.position ?? "",
			supplier_id : apiResponse?.supplier_id ?? "",
			_method: onEdit ? 'put' : 'post',
		},
		validationSchema: Yup.object({
			category:
				Yup.string()
				.min(2, 'Lunghezza minima 2 caratteri')
				.max(50, 'Lunghezza massima 50 caratteri')
				.required('Campo obbligatorio'),
			code:
				Yup.string()
				.min(2, 'Lunghezza minima 2 caratteri')
				.max(30, 'Lunghezza massima 30 caratteri')
				.required('Campo obbligatorio'),
			short_description:
				Yup.string()
				.min(2, 'Lunghezza minima 2 caratteri')
				.max(255, 'Lunghezza massima 255 caratteri')
				.required('Campo obbligatorio'),
			description:
				Yup.string()
				.max(1000, 'Lunghezza massima 1000 caratteri'),
			quantity:
				Yup.number()
				.min(0, 'Valore minimo 0')
				.max(999999.99, 'Valore massimo 999999.99'),
			position:
				Yup.string()
				.max(50, 'Lunghezza massima 50 caratteri'),
			supplier_id :
				Yup.number()
				.integer("Valore non accettato")
				.min(1)
				.max(18446744073709551615, 'Valore non accettato')	//Bigint
				.required('Campo obbligatorio'),
		}),
		onSubmit: values => {
			setLoading(true);

			try {
				let currEndpoint = onEdit ? `spare/${apiResponse.id}` : 'spare';
				Api.post({endpoint: currEndpoint, body: values, contentType : 'application/json'})
					.then(response => {
						if (response.data.spare_id) {
							navigate(`../spares/edit/${response.data.spare_id}`);
						}
						setDisplayAlert(1);
						let timer1 = setTimeout(() => {setLoading(false);clearTimeout(timer1)}, 3000);
					})
					.catch(error => {
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						else {
							console.log(error);
							alert("Errore inatteso!");
						}
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}
		},
		enableReinitialize: true,
	});

	function confirmGoBack(checkChanged) {
		if (checkChanged && Object.keys(formik.touched).length > 0) {
			if (!window.confirm("Uscire senza salvare le modifiche?")) {
				return false;
			}
		}
		return true;
	}

	function gotoBack(checkChanged) {
		return (
			<Link to={`/cms/spares`} style={{margin: 'auto'}}
				onClick={e => !confirmGoBack(checkChanged) && e.preventDefault()}
			>
				<Button
					variant="outlined"
					sx={{ ml: 3 }}
				>
					Torna alla lista!
				</Button>
			</Link>
		);
	}
	
	function newElement() {	
		setDisplayPurchase(1);
		setSelectedSpare({			
			'id': spare,
			'description': formik.getFieldProps('short_description').value,				
		});		
	}

	function renderPurchases() {
		const columns = [
			{
				field: 'brand',
				headerName: 'Fornitore',
				flex: 1,
			},
			{
				field: 'quantity',
				headerName: 'Quantità',
				flex: 1,
				valueGetter: (params) => {
					return params.row.pivot.quantity ? Functions.toDecimal(params.row.pivot.quantity) : ""
				},				
			},
			{
				field: 'unit_price',
				headerName: 'Prezzo unitario',
				flex: 1,
				type:'number',
				align:'right',
				valueGetter: (params) => {
					return params.row.pivot.unit_price ? Functions.toDecimal(params.row.pivot.unit_price) : ""
				},
			},
			{
				field: 'sale_price',
				headerName: 'Prezzo unitario vendita',
				flex: 1,
				type:'number',
				align:'right',
				valueGetter: (params) => {
					return params.row.pivot.unit_price ? Functions.toDecimal(params.row.pivot.sale_price) : ""
				},
			},
			{
				field: 'document',
				headerName: 'DDT',
				flex: 1.5,
				valueGetter: (params) => {
					return (params.row.pivot.document_number ? params.row.pivot.document_number : "") + (params.row.pivot.document_date ? " del " + moment(params.row.pivot.document_date).format('DD/MM/YYYY') : "")
				},
			},
			{
				field: 'id',
				headerName: 'Azioni',
				flex: 1,
				valueGetter: (params) => {
					return params.row.pivot.id
				},					
				renderCell: (params) => {
					function onDelete(e) {
						e.stopPropagation(); // don't select this row after clicking
						if (window.confirm("Confermare l'eliminazione DEFINITIVA dell'Acquisto?")) {
							if (window.confirm("Eliminare l'Acquisto?")) {
								try {
									Api.del({endpoint: `spare/purchase/${params.value}`, contentType : 'application/json'})
										.then(response => {
											setDisplayDialog(1);
											setLastUpdate(Date.now());
										})
										.catch(error => {
											console.log(JSON.stringify(error));
											setDisplayDialog(-1);
										});
								} catch (error) {
									console.log(JSON.stringify(error));
									setDisplayDialog(-1);
								}
							}
						}
					};
					return <>
						<Tooltip title="Elimina Acquisto">
							<Button onClick={onDelete}>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				}
			},
		]

		return (
			<>
				<h5 className="mt-3">Lista acquisti del Ricambio</h5>
				<DataGrid
					//onRowDoubleClick={hook}
					sx={{ minHeight: '300px' }}
					rows={rows}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: PAGE_SIZE,
							},
						},
					}}
					apiRef={apiRef}
					slots={{
						toolbar: GridToolbar,
					}}
					slotProps={{
						columnsPanel: {
							disableHideAllButton: true,
						}
					}}
					pageSizeOptions={pageSizeOptions}
				/>
				
				<div className="mt-2">
					<Button
						variant="contained"
						onClick={newElement}
					>
						Nuovo Acquisto
					</Button>
				</div>				
			</>
		);
	}

	function arraySuppliersResponse() {
		if (!listSuppliersResponse) {
			return (
				<MenuItem key="-1" value="-1">
					Caricamento in corso...
				</MenuItem>
			);
		}
		else {
			return (
				listSuppliersResponse.map((suppliers) => (
					<MenuItem key={suppliers.id} value={suppliers.id}>
						{suppliers.brand}
					</MenuItem>
				))
			);
		}
	}

	useEffect(() => {
		try {
			Api.get({endpoint: `supplier`, contentType : 'application/json'})
				.then(response => {
					setListSuppliersResponse(response.data.suppliers);
				})
				.catch(error => {
					console.log(error);
					setListSuppliersResponse(null);
					setDisplayAlert(-1);
				});
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
		
		try {
			if (onEdit) {
				Api.get({endpoint: `spare/${props.spare}`, contentType : 'application/json'})
					.then(response => {
						setApiResponse(response.data.spare);
					})
					.catch(error => {
						console.log(error);
						setApiResponse(null);
						setDisplayAlert(-1);
					});
			}
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}		
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (onEdit) {
			try {
				Api.get({endpoint: `spare/lists/${props.spare}/purchases`, contentType : 'application/json'})
					.then(response => {
						setRows(response.data.purchases);
					})
					.catch(error => {
						console.log(error);
						setRows([]);
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				return (error.response ? error.response.status : null);
			}
		}
	}, [lastUpdate, lastParentUpdate]);	// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<form
				autoComplete="off"
				noValidate
				onSubmit={formik.handleSubmit}
			>
				<Card>
					<CardHeader
						title="Ricambio"
						subheader="Visualizza e modifica i dati del Ricambio"
					/>
					<CardContent sx={{ pt: 0 }}>
						<div key="r_supplier_id" className="row styled">
							<div key="supplier_id" className="col-md-12">
								<FastTextField loading={loading} required formik={formik} label="Fornitore" field="supplier_id" select
									child = {arraySuppliersResponse()}
								/>
							</div>
						</div>
						<div key="category_code" className="row styled">
							<div key="category" className="col-md-8">
								<FastTextField loading={loading} required formik={formik} label="Categoria" field="category"/>
							</div>
							<div key="code" className="col-md-4">
								<FastTextField loading={loading} required formik={formik} label="Codice" field="code"/>
							</div>
						</div>
						<div key="r_short_description" className="row styled">
							<div key="category" className="col-md-12">
								<FastTextField loading={loading} required formik={formik} label="Descrizione breve" field="short_description"/>
							</div>
						</div>
						<div key="r_description" className="row styled">
							<div key="description" className="col-md-12">
								<FastTextField loading={loading} formik={formik} label="Descrizione estesa" field="description"
									multiline
									rows={4}
								/>
							</div>
						</div>
						<div key="quantity_position" className="row styled">
							<div key="quantity" className="col-md-4">
								<FastTextField loading={loading} formik={formik} label="Quantità disponibile" field="quantity" type="number" />
							</div>
							<div key="position" className="col-md-8">
								<FastTextField loading={loading} formik={formik} label="Posizione" field="position"/>
							</div>
						</div>
						<div className="row styled">
							<div className="col">
								{loading && (
										<>
											<>
											{displayAlert === 0 && (
												<img className="pl-3" src={loadingGif} alt="Loading..."/>
											)}
											</>
											<>
											{displayAlert === -1 && (
												<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
											)}
											</>
											<>
											{displayAlert === 1 && (
												<Alert severity="success">
													{onEdit ? (
														<>Dati modificati con successo!</>
													) : (
														<>Dati salvati con successo!</>
													)}
													{gotoBack()}
												</Alert>
											)}
											</>
										</>
								)}
								{!loading && (
									<>
										<Button
											variant="contained"
											type="submit"
										>
											{onEdit ? (
												<>Modifica dati</>
											) : (
												<>Invia dati</>
											)}
										</Button>
										{gotoBack(true)}
									</>
								)}
							</div>
						</div>
						<FormikValidation formik={formik} />
					</CardContent>
				</Card>
				<FormikValidation formik={formik} tech={true} />
			</form>

			{onEdit &&
				renderPurchases()
			}
		</>
	);
}

export default SpareForm;