import { useState, useEffect, useContext, useRef } from 'react';
import {Card, CardHeader, CardContent, Alert, AlertTitle, MenuItem, Button, TextField, IconButton} from '@mui/material';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';

import { SignatureComponent } from '@syncfusion/ej2-react-inputs';
import { registerLicense } from '@syncfusion/ej2-base';

import { useFormik } from 'formik';
import moment from 'moment';

import loadingGif from '../../Assets/loading.gif';
import Api from '../../Services/Api';
import {OPERATOR_ROLE} from '../../Config/constants.js';

import FastTextField from "../FastTextField";
import FastBoolField from "../FastBoolField";
import FastInfoBlock from "../FastInfoBlock";
import FormikValidation from "../FormikValidation";
import { ApWoContext } from '../../Contexts/context';
import YupHelper from "../../Services/YupHelper";

import { areas as optionsArea, persons as optionsPerson, liables as optionsLiable, tripleValueChecks as optionsTripleValueCheck, generatorTypes as optionsGeneratorType, fuels as optionsFuel, exhaustSmokeMethods as optionsExhaustSmokeMethod } from '../../Config/constants.js';

function RenderVerification(props) {
	const {user, appointment, setInEdit, isCancelled, isClosed} = props;

	const [metaResponse, setMetaResponse] = useState(null)
	const [indexedMetaResponse, setIndexedMetaResponse] = useState(null)
	const [apiResponse, setApiResponse] = useState(null)
	const [reservedStamp, setReservedStamp] = useState("NA")
	const [onEdit, setOnEdit] = useState(false) //true if form is edit-form
	const [localInEdit, setLocalInEdit] = useState(false) //true if data was changed without save
	const [loading, setLoading] = useState(false)
	const [displayAlert, setDisplayAlert] = useState(0)
	const [openDialog, setOpenDialog] = useState(false)

	// eslint-disable-next-line
	const { showContextOverlayContext, setShowContextOverlayContext } = useContext(ApWoContext);

	const theme = useTheme();

	function format(dateValue) {
		return moment(dateValue).format('YYYY-MM-DD');
	}

	const exactLengths = {
		installation_zipCode: 5,
		installation_area: 2,
		customer_zipCode: 5,
		customer_area: 2,
		verification_time_start: 5,
		verification_time_end: 5,
	};

	function setVerificationStart() {
		formik.setFieldValue("verification_start_d", moment().format('YYYY-MM-DD'));
		formik.setFieldValue("verification_start_t", moment().format('HH:mm'));
	}
	function setVerificationEnd() {
		formik.setFieldValue("verification_end_t", moment().format('HH:mm'));
	}

	const handleClose = () => {
		setOpenDialog(false);
	};

	const handleOk = () => {
		setReservedStamp("REQ");
		setOpenDialog(false);
	};

	function reserveStamp() {
		setOpenDialog(true);
	}

	function getValidationSchema() {
		if (metaResponse) {
			const cleanedMetaResponse = metaResponse.findIndex((meta) => meta?.name === "verification_start");
			delete metaResponse[cleanedMetaResponse];
			return YupHelper.validationSchema(metaResponse, exactLengths);
		}
	}

    registerLicense('Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXpceHZVRmJeVEB/WEs=');

    const signatureTecnician = useRef(null);
    const signatureCustomer = useRef(null);

    const clrBtnClick = (e) => {
        let id = e.target.id;
        if (id === "signature_tecnician_clear") {
            signatureTecnician.current.clear();
        }
        if (id === "signature_customer_clear") {
            signatureCustomer.current.clear();
        }
    }

	const formik = useFormik({
		initialValues: {
			unique_code: apiResponse?.verification.unique_code ?? (onEdit ? "" : apiResponse?.installation.unique_code ?? ""),
			power: apiResponse?.verification.power ?? (onEdit ? "" : apiResponse?.installation.power ?? ""),
			installation_address: apiResponse?.verification.installation_address ?? (onEdit ? "" : apiResponse?.installation.address ?? ""),
			installation_number: apiResponse?.verification.installation_number ?? (onEdit ? "" : apiResponse?.installation.number ?? ""),
			installation_floor: apiResponse?.verification.installation_floor ?? (onEdit ? "" : apiResponse?.installation.floor ?? ""),
			installation_city: apiResponse?.verification.installation_city ?? (onEdit ? "" : apiResponse?.installation.city ?? ""),
			installation_zipCode: apiResponse?.verification.installation_zipCode ?? (onEdit ? "" : apiResponse?.installation.zipCode ?? ""),
			installation_area: apiResponse?.verification.installation_area ?? (onEdit ? "" : apiResponse?.installation.area ?? ""),
			maintainer_company: apiResponse?.verification.maintainer_company ?? (onEdit ? "" : apiResponse?.maintainer.company ?? ""),
			maintainer_address: apiResponse?.verification.maintainer_address ?? (onEdit ? "" : apiResponse?.maintainer.address ?? ""),
			maintainer_vatCode: apiResponse?.verification.maintainer_vatCode ?? (onEdit ? "" : apiResponse?.maintainer.vatCode ?? ""),
			maintainer_number: apiResponse?.verification.maintainer_number ?? (onEdit ? "" : apiResponse?.maintainer.number ?? ""),
			maintainer_city: apiResponse?.verification.maintainer_city ?? (onEdit ? "" : apiResponse?.maintainer.city ?? ""),
			maintainer_zipCode: apiResponse?.verification.maintainer_zipCode ?? (onEdit ? "" : apiResponse?.maintainer.zipCode ?? ""),
			maintainer_area: apiResponse?.verification.maintainer_area ?? (onEdit ? "" : apiResponse?.maintainer.area ?? ""),
			maintainer_phone_number: apiResponse?.verification.maintainer_phone_number ?? (onEdit ? "" : apiResponse?.maintainer.phone_number ?? ""),
			maintainer_email: apiResponse?.verification.maintainer_email ?? (onEdit ? "" : apiResponse?.maintainer.email ?? ""),
			maintainer_email_cert: apiResponse?.verification.maintainer_email_cert ?? (onEdit ? "" : apiResponse?.maintainer.email_cert ?? ""),
			surname: apiResponse?.verification.surname ?? (onEdit ? "" : apiResponse?.customer.surname ?? ""),
			name: apiResponse?.verification.name ?? (onEdit ? "" : apiResponse?.customer.name ?? ""),
			fiscalVatCode: apiResponse?.verification.fiscalVatCode ?? (onEdit ? "" : apiResponse?.customer.fiscalVatCode ?? ""),
			person: apiResponse?.verification.person ?? (onEdit ? "" : apiResponse?.customer.person ?? ""),
			customer_phone_number: apiResponse?.verification.customer_phone_number ?? (onEdit ? "" : apiResponse?.customer.phone_number_1 ?? ""),
			customer_email: apiResponse?.verification.customer_email ?? (onEdit ? "" : apiResponse?.customer.email ?? ""),
			customer_address: apiResponse?.verification.customer_address ?? (onEdit ? "" : apiResponse?.customer.address ?? ""),
			customer_number: apiResponse?.verification.customer_number ?? (onEdit ? "" : apiResponse?.customer.number ?? ""),
			customer_floor: apiResponse?.verification.customer_floor ?? (onEdit ? "" : apiResponse?.customer.floor ?? ""),
			customer_city: apiResponse?.verification.customer_city ?? (onEdit ? "" : apiResponse?.customer.city ?? ""),
			customer_zipCode: apiResponse?.verification.customer_zipCode ?? (onEdit ? "" : apiResponse?.customer.zipCode ?? ""),
			customer_area: apiResponse?.verification.customer_area ?? (onEdit ? "" : apiResponse?.customer.area ?? ""),
			liable: apiResponse?.verification.liable ?? (onEdit ? "" : apiResponse?.installation.liable ?? ""),
			compliance: apiResponse?.verification.compliance ?? "",
			installation_book_exist: apiResponse?.verification.installation_book_exist ?? "",
			use_book_exist: apiResponse?.verification.use_book_exist ?? "",
			installation_book_compiled: apiResponse?.verification.installation_book_compiled ?? "",
			water_hardness: apiResponse?.verification.water_hardness ?? "",
			processing_heating_not_required: apiResponse?.verification.processing_heating_not_required ?? "",
			processing_heating_not_present: apiResponse?.verification.processing_heating_not_present ?? "",
			processing_heating_filtering: apiResponse?.verification.processing_heating_filtering ?? "",
			processing_heating_sweeting: apiResponse?.verification.processing_heating_sweeting ?? "",
			processing_heating_chimical: apiResponse?.verification.processing_heating_chimical ?? "",
			processing_acs_not_required: apiResponse?.verification.processing_acs_not_required ?? "",
			processing_acs_not_present: apiResponse?.verification.processing_acs_not_present ?? "",
			processing_acs_filtering: apiResponse?.verification.processing_acs_filtering ?? "",
			processing_acs_sweeting: apiResponse?.verification.processing_acs_sweeting ?? "",
			processing_acs_chimical: apiResponse?.verification.processing_acs_chimical ?? "",
			suitable_venue: apiResponse?.verification.suitable_venue ?? "",
			suitable_generator: apiResponse?.verification.suitable_generator ?? "",
			ventilation_holes_free: apiResponse?.verification.ventilation_holes_free ?? "",
			ventilation_holes_size_ok: apiResponse?.verification.ventilation_holes_size_ok ?? "",
			exhaust_smoke_ok: apiResponse?.verification.exhaust_smoke_ok ?? "",
			env_temperature_system: apiResponse?.verification.env_temperature_system ?? "",
			no_fluid_loss: apiResponse?.verification.no_fluid_loss ?? "",
			system_seal_ok: apiResponse?.verification.system_seal_ok ?? "",
			gt: apiResponse?.verification.gt ?? "",
			installation_date: apiResponse?.verification.installation_date ? format(apiResponse?.verification.installation_date) : (onEdit ? "" : (apiResponse?.installation.installation_date ? format(apiResponse?.installation.installation_date) : "")),
			brand: apiResponse?.verification.brand ?? (onEdit ? "" : apiResponse?.installation.brand ?? ""),
			model: apiResponse?.verification.model ?? (onEdit ? "" : apiResponse?.installation.model ?? ""),
			serial: apiResponse?.verification.serial ?? (onEdit ? "" : apiResponse?.installation.serial ?? ""),
			generator_type: apiResponse?.verification.generator_type ?? (onEdit ? "" : apiResponse?.installation.generator_type ?? ""),
			fire_power: apiResponse?.verification.fire_power ?? (onEdit ? "" : apiResponse?.installation.fire_power ?? ""),
			useful_power: apiResponse?.verification.useful_power ?? (onEdit ? "" : apiResponse?.installation.useful_power ?? ""),
			climatization: apiResponse?.verification.climatization ?? (onEdit ? "" : apiResponse?.installation.climatization ?? ""),
			acs_production: apiResponse?.verification.acs_production ?? (onEdit ? "" : apiResponse?.installation.acs_production ?? ""),
			fuel: apiResponse?.verification.fuel ?? (onEdit ? "" : apiResponse?.installation.fuel ?? ""),
			exhaust_smoke_method: apiResponse?.verification.exhaust_smoke_method ?? (onEdit ? "" : apiResponse?.installation.exhaust_smoke_method ?? ""),
			depression: apiResponse?.verification.depression ?? "",
			command_systems_ok: apiResponse?.verification.command_systems_ok ?? "",
			security_systems_ok: apiResponse?.verification.security_systems_ok ?? "",
			security_valve_free: apiResponse?.verification.security_valve_free ?? "",
			exchanger_cleaned_checked: apiResponse?.verification.exchanger_cleaned_checked ?? "",
			reflux_present: apiResponse?.verification.reflux_present ?? "",
			uni_10389_1_compliant: apiResponse?.verification.uni_10389_1_compliant ?? "",
			smoke_temp: apiResponse?.verification.smoke_temp ?? "",
			air_temp: apiResponse?.verification.air_temp ?? "",
			o2: apiResponse?.verification.o2 ?? "",
			co2: apiResponse?.verification.co2 ?? "",
			bacharach: apiResponse?.verification.bacharach_1 ? apiResponse?.verification.bacharach_1 + "/" + apiResponse?.verification.bacharach_2 + "/" + apiResponse?.verification.bacharach_3 : "",
			bacharach_1: apiResponse?.verification.bacharach_1 ?? "",
			bacharach_2: apiResponse?.verification.bacharach_2 ?? "",
			bacharach_3: apiResponse?.verification.bacharach_3 ?? "",
			co_fixed: apiResponse?.verification.co_fixed ?? "",
			combustion_yield: apiResponse?.verification.combustion_yield ?? "",
			min_yield: apiResponse?.verification.min_yield ?? "",
			check_list_valves: apiResponse?.verification.check_list_valves ?? "",
			check_list_isolation: apiResponse?.verification.check_list_isolation ?? "",
			check_list_treatment: apiResponse?.verification.check_list_treatment ?? "",
			check_list_replacement: apiResponse?.verification.check_list_replacement ?? "",
			remarks: apiResponse?.verification.remarks ?? "",
			recommendations: apiResponse?.verification.recommendations ?? "",
			prescriptions: apiResponse?.verification.prescriptions ?? "",
			can_work: apiResponse?.verification.can_work ?? "",
			verification_start_d: apiResponse?.verification.verification_start ? moment(apiResponse?.verification.verification_start).format('YYYY-MM-DD') : "",
			verification_start_t: apiResponse?.verification.verification_start ? moment(apiResponse?.verification.verification_start).format('HH:mm') : "",
			verification_start: "",
			verification_end_t: apiResponse?.verification.verification_end ? moment(apiResponse?.verification.verification_end).format('HH:mm') : "",
			verification_end: "",
			price: apiResponse?.verification.price ?? "",
			signature_tecnician: apiResponse?.verification.signature_tecnician ?? "",
			signature_customer: apiResponse?.verification.signature_customer ?? "",
			module_num: 1,
			technician_id: apiResponse?.tecnician.id ?? (user.roles[0].name === OPERATOR_ROLE ? user.id : ""),
			appointment_id: appointment,
			id: onEdit ? apiResponse?.verification.id : -1,
			_method: onEdit ? 'put' : 'post',
		},
		validate : values => {
			setInEdit(true);
            setLocalInEdit(true);

            let errors = {}
			let bacharachPattern = /^((\d){1,3})\/((\d){1,3})\/((\d){1,3})$/;
            if(values.bacharach && !bacharachPattern.test(values.bacharach)) {
                errors.bacharach = "Formato del campo non valido: NN/NN/NN";
            }

            if(!values.verification_start_d) {
                errors.verification_start_d = "Campo obbligatorio!";
            }
            if(!values.verification_start_t) {
                errors.verification_start_t = "Campo obbligatorio!";
            }

            return errors
        },
		validationSchema: getValidationSchema,
		onSubmit: values => {
			if (reservedStamp === "NA") {
				if (!window.confirm("Stai salvando senza bollino della Provincia!\nContinuare?"))
					return;
			}
			setLoading(true);

            if (!signatureTecnician.current.isEmpty()) {
                values.signature_tecnician = signatureTecnician.current.getSignature();
            }
            else {
                values.signature_tecnician = null;
            }
            if (!signatureCustomer.current.isEmpty()) {
                values.signature_customer = signatureCustomer.current.getSignature();
            }
            else {
                values.signature_customer = null;
            }

			if (values.bacharach) {
				let bac = values.bacharach.split('/');
				values.bacharach_1 = bac[0];
				values.bacharach_2 = bac[1];
				values.bacharach_3 = bac[2];
			}
			else {
				values.bacharach_1 = "";
				values.bacharach_2 = "";
				values.bacharach_3 = "";
			}

			if (values.verification_start_d && values.verification_start_t) {
				values.verification_start = values.verification_start_d + " " + values.verification_start_t;
			}
			else {
				 values.verification_start = "";
			}
			if (values.verification_start_d && values.verification_end_t) {
				values.verification_end = values.verification_start_d + " " + values.verification_end_t;
			}
			else {
				 values.verification_end = "";
			}

			values.stamp_id = apiResponse?.verification.stamp_id;
			if (reservedStamp === "NA") {
				values.stamp_id = "{notApplied}";
			}
			if (reservedStamp === "REQ") {
				values.stamp_id = "{reserved}";
			}

			try {
				let currEndpoint = onEdit ? `verification/${apiResponse.verification.id}` : 'verification';
				Api.post({endpoint: currEndpoint, body: values, contentType : 'application/json'})
					.then(response => {
						if (response.data.verification_id) {
							setOnEdit(true);
						}
						setDisplayAlert(1);
						let timer1 = setTimeout(() => {setLoading(false);clearTimeout(timer1)}, 3000);
					})
					.catch(error => {
						if (error.response.status === 412) {
							if (error.response.data.code === 1062) {
								alert("Il bollino prenotato è stato utilizzato da un'altra postazione!\nRiprovare il salvataggio dei dati");
							}
							if (error.response.data.code === 404) {
								alert("Per la provincia dell'impianto non ci sono bollini liberi disponibili!");
							}
							setDisplayAlert(0);
							setLoading(false);
							setReservedStamp("NA");
							return;
						}
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							setDisplayAlert(-1);
							return;
						}

						console.log(error);
						alert("Errore inatteso!");
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}

			setInEdit(false);
			setLocalInEdit(false);
		},
		enableReinitialize: true,
	});

	useEffect(() => {
		try {
			Api.get({endpoint: 'verification/table/schema', contentType : 'application/json'})
				.then(response => {
					setMetaResponse(response.data.fields);
					setIndexedMetaResponse(response.data.idexedFields);
					Api.get({endpoint: `verification/work/${props.appointment}`, contentType : 'application/json'})
						.then(response => {
							setApiResponse(response.data);
							if (response.data.verification.stamp_id) {
								setReservedStamp("OK");
							}

							if (response.data.verification.signature_tecnician) {
                                signatureTecnician.current.load(response.data.verification.signature_tecnician);
                            }
							if (response.data.verification.signature_customer) {
								signatureCustomer.current.load(response.data.verification.signature_customer);
							}

							if (response.data.verification.id && response.data.verification.id !== -1)
								setOnEdit(true);
						})
						.catch(error => {
							console.log(error);
							setApiResponse(null);
							setDisplayAlert(-1);
						});
				})
				.catch(error => {
					console.log(error);
					setApiResponse(null);
					setDisplayAlert(-1);
				});
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, [onEdit]);	// eslint-disable-line react-hooks/exhaustive-deps

	const indexedMeta = (field) => {
		return indexedMetaResponse && indexedMetaResponse[field]
	}

	const renderDialog = () => {
		if (formik.getFieldProps('power').value) {
			return <>
				<DialogTitle id="alert-dialog-title">
					{"Confermare la prenotazione del bollino?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Procedendo, il sistema prenoterà un bollino provinciale e lo applicherà in modo DEFINITIVO a questa "Prova fumi", rendendola un documento ufficiale.<br />
						Il documento verrà così trasmesso alla Provincia di competenza entro le tempistiche di legge.<br />
						Continuare?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} autoFocus>Annulla</Button>
					<Button onClick={handleOk}>Procedi</Button>
				</DialogActions>
			</>
		}
		else {
			return <>
				<DialogTitle id="alert-dialog-title">
					{"Prenotazione del bollino"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Per poter prenotare un bollino è necessario che il campo "Potenza" sia correttamente compilato,
						perchè la tipologia dipende da tale valore
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} autoFocus>Chiudi</Button>
				</DialogActions>
			</>
		}
	}

    const printPdf = () => {
        if (localInEdit) {
            alert("Salvare il documento per poter effettuare la stampa!");
            return;
        }
        setDisplayAlert(0);
        setLoading(true);
        Api.get({endpoint: `export/pdf/verification/${apiResponse.verification.id}`, responseType: 'blob', axios: true})
            .then(response => {
                const file = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL, "_blank");
                setLoading(false);
            })
            .catch(error => {
                setDisplayAlert(-1);
                console.log(error);
            });
    }

	return (
		<>
			{isCancelled && (
				<Alert severity="error" sx={{marginBottom: '10px'}}>
					<AlertTitle>ATTENZIONE</AlertTitle>
					Stai lavorando su un appuntamento che è stato cancellato!</Alert>
			)}

			{isClosed && (
				<Alert severity="warning" sx={{marginBottom: '10px'}}>
					<AlertTitle>ATTENZIONE</AlertTitle>
					Stai guardando un appuntamento che è stato segnato come CHIUSO!</Alert>
			)}

			<Card>
				<CardHeader
					title="Prova fumi - Manutenzione"
					subheader="Visualizza e modifica i dati di Prova fumi e Manutenzione"
				/>
				<CardContent sx={{ pt: 0 }}>
					<form
						autoComplete="off"
						noValidate
						onSubmit={formik.handleSubmit}
					>
						<p><em>Il sistema differenzia la Prova fumi e la Manutenzione, per le scadenze e le altre configurazioni, sulla base dell'applicazione virtuale del bollino della Provincia</em></p>

						<h1 className="formSection">A. DATI IDENTIFICATIVI</h1>
						<h2 className="formSection">Impianto</h2>
						<div key="unique_code_stamp_id_serial_power" className="row styled">
							<div key="unique_code_stamp_id" className="col-md-6">
								<div key="r_unique_code_stamp_id" className="row">
									<div key="unique_code" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('unique_code')}/>
									</div>
									<div key="stamp_id " className="col-md-6">
										<FastInfoBlock label={indexedMeta('stamp_id')?.label} content={apiResponse?.verification.stamp_id ? apiResponse.verification.stamp_id : (reservedStamp === "REQ" ? "{applicato}" : "{non applicato}")} {...props} />
									</div>
								</div>
							</div>
							<div key="serial_power" className="col-md-6">
								<div key="r_serial_power" className="row">
									<div key="serial" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('serial')}/>
									</div>
									<div key="power" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('power')}/>
									</div>
								</div>
							</div>
						</div>
						<div key="r_installation_address_installation_number_installation_floor" className="row styled">
							<div key="installation_address" className="col-md-6">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('installation_address')}/>
							</div>
							<div key="installation_number_installation_floor" className="col-md-6">
								<div key="r_installation_number_installation_floor" className="row">
									<div key="installation_number" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('installation_number')}/>
									</div>
									<div key="installation_floor" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('installation_floor')}/>
									</div>
								</div>
							</div>
						</div>
						<div key="installation_city_installation_zipCode_installation_area" className="row styled">
							<div key="installation_city" className="col-md-6">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('installation_city')}/>
							</div>
							<div key="installation_zipCode_installation_area" className="col-md-6">
								<div key="r_installation_zipCode_installation_area" className="row">
									<div key="installation_zipCode" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('installation_zipCode')}/>
									</div>
									<div key="installation_area" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('installation_area')} select
											child = {optionsArea.map((area) => (
												<MenuItem key={area} value={area}>
													{area}
												</MenuItem>
											))}
										/>
									</div>
								</div>
							</div>
						</div>
						<h2 className="formSection">Responsabile dell'impianto</h2>
						<div key="surname_name" className="row styled">
							<div key="surname" className="col-md-6">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('surname')}/>
							</div>
							<div key="name" className="col-md-6">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('name')}/>
							</div>
						</div>
						<div key="fiscalVatCode_person" className="row styled">
							<div key="fiscalVatCode" className="col-md-6">
								<div key="r_fiscalVatCode" className="row">
									<div key="fiscalVatCode" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('fiscalVatCode')}/>
									</div>
								</div>
							</div>
							<div key="person" className="col-md-6">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('person')} select
									child = {optionsPerson.map((person) => (
										<MenuItem key={person} value={person}>
											{person}
										</MenuItem>
									))}
								/>
							</div>
						</div>
						<div key="r_customer_address_customer_number_customer_floor" className="row styled">
							<div key="customer_address" className="col-md-6">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('customer_address')}/>
							</div>
							<div key="customer_number_customer_floor" className="col-md-6">
								<div key="r_customer_number_customer_floor" className="row">
									<div key="customer_number" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('customer_number')}/>
									</div>
									<div key="customer_floor" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('customer_floor')}/>
									</div>
								</div>
							</div>
						</div>
						<div key="customer_city_customer_zipCode_customer_area" className="row styled">
							<div key="customer_city" className="col-md-6">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('customer_city')}/>
							</div>
							<div key="customer_zipCode_customer_area" className="col-md-6">
								<div key="r_customer_zipCode_customer_area" className="row">
									<div key="customer_zipCode" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('customer_zipCode')}/>
									</div>
									<div key="customer_area" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('customer_area')} select
											child = {optionsArea.map((area) => (
												<MenuItem key={area} value={area}>
													{area}
												</MenuItem>
											))}
										/>
									</div>
								</div>
							</div>
						</div>
						<div key="liable_customer_email_customer_phone_number" className="row styled">
							<div key="customer_email_customer_phone_number" className="col-md-6">
								<div key="r_customer_email_customer_phone_number" className="row">
									<div key="customer_email" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('customer_email')}/>
									</div>
									<div key="customer_phone_number" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('customer_phone_number')}/>
									</div>
								</div>
							</div>
							<div key="liable" className="col-md-6">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('liable')} select
									child = {optionsLiable.map((liable) => (
										<MenuItem key={liable} value={liable}>
											{liable}
										</MenuItem>
									))}
								/>
							</div>
						</div>
						<h2 className="formSection">Impresa manutentrice</h2>
						<div key="maintainer_company_maintainer_address" className="row styled">
							<div key="maintainer_company" className="col-md-6">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('maintainer_company')}/>
							</div>
							<div key="maintainer_address_maintainer_vatCode" className="col-md-6">
								<div key="r_maintainer_address_maintainer_vatCode" className="row">
									<div key="maintainer_address" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('maintainer_address')}/>
									</div>
									<div key="maintainer_vatCode" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('maintainer_vatCode')}/>
									</div>
								</div>
							</div>
						</div>
						<div key="maintainer_number_maintainer_city_maintainer_zipCode_maintainer_area" className="row styled">
							<div key="maintainer_number_maintainer_city" className="col-md-6">
								<div key="r_maintainer_number_maintainer_city" className="row">
									<div key="maintainer_number" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('maintainer_number')}/>
									</div>
									<div key="maintainer_city" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('maintainer_city')}/>
									</div>
								</div>
							</div>
							<div key="maintainer_zipCode_maintainer_area" className="col-md-6">
								<div key="r_maintainer_zipCode_maintainer_area" className="row">
									<div key="maintainer_zipCode" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('maintainer_zipCode')}/>
									</div>
									<div key="maintainer_area" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('maintainer_area')}/>
									</div>
								</div>
							</div>
						</div>
						<h1 className="formSection">B. DOCUMENTAZIONE TECNICA A CORREDO</h1>
						<div key="compliance_installation_book_exist_use_book_exist_installation_book_compiled" className="row styled">
							<div key="compliance_installation_book_exist" className="col-md-6">
								<div key="r_compliance_installation_book_exist" className="row">
									<div key="compliance" className="col-md-6">
										<FastBoolField loading={loading} formik={formik} meta={indexedMeta('compliance')}/>
									</div>
									<div key="installation_book_exist" className="col-md-6">
										<FastBoolField loading={loading} formik={formik} meta={indexedMeta('installation_book_exist')}/>
									</div>
								</div>
							</div>
							<div key="use_book_exist_installation_book_compiled" className="col-md-6">
								<div key="r_use_book_exist_installation_book_compiled" className="row">
									<div key="use_book_exist" className="col-md-6">
										<FastBoolField loading={loading} formik={formik} meta={indexedMeta('use_book_exist')}/>
									</div>
									<div key="installation_book_compiled" className="col-md-6">
										<FastBoolField loading={loading} formik={formik} meta={indexedMeta('installation_book_compiled')}/>
									</div>
								</div>
							</div>
						</div>
						<h1 className="formSection">C. TRATTAMENTO DELL'ACQUA</h1>
						<div key="r_water_hardness" className="row styled">
							<div key="water_hardness" className="col-md-3">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('water_hardness')}/>
							</div>
						</div>
						<h2 className="formSection">Trattamento in riscaldamento</h2>
						<div key="ph_not_required_ph_not_present_ph_filtering_ph_sweeting_ph_chimical" className="row styled">
							<div key="ph_not_required" className="col-md-2">
								<FastBoolField loading={loading} formik={formik} meta={indexedMeta('processing_heating_not_required')}/>
							</div>
							<div key="ph_not_present" className="col-md-2">
								<FastBoolField loading={loading} formik={formik} meta={indexedMeta('processing_heating_not_present')}/>
							</div>
							<div key="ph_filtering" className="col-md-2">
								<FastBoolField loading={loading} formik={formik} meta={indexedMeta('processing_heating_filtering')}/>
							</div>
							<div key="ph_sweeting" className="col-md-2">
								<FastBoolField loading={loading} formik={formik} meta={indexedMeta('processing_heating_sweeting')}/>
							</div>
							<div key="ph_chimical" className="col-md-2">
								<FastBoolField loading={loading} formik={formik} meta={indexedMeta('processing_heating_chimical')}/>
							</div>
						</div>
						<h2 className="formSection">Trattamento in ACS</h2>
						<div key="pa_not_required_pa_not_present_pa_filtering_pa_sweeting_pa_chimical" className="row styled">
							<div key="pa_not_required" className="col-md-2">
								<FastBoolField loading={loading} formik={formik} meta={indexedMeta('processing_acs_not_required')}/>
							</div>
							<div key="pa_not_present" className="col-md-2">
								<FastBoolField loading={loading} formik={formik} meta={indexedMeta('processing_acs_not_present')}/>
							</div>
							<div key="pa_filtering" className="col-md-2">
								<FastBoolField loading={loading} formik={formik} meta={indexedMeta('processing_acs_filtering')}/>
							</div>
							<div key="pa_sweeting" className="col-md-2">
								<FastBoolField loading={loading} formik={formik} meta={indexedMeta('processing_acs_sweeting')}/>
							</div>
							<div key="pa_chimical" className="col-md-2">
								<FastBoolField loading={loading} formik={formik} meta={indexedMeta('processing_acs_chimical')}/>
							</div>
						</div>
						<h1 className="formSection">D. CONTROLLO DELL'IMPIANTO</h1>
						<div key="suitable_venue_suitable_generator_ventilation_holes_free_ventilation_holes_size_ok" className="row styled">
							<div key="suitable_venue" className="col-md-3">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('suitable_venue')} select
									child = {optionsTripleValueCheck.map((tripleValueCheck) => (
										<MenuItem key={tripleValueCheck} value={tripleValueCheck}>
											{tripleValueCheck}
										</MenuItem>
									))}
								/>
							</div>
							<div key="suitable_generator" className="col-md-3">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('suitable_generator')} select
									child = {optionsTripleValueCheck.map((tripleValueCheck) => (
										<MenuItem key={tripleValueCheck} value={tripleValueCheck}>
											{tripleValueCheck}
										</MenuItem>
									))}
								/>
							</div>
							<div key="ventilation_holes_free" className="col-md-3">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('ventilation_holes_free')} select
									child = {optionsTripleValueCheck.map((tripleValueCheck) => (
										<MenuItem key={tripleValueCheck} value={tripleValueCheck}>
											{tripleValueCheck}
										</MenuItem>
									))}
								/>
							</div>
							<div key="ventilation_holes_size_ok" className="col-md-3">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('ventilation_holes_size_ok')} select
									child = {optionsTripleValueCheck.map((tripleValueCheck) => (
										<MenuItem key={tripleValueCheck} value={tripleValueCheck}>
											{tripleValueCheck}
										</MenuItem>
									))}
								/>
							</div>
						</div>
						<div key="exhaust_smoke_ok_env_temperature_system_no_fluid_loss_system_seal_ok" className="row styled">
							<div key="exhaust_smoke_ok" className="col-md-3">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('exhaust_smoke_ok')} select
									child = {optionsTripleValueCheck.map((tripleValueCheck) => (
										<MenuItem key={tripleValueCheck} value={tripleValueCheck}>
											{tripleValueCheck}
										</MenuItem>
									))}
								/>
							</div>
							<div key="env_temperature_system" className="col-md-3">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('env_temperature_system')} select
									child = {optionsTripleValueCheck.map((tripleValueCheck) => (
										<MenuItem key={tripleValueCheck} value={tripleValueCheck}>
											{tripleValueCheck}
										</MenuItem>
									))}
								/>
							</div>
							<div key="no_fluid_loss" className="col-md-3">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('no_fluid_loss')} select
									child = {optionsTripleValueCheck.map((tripleValueCheck) => (
										<MenuItem key={tripleValueCheck} value={tripleValueCheck}>
											{tripleValueCheck}
										</MenuItem>
									))}
								/>
							</div>
							<div key="system_seal_ok" className="col-md-3">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('system_seal_ok')} select
									child = {optionsTripleValueCheck.map((tripleValueCheck) => (
										<MenuItem key={tripleValueCheck} value={tripleValueCheck}>
											{tripleValueCheck}
										</MenuItem>
									))}
								/>
							</div>
						</div>
						<h1 className="formSection">E. CONTROLLO E VERIFICA ENERGETICA DEL GRUPPO TERMICO</h1>
						<div key="gt_installation_date_generator_type" className="row styled">
							<div key="gt_installation_date" className="col-md-6">
								<div key="r_gt_installation_date" className="row">
									<div key="gt" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('gt')}/>
									</div>
									<div key="installation_date" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('installation_date')}/>
									</div>
								</div>
							</div>
							<div key="generator_type" className="col-md-6">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('generator_type')} select
									child = {optionsGeneratorType.map((generatorType) => (
										<MenuItem key={generatorType} value={generatorType}>
											{generatorType}
										</MenuItem>
									))}
								/>
							</div>
						</div>
						<div key="brand_model_fire_power_useful_power" className="row styled">
							<div key="brand_model" className="col-md-6">
								<div key="r_brand_model" className="row">
									<div key="brand" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('brand')}/>
									</div>
									<div key="model" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('model')}/>
									</div>
								</div>
							</div>
							<div key="fire_power_useful_power" className="col-md-6">
								<div key="r_fire_power_useful_power" className="row">
									<div key="fire_power" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('fire_power')}/>
									</div>
									<div key="useful_power" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('useful_power')}/>
									</div>
								</div>
							</div>
						</div>
						<div key="climatization_acs_production_fuel_exhaust_smoke_method" className="row styled">
							<div key="climatization_acs_production" className="col-md-6">
								<div key="r_climatization_acs_production" className="row">
									<div key="climatization" className="col-md-6">
										<FastBoolField loading={loading} formik={formik} meta={indexedMeta('climatization')}/>
									</div>
									<div key="acs_production" className="col-md-6">
										<FastBoolField loading={loading} formik={formik} meta={indexedMeta('acs_production')}/>
									</div>
								</div>
							</div>
							<div key="fuel_exhaust_smoke_method" className="col-md-6">
								<div key="r_fuel_exhaust_smoke_method" className="row">
									<div key="fuel" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('fuel')} select
											child = {optionsFuel.map((fuel) => (
												<MenuItem key={fuel} value={fuel}>
													{fuel}
												</MenuItem>
											))}
										/>
									</div>
									<div key="exhaust_smoke_method" className="col-md-6">
										<FastTextField loading={loading} formik={formik} meta={indexedMeta('exhaust_smoke_method')} select
											child = {optionsExhaustSmokeMethod.map((exhaustSmokeMethod) => (
												<MenuItem key={exhaustSmokeMethod} value={exhaustSmokeMethod}>
													{exhaustSmokeMethod}
												</MenuItem>
											))}
										/>
									</div>
								</div>
							</div>
						</div>

						<div key="command_systems_ok_security_systems_ok_security_valve_free" className="row styled">
							<div key="command_systems_ok" className="col-md-4">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('command_systems_ok')} select
									child = {optionsTripleValueCheck.map((tripleValueCheck) => (
										<MenuItem key={tripleValueCheck} value={tripleValueCheck}>
											{tripleValueCheck}
										</MenuItem>
									))}
								/>
							</div>
							<div key="security_systems_ok" className="col-md-4">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('security_systems_ok')} select
									child = {optionsTripleValueCheck.map((tripleValueCheck) => (
										<MenuItem key={tripleValueCheck} value={tripleValueCheck}>
											{tripleValueCheck}
										</MenuItem>
									))}
								/>
							</div>
							<div key="security_valve_free" className="col-md-4">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('security_valve_free')} select
									child = {optionsTripleValueCheck.map((tripleValueCheck) => (
										<MenuItem key={tripleValueCheck} value={tripleValueCheck}>
											{tripleValueCheck}
										</MenuItem>
									))}
								/>
							</div>
						</div>
						<div key="exchanger_cleaned_checked_reflux_present_uni_10389_1_compliant" className="row styled">
							<div key="exchanger_cleaned_checked" className="col-md-4">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('exchanger_cleaned_checked')} select
									child = {optionsTripleValueCheck.map((tripleValueCheck) => (
										<MenuItem key={tripleValueCheck} value={tripleValueCheck}>
											{tripleValueCheck}
										</MenuItem>
									))}
								/>
							</div>
							<div key="reflux_present" className="col-md-4">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('reflux_present')} select
									child = {optionsTripleValueCheck.map((tripleValueCheck) => (
										<MenuItem key={tripleValueCheck} value={tripleValueCheck}>
											{tripleValueCheck}
										</MenuItem>
									))}
								/>
							</div>
							<div key="uni_10389_1_compliant" className="col-md-4">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('uni_10389_1_compliant')} select
									child = {optionsTripleValueCheck.map((tripleValueCheck) => (
										<MenuItem key={tripleValueCheck} value={tripleValueCheck}>
											{tripleValueCheck}
										</MenuItem>
									))}
								/>
							</div>
						</div>
						<hr />
						<div key="depression_smoke_temp_air_temp_o2_co2_bacharach" className="row styled">
							<div key="depression" className="col-md-2">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('depression')}/>
							</div>
							<div key="smoke_temp" className="col-md-2">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('smoke_temp')}/>
							</div>
							<div key="air_temp" className="col-md-2">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('air_temp')}/>
							</div>
							<div key="o2" className="col-md-2">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('o2')}/>
							</div>
							<div key="co2" className="col-md-2">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('co2')}/>
							</div>
							<div key="bacharach" className="col-md-2">
								<TextField
									fullWidth
									helperText={formik.touched['bacharach'] && formik.errors['bacharach'] ? formik.errors['bacharach'] : null}
									error={Boolean(formik.touched['bacharach'] && formik.errors['bacharach'])}
									label="Bacharach"
									name='bacharach'
									required={indexedMeta('bacharach_1') ? indexedMeta('bacharach_1')?.nullable === "NO" : false}
									disabled={Boolean(loading)}
									{...formik.getFieldProps('bacharach')}
									className="styled"
									InputProps={{
										style: {color: theme.palette.primary.darkMain},
										placeholder: '____ / ____ / ____'
									}}
								/>
							</div>
						</div>
						<div key="co_fixed_combustion_yield_min_yield" className="row styled">
							<div key="co_fixed" className="col-md-2">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('co_fixed')}/>
							</div>
							<div key="combustion_yield" className="col-md-2">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('combustion_yield')}/>
							</div>
							<div key="min_yield" className="col-md-2">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('min_yield')}/>
							</div>
						</div>
						<hr />
						<h1 className="formSection">F. CHECK-LIST</h1>
						<p>Elenco di possibili interventi, dei quali va valutata la convenienza economica, che qualora applicabili all’impianto, potrebbero comportare un miglioramento della prestazione energetica</p>
						<div key="check_list_valves_check_list_isolation_check_list_treatment_check_list_replacement" className="row styled">
							<div key="check_list_valves" className="col-md-3">
								<FastBoolField loading={loading} formik={formik} meta={indexedMeta('check_list_valves')}/>
							</div>
							<div key="check_list_isolation" className="col-md-3">
								<FastBoolField loading={loading} formik={formik} meta={indexedMeta('check_list_isolation')}/>
							</div>
							<div key="check_list_treatment" className="col-md-3">
								<FastBoolField loading={loading} formik={formik} meta={indexedMeta('check_list_treatment')}/>
							</div>
							<div key="check_list_replacement" className="col-md-3">
								<FastBoolField loading={loading} formik={formik} meta={indexedMeta('check_list_replacement')}/>
							</div>
						</div>
						<h1 className="formSection">OSSERVAZIONI</h1>
                        <p style={{'fontSize': '0.8em', 'fontWeight': 'bold'}}>Se si superano i 140 caratteri, sul documento stampato il testo verrà troncato ma resterà memorizzato sul sistema informatico</p>
						<FastTextField loading={loading} formik={formik} meta={indexedMeta('remarks')}
							multiline
							rows={4}
						/>
						<h1 className="formSection">RACCOMANDAZIONI</h1>
                        <p style={{'fontSize': '0.8em', 'fontWeight': 'bold'}}>Se si superano i 140 caratteri, sul documento stampato il testo verrà troncato ma resterà memorizzato sul sistema informatico</p>
						<FastTextField loading={loading} formik={formik} meta={indexedMeta('recommendations')}
							multiline
							rows={4}
						/>
						<h1 className="formSection">PRESCRIZIONI</h1>
                        <p style={{'fontSize': '0.8em', 'fontWeight': 'bold'}}>Se si superano i 140 caratteri, sul documento stampato il testo verrà troncato ma resterà memorizzato sul sistema informatico</p>
						<FastTextField loading={loading} formik={formik} meta={indexedMeta('prescriptions')}
							multiline
							rows={4}
						/>
						<p>Il tecnico dichiara, in riferimento ai punti A,B,C,D,E (sopra menzionati), che l’apparecchio può essere messo in servizio ed usato normalmente ai fini dell’efficienza energetica senza compromettere la sicurezza delle persone, degli animali e dei beni.</p>
						<div key="can_work_verification_start_d" className="row">
							<div key="can_work" className="col-md-3">
								<FastBoolField loading={loading} formik={formik} meta={indexedMeta('can_work')}/>
							</div>
							<div key="verification_start_d" className="col-md-3">
								<FastTextField loading={loading} required={indexedMeta('verification_start')?.nullable === "NO"} formik={formik} label="Data del presente controllo" field="verification_start_d" type="date" />
							</div>
							<div key="verification_start_t" className="col-md-3">
								<FastTextField
									loading={loading} required={indexedMeta('verification_start')?.nullable === "NO"} formik={formik} label="Orario di arrivo presso l’impianto" field="verification_start_t" type="time"
									sx={{
										width: '90%',
									}}
								/>
								<IconButton
									aria-label="toggle password visibility"
									onClick={setVerificationStart}
									edge="end"
								>
									<AlarmOnIcon/>
								</IconButton>
							</div>
							<div key="verification_end_t" className="col-md-3">
								<FastTextField
									loading={loading} required={indexedMeta('verification_end')?.nullable === "NO"} formik={formik} label="Orario di partenza dall’impianto" field="verification_end_t" type="time"
									sx={{
										width: '90%',
									}}
								/>
								<IconButton
									aria-label="toggle password visibility"
									onClick={setVerificationEnd}
									edge="end"
								>
									<AlarmOnIcon/>
								</IconButton>
							</div>
						</div>
						<div key="technician_price" className="row styled">
							<div key="technician" className="col-md-6">
								<FastInfoBlock label={indexedMeta('technician_id')?.label} content={`${apiResponse?.tecnician.surname} ${apiResponse?.tecnician.name}`} {...props} />
							</div>
							<div key="price" className="col-md-3">
								<FastTextField loading={loading} formik={formik} meta={indexedMeta('price')}/>
							</div>
						</div>

						<div key="signature_label" className="row styled" style={{'borderTop': '1px solid gray'}}>
                            <div key="signature_tecnician_label" className="col-md-6">
                                Firma del tecnico
                            </div>
                            <div key="signature_customer_label" className="col-md-6">
                                Firma del cliente
                            </div>
                        </div>

						<div key="signature" className="row styled">
							<div key="signature_tecnician" className="col-md-3 signature-control">
                                <div className="signature_canvas">
                                    <SignatureComponent width={350} height={100} id="signature_tecnician" ref={signatureTecnician}></SignatureComponent>
                                </div>
							</div>
							<div key="signature_tecnician_clear" className="col-md-3">
                                <Button id="signature_tecnician_clear" variant="outlined" disabled={loading} onClick={(e) => clrBtnClick(e)}>
                                    Cancella firma
                                </Button>
                                <p style={{'marginTop': '10px', 'fontSize': '0.8em'}}>Prima di stampare,<br />salvare il documento per acquisire la firma</p>
                            </div>
							<div key="signature_customer" className="col-md-3 signature-control">
                                <div className="signature_canvas">
                                    <SignatureComponent width={350} height={100} id="signature_customer" ref={signatureCustomer}></SignatureComponent>
                                </div>
							</div>
							<div key="signature_customer_clear" className="col-md-3">
                                <Button id="signature_customer_clear" variant="outlined" disabled={loading} onClick={(e) => clrBtnClick(e)}>
                                    Cancella firma
                                </Button>
                                <p style={{'marginTop': '10px', 'fontSize': '0.8em'}}>Prima di stampare,<br />salvare il documento per acquisire la firma</p>
                            </div>
						</div>

						<div className="row styled">
                            {loading && (
                                <div className="col">
                                    <>
                                    {displayAlert === 0 && (
                                        <img className="pl-3" src={loadingGif} alt="Loading..."/>
                                    )}
                                    </>
                                    <>
                                    {displayAlert === -1 && (
                                        <Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
                                    )}
                                    </>
                                    <>
                                    {displayAlert === 1 && (
                                        <Alert severity="success">
                                            {onEdit ? (
                                                <>Dati modificati con successo!</>
                                            ) : (
                                                <>Dati salvati con successo!</>
                                            )}
                                        </Alert>
                                    )}
                                    </>
                                </div>
                            )}
                            {!loading && (
                                <>
                                    <div className="col-md-6">
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            disabled={isClosed}
                                        >
                                            {onEdit ? (
                                                <>Modifica dati</>
                                            ) : (
                                                <>Invia dati</>
                                            )}
                                        </Button>
                                        {reservedStamp === "NA" && (
                                            <Button
                                                variant="outlined"
                                                style={{
                                                    marginLeft: "30px",
                                                }}
                                                disabled={isClosed}
                                                onClick={reserveStamp}
                                            >
                                                Applica bollino provincia
                                            </Button>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <Button
                                            size="small"
                                            onClick={printPdf}
                                            style={{backgroundColor: theme.palette.secondary.background, fontSize: '0.8em'}}
                                            variant="contained"
                                            type="button"
                                        >
                                            Stampa rapporto (PDF)
                                        </Button>
                                    </div>
                                </>
                            )}
						</div>
					</form>
					<FormikValidation formik={formik} />
				</CardContent>
			</Card>
			<FormikValidation formik={formik} tech={true} />
			<Dialog
				open={openDialog}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{renderDialog()}
			</Dialog>
		</>
	)
}

export default RenderVerification;
