import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Button, Alert } from '@mui/material';
import { Link } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import loadingGif from '../../Assets/loading.gif';
import Api from '../../Services/Api';
import FastTextField from "../FastTextField";
import FastBoolField from "../FastBoolField";
import FormikValidation from "../FormikValidation";

function CategoryForm(props) {
	const [apiResponse, setApiResponse] = useState(null)
	const [onEdit] = useState(props.category ? true : false)
	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(0);

	const formik = useFormik({
		initialValues: {
			name: apiResponse?.name ?? "",
			enabled: apiResponse?.enabled ?? "1",
			_method: onEdit ? 'put' : 'post',
		},
		validationSchema: Yup.object({
			name:
				Yup.string()
				.min(2, 'Lunghezza minima 2 caratteri')
				.max(50, 'Lunghezza massima 50 caratteri')
				.required('Campo obbligatorio'),
		}),
		onSubmit: values => {
			setLoading(true);

			try {
				let currEndpoint = onEdit ? `category/${apiResponse.id}` : 'category';
				Api.post({endpoint: currEndpoint, body: values, contentType : 'application/json'})
					.then(response => {
						setDisplayAlert(1);
						//let timer1 = setTimeout(() => {setLoading(false);clearTimeout(timer1)}, 3000);
					})
					.catch(error => {
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						else {
							console.log(error);
							alert("Errore inatteso!");
						}
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}
		},
		enableReinitialize: true,
	});

	function confirmGoBack(checkChanged) {
		if (checkChanged && Object.keys(formik.touched).length > 0) {
			if (!window.confirm("Uscire senza salvare le modifiche?")) {
				return false;
			}
		}
		return true;
	}

	function gotoBack(checkChanged) {
		return (
			<Link to={`/cms/categories`} style={{margin: 'auto'}}
				onClick={e => !confirmGoBack(checkChanged) && e.preventDefault()}
			>
				<Button
					variant="outlined"
					sx={{ ml: 3 }}
				>
					Torna alla lista!
				</Button>
			</Link>
		);
	}

	useEffect(() => {
		try {
			if (onEdit) {
				Api.get({endpoint: `category/${props.category}`, contentType : 'application/json'})
					.then(response => {
						setApiResponse(response.data.category);
					})
					.catch(error => {
						console.log(error);
						setApiResponse(null);
						setDisplayAlert(-1);
					});
			}
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, [onEdit]);	// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<form
			autoComplete="off"
			noValidate
			onSubmit={formik.handleSubmit}
		>
			<Card>
				<CardHeader
					title="Categoria"
					subheader="Visualizza e modifica i dati della Categoria"
				/>
				<CardContent sx={{ pt: 0 }}>
						<div key="name_enabled" className="row styled">
							<div key="name" className="col-md-6">
								<FastTextField loading={loading} required formik={formik} label="Nome" field="name"/>
							</div>
							<div key="enabled" className="col-md-6">
								<FastBoolField loading={loading} formik={formik} label="Attiva" field="enabled"/>
							</div>														
						</div>
						<div className="row styled">
							<div className="col">
								{loading && (
										<>
											<>
											{displayAlert === 0 && (
												<img className="pl-3" src={loadingGif} alt="Loading..."/>
											)}
											</>
											<>
											{displayAlert === -1 && (
												<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
											)}
											</>
											<>
											{displayAlert === 1 && (
												<Alert severity="success">
													{onEdit ? (
														<>Dati modificati con successo!</>
													) : (
														<>Dati salvati con successo!</>
													)}
													{gotoBack()}
												</Alert>
											)}
											</>
										</>
								)}
								{!loading && (
									<>
										<Button
											variant="contained"
											type="submit"
										>
											{onEdit ? (
												<>Modifica dati</>
											) : (
												<>Invia dati</>
											)}
										</Button>
										{gotoBack(true)}
									</>
								)}
							</div>
						</div>
						<FormikValidation formik={formik} />
				</CardContent>
			</Card>
			<FormikValidation formik={formik} tech={true} />
		</form>
	);
}

export default CategoryForm;