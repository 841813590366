import {APP_NAME} from '../Config/constants.js';
import {Typography} from '@mui/material';

function Copyright (props) {
	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			{'Copyright © '}
			{APP_NAME}{' '}
			{new Date().getFullYear()}
		</Typography>
	);
}

export default Copyright;
