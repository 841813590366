import { useState, useEffect, useContext, useRef } from 'react';
import { Card, CardContent, CardHeader, InputAdornment, IconButton, Alert, AlertTitle, Button, MenuItem } from '@mui/material';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import { useTheme } from '@mui/material/styles';

import FastInfoBlock from "../FastInfoBlock";
import Api from '../../Services/Api';
import Functions from "../../Services/Functions";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import loadingGif from '../../Assets/loading.gif';

import FastTextField from "../FastTextField";
import FormikValidation from "../FormikValidation";
import { ApWoContext } from '../../Contexts/context';

import { payments as optionsPayment } from '../../Config/constants.js';

const InfoBlock = (props) => {
	let {label, content, colsize} = props;

	if (!colsize)
		colsize = 4;

	return (
		<div className={`col-md-${colsize}`}>
			<FastInfoBlock label={label} content={content} {...props} />
		</div>
	);
}

function RenderAppointment(props) {
	let {appointment, setInEdit, handleCancelledAppointment, handleClosedAppointment} = props;

	const [apiResponse, setApiResponse] = useState(null)
	const [address, setAddress] = useState(null)
	const [displayAlert, setDisplayAlert] = useState(0);
	const [loading, setLoading] = useState(false);
	const [suggestedPrice, setSuggestedPrice] = useState(null);
	const [suggestedPriceRepair, setSuggestedPriceRepair] = useState(null);
	const [suggestedPriceStamp, setSuggestedPriceStamp] = useState(null);
	const [forceCloseOnEmpty, setForceCloseOnEmpty] = useState(false);


	const requireClosing = useRef(false);
	const [isClosed, setIsClosed] = useState(false);

	// eslint-disable-next-line
	const { showContextOverlayContext, setShowContextOverlayContext } = useContext(ApWoContext);

	const theme = useTheme();

	const localeOptions = {
		  style: 'decimal',  // Other options: 'currency', 'percent', etc.
		  minimumFractionDigits: 2,
		  maximumFractionDigits: 2,
	};

	const handleCall = (event) => {
		if (apiResponse?.phone_number_1 && window.confirm("Chiamare il numero di telefono selezionato?")) {
			window.open("tel:" + apiResponse.phone_number_1);
		}
	}

	const handleSubmit = () => {
		setIsClosed(false);
		requireClosing.current = false;
		formik.handleSubmit();
	}

	const handleSubmitClose = () => {
		if (!forceCloseOnEmpty) {
			if (!window.confirm('Chiudere l\'appuntamento? Tutte le attività sono state completate?')) {
				return;
			}
		}
		else {
			if (!window.confirm('Stai chiudendo l\'appuntamento senza aver svolto alcuna attività\nContinuare?')) {
				return;
			}
			formik.setFieldValue("forceCloseOnEmpty", true);
		}

		requireClosing.current = true;		
		formik.handleSubmit();
	}

	useEffect(() => {
		try {
			Api.get({endpoint: `appointmentWork/${appointment}`, contentType : 'application/json'})
				.then(response => {
					setApiResponse(response.data.appointment);
					let composeAddress =
						response.data.appointment.address +
						(response.data.appointment.number ? `, ${response.data.appointment.number} ` : " ") +
						"- " +
						(response.data.appointment.zipCode ? `${response.data.appointment.zipCode} ` : "") +
						response.data.appointment.city + " (" + response.data.appointment.area + ")" +
						(response.data.appointment.floor ? ` [${response.data.appointment.floor}]` : "");
					setAddress(composeAddress);
					response.data.appointment.cancelled_at && handleCancelledAppointment(true);
					if (response.data.appointment.closed_appointment) {
						handleClosedAppointment(true);
						setIsClosed(true);
					}
				})
				.catch(error => {
					if (Functions.unauthorized(error)) {
						setShowContextOverlayContext(true);
					}
					console.log(error);
					setApiResponse(null);
					setDisplayAlert(-1);
				});
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps


	const handleCancelNow = () => {
		formik.setFieldValue("cancelled_at_d", moment().format('YYYY-MM-DD'));
		formik.setFieldValue("cancelled_at_t", moment().format('HH:mm'));
	};

	const handleSuggest = () => {
		try {
			setDisplayAlert(0);
			setLoading(true);

			Api.get({endpoint: `appointmentWork/getPrice/${appointment}`, contentType : 'application/json'})
				.then(response => {
					let price = response.data.price;
					if (price) {
						setSuggestedPrice(price);
						setSuggestedPriceRepair(response.data.priceRepair);
						setSuggestedPriceStamp(response.data.priceStamp);
					}
					else {
						setSuggestedPrice(null);
						setSuggestedPriceRepair(null);
						setSuggestedPriceStamp(null);
						alert("Riparazione e bollino non presenti");
					}
					setLoading(false);
				})
				.catch(error => {
					if (Functions.unauthorized(error)) {
						setShowContextOverlayContext(true);
					}
					console.log(error);
					setDisplayAlert(-1);
				});
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}

	const formik = useFormik({
		initialValues: {
			price: apiResponse?.price ?? "",
			payment_method: apiResponse?.payment_method ?? "",
			payment_hour: apiResponse?.payment_hour ? moment(apiResponse?.payment_hour, 'HH:mm:ss').format('HH:mm') : "",
			payment_notes: apiResponse?.payment_notes ?? "",
			closed_appointment: apiResponse?.closed_appointment ?? false,
			cancelled_at_d: apiResponse?.cancelled_at ? moment(apiResponse?.cancelled_at).format('YYYY-MM-DD') : "",
			cancelled_at_t: apiResponse?.cancelled_at ? moment(apiResponse?.cancelled_at).format('HH:mm') : "",
			cancelled_at: "",
			cancelled_notes: apiResponse?.cancelled_notes ?? "",
			cancelled_user_id: apiResponse?.cancelled_user_id ?? "",
			_method: 'put',
			_isWork: true,
		},
		validate : values => {
			setInEdit(true);

            let errors = {}

			const _requireClosing  = requireClosing.current;

            if((values.cancelled_at_d && !values.cancelled_at_t) || (!values.cancelled_at_d && values.cancelled_at_t)) {
                errors.cancelled_at_d = "Inserire entrambi o nessun campo";
                errors.cancelled_at_d = "Inserire entrambi o nessun campo";
            }
            if(values.cancelled_notes && !values.cancelled_at_d) {
                errors.cancelled_notes = "Per inserire le note, inserire prima la data di cancellazione";
            }
			if (_requireClosing) {
				if(!values.payment_method && !values.payment_hour && !values.payment_notes) {
					errors.payment_notes = "Se non si inseriscono metodo ed ora di pagamento, inserire la motivazione";
				}
				if(values.payment_method && !values.payment_hour) {
					errors.payment_hour = "Inserire ora di pagamento per il metodo selezionato";
				}
				if(!values.payment_method && values.payment_hour) {
					errors.payment_method = "Inserire metodo di pagamento per l'ora selezionata";
				}
			}

            return errors
        },
		validationSchema: Yup.object({
			price:
				Yup.number()
				.min(0, 'Valore minimo 0')
				.max(999999.99, 'Valore massimo 999999.99'),
			payment_method:
				Yup.string()
				.max(25, 'Lunghezza massima 25 caratteri'),
			payment_hour:
				Yup.string(),
			payment_notes:
				Yup.string()
				.max(255, 'Lunghezza massima 255 caratteri'),
			cancelled_at_d:
				Yup.date(),
			cancelled_at_t:
				Yup.string(),
			cancelled_notes:
				Yup.string()
				.max(1000, 'Lunghezza massima 1000 caratteri'),
		}),
		onSubmit: values => {
			setLoading(true);

			const _requireClosing  = requireClosing.current;

			//formik.setFieldValue(("cancelled_at", values.cancelled_at_d + " " + values.cancelled_at_t).trim());
			if (values.cancelled_at_d && values.cancelled_at_t) {
				values.cancelled_at = values.cancelled_at_d + " " + values.cancelled_at_t;
				handleCancelledAppointment(true);
			}
			else {
				 values.cancelled_at = "";
				 handleCancelledAppointment(false);
			}

			if (_requireClosing) {
				values.closed_appointment = true;
				handleClosedAppointment(true);
				setIsClosed(true);
			}
			else {
				handleClosedAppointment(false);
			}

			try {
				let currEndpoint = `appointment/${apiResponse.id}`;
				Api.post({endpoint: currEndpoint, body: values, contentType : 'application/json'})
					.then(response => {
						setDisplayAlert(1);
						let timer1 = setTimeout(() => {setLoading(false);clearTimeout(timer1)}, 3000);
					})
					.catch(error => {
						if (_requireClosing) {
							values.closed_appointment = false;
							handleClosedAppointment(false);
							setIsClosed(false);
						}

						if (error.response.status === 422) {
							if (error.response.data.error === 1000) {
								alert("Impossibile chiudere senza alcuna\nProva fumi / Manutenzione / Riparazione.\nPer proseguire comunque, eseguire nuovamente la chiusura!");
								setDisplayAlert(0);
								setLoading(false);
								setForceCloseOnEmpty(true);
								return;
							}
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						else {
							console.log(error);
							alert("Errore inatteso!");
						}
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}

			setInEdit(false);
		},
		enableReinitialize: true,
	});

	return (
		<>
			<Card>
				<CardHeader
					title="Appuntamento"
					subheader="Visualizza e modifica i dati dell'Appuntamento"
				/>
				<CardContent sx={{ pt: 0 }}>
					<div className="row">
						<InfoBlock label="Cliente" content={`${apiResponse?.surname ?? ""} ${apiResponse?.name ?? ""}`} />
						<InfoBlock label="Indirizzo" content={address || ""} />
						<InfoBlock
							label="Telefono"
							content={apiResponse?.phone_number_1 ?? ""}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<IconButton
														aria-label="call phone"
														onClick={handleCall}
														edge="end"
														sx={{
															paddingRight: '20px'
														}}
													>
														<PhoneForwardedIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
						/>
					</div>
					<div className="row">
						<InfoBlock label="Marca" content={apiResponse?.brand ?? ""} />
						<InfoBlock label="Modello" content={apiResponse?.model ?? ""} />
						<InfoBlock label="Codice impianto" content={apiResponse?.unique_code ?? ""} />
					</div>
					<div className="row">
						<InfoBlock label="Data/ora appuntamento" content={apiResponse?.appointment_at ? moment(apiResponse?.appointment_at).format('DD/MM/YYYY HH:mm') : ""} />
						<InfoBlock label="Contatto" content={apiResponse?.referent ?? ""} />
						<InfoBlock label="Tipo intervento" content={apiResponse?.appointment_type ?? ""} />
					</div>
					<div className="row">
						<InfoBlock label="Note di intervento" content={apiResponse?.notes ?? ""} colsize={12} multiline rows={3} />
					</div>
					<hr />
					<form
						autoComplete="off"
						noValidate
						//onSubmit={formik.handleSubmit}
					>
						<div key="price_labelPrice" className="row">
							<div key="price" className="col-md-3">
								<FastTextField loading={loading} formik={formik} label="Prezzo totale intervento" field="price" type="number" />
							</div>
							<div key="labelPrice" className="col-md-9 pt-3">
								{suggestedPrice &&
									<p style={{'paddingLeft': '10px'}}>
										<em>Importo parziale suggerito:&nbsp;
											<strong>{suggestedPrice.toLocaleString('it-IT', localeOptions)}€</strong> di cui
											{suggestedPriceRepair ? ` Riparazione: ${suggestedPriceRepair.toLocaleString('it-IT', localeOptions)}€` : ''}
											{suggestedPriceStamp ? ` Bollino: ${suggestedPriceStamp.toLocaleString('it-IT', localeOptions)}€ ` : ''}
										</em>
									</p>
								}
							</div>
						</div>
						<div key="r_labelPrice" className="row">
							<div key="labelPrice" className="col-md-12">
								<Button variant="outlined" size="small" onClick={handleSuggest} disabled={isClosed}>
									Suggerisci importo Riparazione e Bollino (se presenti)
								</Button>
								<span style={{'paddingLeft': '10px', 'fontWeight': 'bold'}}>Al prezzo suggerito va aggiunto il costo dell'eventuale intervento di Prova fumi / Manutenzione quando svolto</span>
							</div>
						</div>
						<div key="payment_method_payment_hour_payment_notes" className="row styled">
							<div key="payment_method" className="col-md-3">
								<FastTextField loading={loading} formik={formik} label="Metodo di pagamento" field="payment_method" select
									child = {optionsPayment.map((payment) => (
										<MenuItem key={payment} value={payment}>
											{payment}
										</MenuItem>
									))}
								/>
							</div>
							<div key="payment_hour" className="col-md-3">
								<FastTextField loading={loading} formik={formik} label="Ora pagamento" field="payment_hour" type="time" />
							</div>
							<div key="payment_notes" className="col-md-6">
								<FastTextField loading={loading} formik={formik} label="Note (mancato) pagamento" field="payment_notes" />
							</div>
						</div>
						<hr />
						<div key="cancelled_at_cancelled_notes" style={{'backgroundColor': '#f5f5f5', 'borderRadius': '4px'}} className="row styled">
							<div key="cancelled_at" className="col-md-6">
								<div key="cancelled_at_d_cancelled_at_t" className="row">
									<div key="cancelled_at_d" className="col-md-6">
										<FastTextField loading={loading} formik={formik} label="Data cancellazione" field="cancelled_at_d" type="date" disabled={false} />
									</div>
									<div key="cancelled_at_t" className="col-md-6">
										<FastTextField loading={loading} formik={formik} label="Ora cancellazione" field="cancelled_at_t" type="time" disabled={false} />
									</div>
								</div>
								<Button variant="outlined" size="small" onClick={handleCancelNow} disabled={isClosed}>
									Cancella adesso
								</Button>
							</div>
							<div key="cancelled_notes" className="col-md-6">
								<FastTextField loading={loading} formik={formik} label="Note cancellazione" field="cancelled_notes"
									multiline
									rows={3}
								/>
							</div>
						</div>
						<div className="row styled">
							<div className="col">
								{loading && (
										<>
											<>
											{displayAlert === 0 && (
												<img className="pl-3" src={loadingGif} alt="Loading..."/>
											)}
											</>
											<>
											{displayAlert === -1 && (
												<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
											)}
											</>
											<>
											{displayAlert === 1 && (
												<Alert severity="success">
													Dati modificati con successo!
												</Alert>
											)}
											</>
										</>
								)}
								{!loading && (
									<>
										<Button
											variant="contained"
											onClick={handleSubmit}
											disabled={isClosed}
										>
											Modifica dati
										</Button>

										<Button
											style={{backgroundColor: theme.palette.secondary.background, fontSize: '0.8em'}}
											className="mx-2"
											variant="contained"
											onClick={handleSubmitClose}
											disabled={isClosed}
										>
											{forceCloseOnEmpty ? 'Forza chiusura Appuntamento' : 'Chiudi Appuntamento'}
										</Button>
										{isClosed && (
											<Alert severity="warning" sx={{marginTop: '10px'}}>
												<AlertTitle>ATTENZIONE</AlertTitle>
												Questo appuntamento è stato impostato come "Chiuso". Non è possibile modificarlo
											</Alert>
										)}
									</>
								)}
							</div>
						</div>
					</form>
					<FormikValidation formik={formik} />
				</CardContent>
			</Card>
			<FormikValidation formik={formik} tech={true} />
		</>
	)
}

export default RenderAppointment;
