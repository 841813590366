import { useState, useEffect } from 'react';
import { Autocomplete, TextField, Typography, Popover, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { myLog } from "../Services/Functions";

function FastAutocomplete (props) {
	const [anchorEl, setAnchorEl] = useState(null);

    let { meta, field, formik, options, onChange, loading, label, required } = props;

	const handlePopoverSwitch = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

	const handlePopoverClose = (event) => {
		setAnchorEl(null);
	};
	const openPopover = Boolean(anchorEl);

	const handleOnChange = (event, newValue) => {
		/*
			Not fired on filtering (typing in textbox) until selecting an item
			Parent function "onChange" is fired everytime with SynteticBaseEvent as param (no item passed)
			Check in parent and exit "onChange" if
				typeof val == "object"
		*/
        myLog(newValue, "FastAutocomplete.handleOnChange.newValue");

        formik.setFieldValue(meta ? meta.name : field, newValue ? newValue.id : null);
		onChange && onChange(newValue ? newValue : null);
	}

    useEffect(() => {
        myLog(formik.values, "FastAutocomplete.useEffect.formik_values");
    }, [formik.values]);	// eslint-disable-line react-hooks/exhaustive-deps

    const showHelp = meta && meta.label.length > 30;

    const renderAutocomplete = (name, label, required) => (
        <Autocomplete
            id={name}
            key={name}
            value={options.find(option => option.id === formik.values[name]) || null}
            onChange={handleOnChange}
            options={options}
            getOptionLabel={(option) => option.label || ""}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {`${option.label}`}
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    helperText={formik.touched[name] && formik.errors[name] ? formik.errors[name] : null}
                    error={Boolean(formik.touched[name] && formik.errors[name])}
                    label={label}
                    name={name}
                    required={required}
                    disabled={Boolean(loading)}
                    className="styled"
                    sx={{
                        width: showHelp ? '85%' : '100%',
                    }}
                    {...formik.getFieldProps(name ?? "none")}
                    {...props}
                />
            )}
        />
    );

    const name = meta ? meta.name : field;
    const autocompleteLabel = meta ? meta.label : label;
    const isRequired = meta ? meta.nullable === "NO" : required;

    return (
        <>
            {renderAutocomplete(name, autocompleteLabel, isRequired)}

            {meta && showHelp && (
                <IconButton
                    aria-label="switch popover"
                    onClick={handlePopoverSwitch}
                    edge="end"
                >
                    <InfoOutlinedIcon />
                </IconButton>
            )}

            {meta && (
                <Popover
                    id="mouse-over-popover"
                    sx={{ pointerEvents: 'none' }}
                    open={openPopover}
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography sx={{ p: 1 }}>{meta.label}</Typography>
                </Popover>
            )}
        </>
    )
}

export default FastAutocomplete;


