import { MenuItem } from '@mui/material';
import FastTextField from "./FastTextField";
function FastBoolField (props) {

	let nullable = props.nullable || (props.meta && props.meta.nullable);
	const booleans = [
		{
			expr: 'No',
			val: '0',
		},
		{
			expr: 'Si',
			val: '1',
		},	
	];
	
	const nullableBooleans = [
		{
			expr: '---',
			val: '',
		},	
		{
			expr: 'No',
			val: '0',
		},
		{
			expr: 'Si',
			val: '1',
		},	
	];
	
	return (
		<FastTextField {...props} select
			child = {nullable ? 
				(
					nullableBooleans.map((boolx) => (
						<MenuItem key={boolx.val} value={boolx.val}>
							{boolx.expr}
						</MenuItem>
					))
				) : (
					booleans.map((boolx) => (
						<MenuItem key={boolx.val} value={boolx.val}>
							{boolx.expr}
						</MenuItem>
					))
				)
			}
		/>							
	);
}

export default FastBoolField;


