import React, { useState, useEffect } from 'react';
import Functions from "../Services/Functions";

const weak = {
	height: '3px',
	margin: '0px 2px',
	border: '1px solid #FF0000',
	backgroundColor: '#FF0000',
};
const medium = {
	height: '3px',
	margin: '0px 2px',
	border: '1px solid #FFA500',
	backgroundColor: '#FFA500',
};
const strong = {
	height: '3px',
	margin: '0px 2px',
	border: '1px solid #00FF00',
	backgroundColor: '#00FF00',
};

function PasswordStrength (props) {
	const [passwordStrength, setPasswordStrength] = useState(null);
	
	useEffect(() => {
		if (!props.password || props.password === "") {
			setPasswordStrength(null);
		}
		else {
			let ps = Functions.getPasswordStrength(props.password);
			if (ps >= 0 && ps < 50)
				setPasswordStrength('weak');
			if (ps >= 50 && ps < 100)
				setPasswordStrength('medium');
			if (ps === 100)
				setPasswordStrength('strong');
		}
	}, [props.password]);	// eslint-disable-line react-hooks/exhaustive-deps
			
	return (
		<div className="container my-2">
			{passwordStrength && (
				<div className="row">
					<div className="col" style={weak}>
						&nbsp;
					</div>
					{(passwordStrength === 'strong' || passwordStrength === 'medium') && (
						<div className="col" style={medium}>
							&nbsp;
						</div>
					)}
					{passwordStrength === 'strong' && (
						<div className="col" style={strong}>
							&nbsp;					
						</div>
					)}
				</div>
			)}			
		</div>
	);
}

export default PasswordStrength;


