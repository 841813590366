import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet, Link, useLocation } from 'react-router-dom';
import moment from 'moment';

import { useAuth } from '../Auth/ProvideAuth'
import { isMobile } from 'react-device-detect';

/* MUI ELEMENTS */
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box, Toolbar, IconButton, Typography, Badge, Divider, List, Container, MenuItem, MenuList} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { Paper, Tooltip, Popover, BottomNavigation } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

/* SERVICE */
import Api from "../Services/Api";
import {APP_NAME, ADMIN_ROLE, VERSION} from '../Config/constants.js';
import Copyright from "../Components/Copyright";
import AutoAvatar from "../Components/AutoAvatar";
import { basePath, mainListItems, secondaryListItems, secondaryHeader } from '../Config/mainMenu';
import packageJson from "../../package.json";

import '../global.css';
import logoSmall from '../Assets/logoSmall.png'

//Inspired by https://mui.com/material-ui/getting-started/templates/ "Dashboard"
function CmsLayout() {
	let auth = useAuth();
	let navigate = useNavigate();

    const buildDate = packageJson.buildDate;
    
	const location = useLocation();
	const pathname = location.pathname;

	const [isMe, setIsMe] = useState(null);

	const [open, setOpen] = useState(!isMobile);
	const [showNotifications] = useState(false);
	const [showFooter] = useState(false);
	const [notificationsNumber] = useState(1);

	const [anchorElUser, setAnchorElUser] = useState(null);
	const handleClickUser = (event) => {
		setAnchorElUser(event.currentTarget);
	};
	const handleCloseUser = () => {
		setAnchorElUser(null);
	};
	const openUserPopover = Boolean(anchorElUser);
	const idUserPopover = openUserPopover ? 'simple-popover' : undefined;

	const toggleDrawer = () => {
		setOpen(!open);
	};

	const drawerWidth = 240;

	const AppBar = styled(MuiAppBar, {
			shouldForwardProp: (prop) => prop !== 'open',
		})(({ theme, open }) => ({
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...(open && {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}),
	}));

	const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
		({ theme, open }) => ({
			'& .MuiDrawer-paper': {
				position: 'relative',
				whiteSpace: 'nowrap',
				width: drawerWidth,
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.enteringScreen,
				}),
				boxSizing: 'border-box',
				...(!open && {
					overflowX: 'hidden',
					transition: theme.transitions.create('width', {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					width: theme.spacing(7),
					[theme.breakpoints.up('sm')]: {
						width: theme.spacing(9),
					},
				}),
			},
		}),
	);

	const getMe = () => {
		try {
			Api.get({endpoint: `me`, contentType : 'application/json'})
			.then(response => {
				setIsMe(response.data);
			})
			.catch(error => {
				navigate("/login");
			});
		} catch (error) {
			console.log(error);
			navigate("/login");
		}	
	}

	useEffect(() => {
		getMe();
	}, [pathname]);	// eslint-disable-line react-hooks/exhaustive-deps

	function handleLogout(e) {
		if (!window.confirm("Confermare il logout?"))
			return;
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		auth.signout();
		navigate("/");
	}

	//BottomNavigationAction
	const [value, setValue] = useState('');
	const handleChange = (e, newValue) => {
		setValue(newValue);
	};

	function checkRoles(value) {
		if(auth.lonyUser.roles[0].name === ADMIN_ROLE || !value.roles || (value.roles && value.roles.includes(auth.lonyUser.roles[0].name))) {
			return true;
		}
		else {
			return false;
		}
	}

/********** CUSTOMIZE **********/
	//const defaultTheme = createTheme(); > Alternatively use customize theme
	const customTheme = createTheme({
		palette: {
			primary: {
				main: '#11cb5f',	// This is green.A700 as hex.
				readOnly: '#446af2',
				darkMain: '#3fb06e',
			},
			secondary: {
				main: '#ffad69',
				background: '#ffad69',
			}
		},
	});

	const linkStyle = {
		textDecoration: "none",
	};
/********** /CUSTOMIZE **********/

	return (
		<ThemeProvider theme={customTheme}>	{/* or defaultTheme */}
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				<AppBar position="absolute" open={open} color="primary">
					<Toolbar
						sx={{
							pr: '24px', // keep right padding when drawer closed
						}}
					>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={toggleDrawer}
							sx={{
								marginRight: '36px',
								...(open && { display: 'none' }),
							}}
						>
							<MenuIcon />
						</IconButton>
						<Typography
							component="h1"
							variant="h6"
							color="inherit"
							noWrap
							sx={{ flexGrow: 1 }}
						>
							{APP_NAME}
						</Typography>
						{showNotifications && (
							<IconButton
								color="inherit"
								sx={{ marginRight: 3 }}
							>
								<Badge badgeContent={notificationsNumber} color="secondary">
									<NotificationsIcon />
								</Badge>
							</IconButton>
						)}

						<div>
							<Tooltip title="Open settings">
								<IconButton onClick={handleClickUser} sx={{ p: 0 }} >
									<AutoAvatar name={auth.lonyUser.name} />
								</IconButton>
							</Tooltip>
							{/* https://mui.com/material-ui/react-popover/ */}
							<Popover
								id={idUserPopover}
								open={openUserPopover}
								transformOrigin={{
									vertical: 'center',
									horizontal: 'left',
								}}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								onClose={handleCloseUser}
								//anchorEl={anchorElUser}
							>
								<Box
									sx={{
										py: 1.5,
										px: 2
									}}
								>
									<Typography variant="overline">
										Account
									</Typography>
									<Typography
										color="text.secondary"
										variant="body2"
									>
										Ciao {auth.lonyUser.name}
									</Typography>
								</Box>
								<Divider />
								<MenuList
									disablePadding
									dense
									sx={{
										p: '8px',
										'& > *': {
											borderRadius: 1
										}
									}}
								>									
									<MenuItem
										onClick={handleCloseUser}
										component={Link}
										to={`${basePath}/account`}
									>
										Il tuo account
									</MenuItem>
									<MenuItem onClick={handleLogout}>
										Logout
									</MenuItem>							
								</MenuList>
							</Popover>
						</div>
					</Toolbar>
				</AppBar>
				<Drawer variant="permanent" open={open}>
					<Toolbar
						sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						px: [1],
						}}
					>
						<Link to={`/cms`} style={{margin: 'auto'}}>
							<img src={logoSmall} alt={APP_NAME}/>
						</Link>
						<IconButton onClick={toggleDrawer}>
							<ChevronLeftIcon />
						</IconButton>
					</Toolbar>
					<Divider style={{'border': '1px solid #000000'}}/>
					<List component="nav">
						{mainListItems.filter(checkRoles).map((item) => {
							const active = item.path ? (pathname === item.path) : false;
							const customStyle = {
								pointerEvents: active ? "none" : "default",
								cursor: active ? "crosshair" : "default",
								color: customTheme.palette.primary.darkMain,
							};
							let finalStyle = {
								...linkStyle,
								...customStyle,
							};

							return (
								<React.Fragment
									key={`${item.path}_rf`}
								>
									<Link
										to={item.path}
										style={finalStyle}
									>
										<ListItemButton
											disabled = {active}
										>
											<ListItemIcon>
												{item.icon}
											</ListItemIcon>
											<ListItemText primary={item.title} />
										</ListItemButton>
									</Link>
								</React.Fragment>
							);
						})}
						<Divider sx={{ my: 1 }} />
						<ListSubheader component="div" inset>
							{secondaryHeader}
						</ListSubheader>
						{secondaryListItems.filter(checkRoles).map((item) => {
							const active = item.path ? (pathname === item.path) : false;
							const customStyle = {
								pointerEvents: active ? "none" : "default",
								cursor: active ? "crosshair" : "default",
								color: customTheme.palette.primary.darkMain,
							};
							let finalStyle = {
								...linkStyle,
								...customStyle,
							};

							return (
								<React.Fragment
									key={`${item.path}_rf`}
								>
									<Link
										to={item.path}
										style={finalStyle}
									>
										<ListItemButton
											disabled = {active}
										>
											<ListItemIcon>
												{item.icon}
											</ListItemIcon>
											<ListItemText primary={item.title} />
										</ListItemButton>
									</Link>
								</React.Fragment>
							);
						})}
					</List>                    
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="#f0f0f0" // Grigio chiaro
                    >
                        <Typography className="p-2" style={{'textAlign': 'center', 'fontSize': '0.8em'}}>
                            Versione {VERSION}<br />
                           <span style={{'color': '#AAAAAA'}}>{moment(buildDate).format("DD-MM-YY HH:mm")}</span>
                        </Typography>
                    </Box>
                    
				</Drawer>
				<Box
					component="main"
					sx={{
						backgroundColor: (theme) =>
						theme.palette.mode === 'light'
							? theme.palette.grey[100]
							: theme.palette.grey[900],
						flexGrow: 1,
						height: '100vh',
						overflow: 'auto',
						paddingBottom: '60px'
					}}
				>
					<Toolbar />
					<Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
						{isMe && (
							<Outlet context={isMe} />
						)}
					</Container>
				</Box>
				{showFooter && (
					<Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
						<BottomNavigation
							value={value}
							onChange={handleChange}
							showLabels	//https://mui.com/material-ui/api/bottom-navigation/ | https://mui.com/material-ui/react-bottom-navigation/
						>
						</BottomNavigation>
						<Copyright sx={{ mt: 1 }} />
					</Paper>
				)}
			</Box>
		</ThemeProvider>
	);
}

export default CmsLayout;
