import { Dialog, DialogActions, DialogContent,DialogContentText, DialogTitle, Button, Box } from '@mui/material';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

import { useState } from 'react';

function Report() {
	const [displayDialog, setDisplayDialog] = useState(0);
	
	const handleClose = () => {
		setDisplayDialog(0);
	};
	
	return (
		<>
			<h5>Reportistica</h5>
			
			<Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
				<nav aria-label="main mailbox folders">
					<List>
						<ListItem disablePadding>
							<ListItemButton>
								<ListItemIcon>
									<EuroSymbolIcon />
								</ListItemIcon>
								<ListItemText primary="Pagamenti giornalieri ricevuti" />
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton>
								<ListItemIcon>
									<ReceiptIcon />
								</ListItemIcon>
								<ListItemText primary="Clienti da fatturare" />
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton>
								<ListItemIcon>
									<ProductionQuantityLimitsIcon />
								</ListItemIcon>
								<ListItemText primary="Clienti senza pagamento" />
							</ListItemButton>
						</ListItem>						
					</List>
				</nav>
			</Box>
	
			<Dialog
				open={displayDialog !== 0}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{displayDialog === 1 && "Operazione completata"}
					{displayDialog === -1 && "Errore"}
				</DialogTitle>
				<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{displayDialog === 1 && "Operazione completata con successo!"}
							{displayDialog === -1 && "Si è verificato un errore inatteso"}
						</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Chiudi</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default Report;
