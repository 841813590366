import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid, itIT, GridToolbar, useGridApiRef } from '@mui/x-data-grid';		//https://mui.com/x/react-data-grid/ [MIT version]
import { Box, Alert, Button, Tooltip, TextField, MenuItem } from '@mui/material';
import {OPERATOR_ROLE} from '../../Config/constants.js';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import { useAuth } from '../../Auth/ProvideAuth'
import Api from '../../Services/Api';
import Functions from "../../Services/Functions";
import {PAGE_SIZE, pageSizeOptions} from '../../Config/constants.js';

function SpareList(props) {
	const { hook, lastUpdate } = props;
	const apiRef = useGridApiRef();	//https://mui.com/x/api/data-grid/
	let auth = useAuth();

	const ALL = "[Nessun filtro]";
	const ALL_VALUE = "**ALL**";

	const [rows, setRows] = useState([]);
	const [currentCategory, setCurrentCategory] = useState(ALL_VALUE);
	const [displayAlert, setDisplayAlert] = useState(0);
	const [listCategoriesResponse, setListCategoriesResponse] = useState(null)

	const columns = [
		{
			field: 'category',
			headerName: 'Categoria',
			flex: 2,
		},
		{
			field: 'code',
			headerName: 'Codice (no prefix)',
			flex: 2,
		},
		{
			field: 'short_description',
			headerName: 'Descrizione breve',
			flex: 2,
		},
		{
			field: 'quantity',
			headerName: 'Quantità disponibile(magazz)',
			flex: 1,
			type:'number',
			align:'right',
			valueGetter: (params) => {
				return Functions.toDecimal(params.row.quantity);
			},
		},
		{
			field: 'id',
			headerName: 'Azioni',
			flex: 1,
			renderCell: (params) => {
				function onPurchase(e) {
					e.stopPropagation(); // don't select this row after clicking
					hook("purchase", {
						'id': params.value,
						'description': apiRef.current.getCellValue(params.value, 'short_description'),
					});
				};
				function onTakeRelease(e) {
					e.stopPropagation(); // don't select this row after clicking				
					hook("takeRelease", {
						'id': params.value,
						'description': apiRef.current.getCellValue(params.value, 'short_description'),
						'currentQuantity': apiRef.current.getCellValue(params.value, 'quantity'),
					});					
				};
				function onEdit(e) {
					e.stopPropagation(); // don't select this row after clicking
					hook("edit", params.value);
				};
				function onDelete(e) {
					e.stopPropagation(); // don't select this row after clicking
					if (window.confirm("Confermare l'eliminazione DEFINITIVA del Ricambio?")) {
						if (window.confirm("Eliminare il Ricambio?")) {
							hook("delete", params.value);
						}
					}
				};
				return <>
					{auth.lonyUser.roles[0].name !== OPERATOR_ROLE ?
						<Tooltip title="Acquista Ricambio">
							<Button onClick={onPurchase}>
								<ShoppingCartIcon />
							</Button>
						</Tooltip>
					:
						<Tooltip title="Carica/Scarica Ricambio">
							<Button onClick={onTakeRelease}>
								<LocalShippingIcon />
							</Button>
						</Tooltip>
					}
					<Tooltip title="Modifica Ricambio">
						<Button onClick={onEdit}>
							<EditIcon />
						</Button>
					</Tooltip>
					<Tooltip title="Elimina Ricambio">
						<Button onClick={onDelete}>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</>
			}
		},
	]

	function newElement() {
		hook("create");
	}

	function arrayCategoriesResponse() {
		if (!listCategoriesResponse) {
			return (
				<MenuItem key={ALL_VALUE} value={ALL_VALUE}>
					Caricamento in corso...
				</MenuItem>
			);
		}
		else {
			return (
				listCategoriesResponse.map((category) => (
					<MenuItem key={category.category} value={category.category === ALL ? ALL_VALUE : category.category.trim()}>
						{category.category.trim()}
					</MenuItem>
				))
			);
		}
	}

	function extraFilter(selectedValue) {
		setCurrentCategory(selectedValue);
	}

	useEffect(() => {
		try {
			Api.get({endpoint: `spare/lists/categories`, contentType : 'application/json'})
				.then(response => {
					let cat = response.data.categories;
					cat = [{"category": ALL}, ...cat];
					setListCategoriesResponse(cat);
				})
				.catch(error => {
					console.log(error);
					setListCategoriesResponse(null);
					setDisplayAlert(-1);
				});
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, []);

	useEffect(() => {
		try {
			Api.get({
				endpoint: `spare`,
				contentType : 'application/json',
				body: {
					"category": currentCategory,
				}
			})
			.then(response => {
				setRows(response.data.spares);
			})
			.catch(error => {
				console.log(JSON.stringify(error));
				setDisplayAlert(-1);
			});
		} catch (error) {
			console.log(JSON.stringify(error));
			setDisplayAlert(-1);
		}
	}, [lastUpdate, currentCategory]);	// eslint-disable-line react-hooks/exhaustive-deps

	const theme = createTheme({
			palette: {
				primary: { main: '#11cb5f' },
			},
		},
		itIT,
	);
	return (
		<>
			<h5>Ricambi</h5>
			<Box sx={{ width: '100%', minWidth: '800px' }}>
				<ThemeProvider theme={theme}>
					<TextField
						fullWidth
						label="Seleziona categoria"
						name="category"
						className="styled"
						select
						defaultValue = {ALL_VALUE}
						InputProps={{
							style: {color: theme.palette.primary.darkMain}
						}}
						onChange={(e) => {
							extraFilter(e.target.value);
						}}
					>
						{arrayCategoriesResponse()}
					</TextField>
					<DataGrid
						//onRowDoubleClick={hook}
						sx={{ minHeight: '300px' }}
						rows={rows}
						columns={columns}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: PAGE_SIZE,
								},
							},
						}}
						apiRef={apiRef}
						slots={{
							toolbar: GridToolbar,
						}}
						slotProps={{
							columnsPanel: {
								disableHideAllButton: true,
							}
						}}
						pageSizeOptions={pageSizeOptions}
					/>
					<div className="mt-2">
						<Button
							variant="contained"
							onClick={newElement}
						>
							Nuovo Ricambio
						</Button>
					</div>
				</ThemeProvider>
				{displayAlert === -1 && (
					<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
				)}
			</Box>
		</>
	);
}

export default SpareList;