import * as yup from "yup";

/*
	Based on
		https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/reduce
		https://github.com/jquense/yup
		https://codesandbox.io/s/clever-snyder-1u410?fontsize=14&file=/src/formData.js
	This function create dinamyc YUP validator based on JSON containing list of this object
	{
		id: "total",
		validationType: "number",
		validations: [
			{
				type: "required",
				params: ["this field is required"]
			},
			{
				type: "min",
				params: [1, "there should be atleast 1 family member"]
			},
			{
				type: "max",
				params: [5, "max family members can be 5"]
			}
		]
	}

	called as:
		const yepSchema = myJsonArray.reduce(createYupSchema, {});
		const validateSchema = yup.object().shape(yepSchema);
*/
function createYupSchema(schema, config) {
	const { id, validationType, validations = [] } = config;
	if (!yup[validationType]) {
		return schema;
	}
	let validator = yup[validationType]();

	validations.forEach(validation => {
		const { params, type } = validation;
		if (!validator[type]) {
			return;
		}
		if (!Array.isArray(params)) {
			validator = validator[type](params);
		}
		else {
			validator = validator[type](params[0], params[1]);
		}
	});
	schema[id] = validator;
	return schema;
}

function mapTypes(dbType, dbDetails) {
	let mapType, maxSize, maxValue;
	switch (dbType) {
		case 'bigint':
			mapType = 'number';
			maxValue = 2147483647;
			break;
		case 'decimal':
			dbDetails = dbDetails.substring(dbDetails.indexOf('(') + 1, 100);
			dbDetails = dbDetails.slice(0, -1);
			dbDetails = dbDetails.split(',');
			mapType = 'number';
			maxValue = Math.pow(10, dbDetails[0]-dbDetails[1]) - Math.pow(10,-dbDetails[1]);	//dbDetails = precision - scale
			break;
		case 'tinyint':
			if (dbDetails.indexOf('(1)') !== -1) {	
				mapType = 'boolean';
				maxValue = 1;
			} else {
				mapType = 'number';
				maxValue = 127;
			}
			break;
		case 'smallint':
			mapType = 'number';
			maxValue = 32767;
			break;
		case 'timestamp':
		case 'datetime':
			mapType = 'date';
			break;
		case 'date':
			mapType = 'date';
			break;
		case 'varchar':
			mapType = 'string';
			break;
		case 'text':
			mapType = 'string';
			break;
		case 'enum':
			mapType = 'string';
			break;
		default:
			mapType = 'string';
			maxSize = 255;
	};

	return {
		'mapType': mapType,
		'maxSize': maxSize,
		'maxValue': maxValue,
	};
}

function validationSchema(metaResponse, exactLengths) {
	let formMeta = [];
	if (metaResponse) {
		metaResponse.forEach((meta) => {
			let mapping = YupHelper.mapTypes(meta.data_type, meta.column_type);
			let newElement = {
				id: meta.name,
				validationType: mapping.mapType,
				validations: [],
			}
			if (meta.nullable === "NO") {
				newElement.validations.push({
					type: 'required',
					params: 'Campo obbligatorio!',
				});
			}

			if (exactLengths[meta.name]) {
				newElement.validations.push({
					type: 'length',
					params: [exactLengths[meta.name], `Lunghezza richiesta ${exactLengths[meta.name]} caratteri!`],
				});
			}
			else {
				if (meta.maxlen) {
					newElement.validations.push({
						type: 'max',
						params: [meta.maxlen, `Lunghezza massima ${meta.maxlen} caratteri!`],
					});
				}
				else {
					//string
					if (mapping.maxSize) {
						newElement.validations.push({
							type: 'max',
							params: [mapping.maxSize, `Lunghezza massima ${mapping.maxSize} caratteri!`],
						});
					}
					//number
					if (mapping.maxValue) {
						newElement.validations.push({
							type: 'max',
							params: [mapping.maxValue, `Valore massimo ${mapping.maxValue}!`],
						});
					}
				}
			}

			formMeta.push(newElement);
		})
		const yepSchema = formMeta.reduce(YupHelper.createYupSchema, {});	
		return yup.object().shape(yepSchema);
	}
	else {
		return formMeta;
	}
}
	
const YupHelper = {
    createYupSchema,
    mapTypes,
    validationSchema,
}

export default YupHelper
