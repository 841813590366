import { useParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { Box, Tab, Modal, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab/';

import RenderAppointment from "../Components/AppointmentWork/RenderAppointment";
import RenderVerification from "../Components/AppointmentWork/RenderVerification";
import RenderRepair from "../Components/AppointmentWork/RenderRepair";

import { useAuth } from '../Auth/ProvideAuth'
import { ApWoContext } from '../Contexts/context';
import { useTheme } from '@mui/material/styles';

function AppointmentWork() {
	const [tab, setTab] = useState("AP");	//Appointment, Verification, XXXX>newMaint
	const [isCancelled, setIsCancelled] = useState(false);
	const [isClosed, setIsClosed] = useState(false);
	const [showContextOverlayContext, setShowContextOverlayContext] = useState(false);	
	
	const [childInEdit, setChildInEdit] = useState(false);
	
	let auth = useAuth();
	let {appointment} = useParams();
	let navigate = useNavigate();
	
	const theme = useTheme();
	
	const handleChange = (event, newValue) => {
		if (childInEdit && !window.confirm("Uscire senza salvare i dati sulla scheda attuale?")) {
			return;
		}		
		setChildInEdit(false);
		setTab(newValue);
	};
	
	function handleCancelledAppointment(isCancelled) {
		setIsCancelled(isCancelled);		
	};
	
	function handleClosedAppointment(isClosed) {
		setIsClosed(isClosed);		
	};
	
	const handleClose = () => {
		navigate("/cms");		
	}
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid',
		borderColor: theme.palette.primary.main,
		boxShadow: 24,
		p: 4,
	};
	 
	return (
		<>
			<Modal
				open={showContextOverlayContext}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						Utente non autorizzato
					</Typography>
					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						Sulla base delle restrizioni del sistema, non sei autorizzato a visualizzare questa pagina.
					</Typography>
					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						Cliccando fuori da questo messaggio, verrai reindirizzato sulla Dashboard!
					</Typography>
				</Box>
			</Modal>
			<TabContext value={tab}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleChange} aria-label="Customer">
						<Tab label="Appuntamento" value="AP" />
						<Tab label="Prova fumi-Manutenzione" value="VE" />
						<Tab label="Riparazione" value="AS" />
					</TabList>
				</Box>
				<ApWoContext.Provider value={{ showContextOverlayContext, setShowContextOverlayContext }}>
					<TabPanel value="AP"><RenderAppointment user={auth.lonyUser} appointment={appointment} setInEdit={setChildInEdit} handleCancelledAppointment={handleCancelledAppointment} handleClosedAppointment={handleClosedAppointment} /></TabPanel>
					<TabPanel value="VE"><RenderVerification user={auth.lonyUser} appointment={appointment} setInEdit={setChildInEdit} isCancelled={isCancelled} isClosed={isClosed} /></TabPanel>
					<TabPanel value="AS"><RenderRepair user={auth.lonyUser} appointment={appointment} setInEdit={setChildInEdit} isCancelled={isCancelled} isClosed={isClosed} /></TabPanel>
				</ApWoContext.Provider>
			</TabContext>
		</>
	);
}

export default AppointmentWork;
