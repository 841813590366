import { useState, useRef } from "react";
import {CssBaseline, Paper, Typography, Grid} from '@mui/material';
import {Avatar, Box, Button, TextField, Link as MuiLink, Alert, InputAdornment, IconButton } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import SimpleReactValidator from 'simple-react-validator';
import { useAuth, useForceUpdate } from '../Auth/ProvideAuth'
import { useNavigate } from "react-router-dom";
import Copyright from "../Components/Copyright";

import {MAIN_SITE} from '../Config/constants.js';
import background from '../Assets/background.jpg';
import loadingGif from '../Assets/loading.gif'

const theme = createTheme();

export default function Login() {
	let navigate = useNavigate();
	let auth = useAuth();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(null);
	const [errorLogin, setErrorLogin] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	}

	const simpleValidator = useRef(
		new SimpleReactValidator({
			element: message => <span>{message}</span>,
			messages: {
				required: 'Campo :attribute obbligatorio',
				email: 'Inserire un indirizzo email corretto',
				min: 'Lunghezza minima :min caratteri',
				max: 'Lunghezza massima :max caratteri',
			},
		})
	);

	const [forceUpdate] = useForceUpdate();
		
	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!simpleValidator.current.allValid()) {
			simpleValidator.current.showMessages();
			forceUpdate();
			return;
		}

		setLoading(true);

		try {
            let response = await auth.signin({email, password});
			setLoading(false);
			if (response.status === 401) {
				setErrorLogin("Nome utente o password non validi");
				setDisplayAlert(-1);
			    return;
			}
			if (response.status === 422) {
				if (response.error.localeCompare('Email not verified') === 0) {
					setErrorLogin("Indirizzo email non verificato - Utilizza il pulsante in basso se non hai ricevuto il link");
					setDisplayAlert(-2);
				}
				if (response.error.localeCompare('User disabled') === 0) {
					setErrorLogin("Account disattivato - Contattare l'amministratore");
					setDisplayAlert(-1);

				}
			    return;
			}
			if (response.status !== 200) {
				setErrorLogin("Si è verificato un errore imprevisto. Riprovare più tardi");
				setDisplayAlert(-1);
			    return;
            }

			switch (response.role) {
				case 'administrator':
				case 'backoffice':
				case 'operator':
					navigate("/cms");
					break;
				default:
					console.log('Are you crazy?');
			}
		} catch (error) {
			console.log(error);
			setErrorLogin("Si è verificato un errore imprevisto. Riprovare più tardi");
			setLoading(false);
			return;
		}
	};

	return (
	<ThemeProvider theme={theme}>
		<Grid container component="main" sx={{ height: '100vh' }}>
			<CssBaseline />
			<Grid
				item
				xs={false}
				sm={4}
				md={7}
				sx={{
					backgroundImage: "url(" + background + ")",
					backgroundRepeat: 'no-repeat',
					backgroundColor: (t) =>
					t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			/>
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
			<Box
				sx={{
				my: 8,
				mx: 4,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Accedi
				</Typography>
				<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
					<TextField
						margin="normal"
						type="email"
						name="email"
						id="email"
						label="Email"
						autoComplete="email"
						required
						fullWidth
						autoFocus
						disabled={loading}
						onChange={(e) => setEmail(e.target.value)}
						error={simpleValidator.current.message('email', email, 'required|email') ? true : false}
						helperText={simpleValidator.current.message('email', email, 'required|email')}
					/>
					<TextField
						margin="normal"
						type={showPassword?"text":"password"}
						name="password"
						id="password"
						label="Password"
						autoComplete="current-password"
						required
						fullWidth
						disabled={loading}
						onChange={(e) => setPassword(e.target.value)}
						error={simpleValidator.current.message('password', password, 'required') ? true : false}
						helperText={simpleValidator.current.message('password', password, 'required')}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleShowPassword}
										edge="end"
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					{loading?
						<img className="pl-3" src={loadingGif} alt="Loading..."/>
					:
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							Accedi
						</Button>
					}
					{displayAlert === 1 && (
						<Alert severity="info">Clicca sul link che ti abbiamo inviato via email per attivare l'account</Alert>
					)}
					{(displayAlert === -1 || displayAlert === -2) && (
						<Alert severity="error">{errorLogin}</Alert>
					)}

					<Grid container>
						<Grid item xs>
							<MuiLink href={`${MAIN_SITE}/password/reset`} variant="body2">
								Password dimenticata?
							</MuiLink>
						</Grid>
					</Grid>
					<Copyright sx={{ mt: 5 }} />
				</Box>
			</Box>
			</Grid>
		</Grid>
		</ThemeProvider>
	);
}
