import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Button, Alert, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import loadingGif from '../../Assets/loading.gif';
import Api from '../../Services/Api';
import FastTextField from "../FastTextField";
import FastBoolField from "../FastBoolField";
import FormikValidation from "../FormikValidation";

import { persons as optionsPerson, areas as optionsArea, refTypes as optionsRefType, colorDataBackground as optionsColor } from '../../Config/constants.js';

function CustomerForm(props) {
	const [apiResponse, setApiResponse] = useState(null)
	const [onEdit] = useState(props.customer ? true : false)
	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(0);

	const phoneIndex = ["1", "2", "3"];

	let {customer, setInEdit} = props;

	const formik = useFormik({
		initialValues: {
			surname: apiResponse?.surname ?? "",
			name: apiResponse?.name ?? "",
			fiscalVatCode: apiResponse?.fiscalVatCode ?? "",
			person: apiResponse?.person ?? "",
			address: apiResponse?.address ?? "",
			number: apiResponse?.number ?? "",
			floor: apiResponse?.floor ?? "",
			city: apiResponse?.city ?? "",
			zipCode: apiResponse?.zipCode ?? "",
			area: apiResponse?.area ?? "",
			email: apiResponse?.email ?? "",
			phone_number_1: apiResponse?.phone_number_1 ?? "",
			ref_1: apiResponse?.ref_1 ?? "",
			ref_type_1: apiResponse?.ref_type_1 ?? "",
			phone_number_2: apiResponse?.phone_number_2 ?? "",
			ref_2: apiResponse?.ref_2 ?? "",
			ref_type_2: apiResponse?.ref_type_2 ?? "",
			phone_number_3: apiResponse?.phone_number_3 ?? "",
			ref_3: apiResponse?.ref_3 ?? "",
			ref_type_3: apiResponse?.ref_type_3 ?? "",
			notes: apiResponse?.notes ?? "",
			verified: apiResponse?.verified ?? "1",
			color: apiResponse?.color ?? "1",
			_method: onEdit ? 'put' : 'post',
		},
		validate : values => {
			setInEdit(true);
        },
		validationSchema: Yup.object({
			surname:
				Yup.string()
				.min(2, 'Lunghezza minima 2 caratteri')
				.max(50, 'Lunghezza massima 50 caratteri')
				.required('Campo obbligatorio'),
			name:
				Yup.string()
				.min(2, 'Lunghezza minima 2 caratteri')
				.max(50, 'Lunghezza massima 50 caratteri'),
			fiscalVatCode:
				Yup.string()
				.min(11, 'Lunghezza minima 11 caratteri')
				.max(16, 'Lunghezza massima 16 caratteri'),
			person:
				Yup.string()
				.required('Campo obbligatorio'),
			address:
				Yup.string()
				.min(2, 'Lunghezza minima 2 caratteri')
				.max(50, 'Lunghezza massima 50 caratteri')
				.required('Campo obbligatorio'),
			number:
				Yup.string()
				.max(10, 'Lunghezza massima 10 caratteri'),
			floor:
				Yup.string()
				.max(20, 'Lunghezza massima 20 caratteri'),
			city:
				Yup.string()
				.min(2, 'Lunghezza minima 2 caratteri')
				.max(50, 'Lunghezza massima 50 caratteri')
				.required('Campo obbligatorio'),
			zipCode:
				Yup.string()
				.length(5, 'Lunghezza 5 caratteri'),
			area:
				Yup.string()
				.length(2, 'Lunghezza 2 caratteri')
				.required('Campo obbligatorio'),
			email:
				Yup.string()
				.email('Formato email non valido')
				.max(30, 'Lunghezza massima 30 caratteri'),
			phone_number_1:
				Yup.string()
				.matches(/^\d{9,10}$/, 'Formato non valido'),
			ref_1:
				Yup.string()
				.max(50, 'Lunghezza massima 50 caratteri'),
			ref_type_1:
				Yup.string(),
			phone_number_2:
				Yup.string()
				.matches(/^\d{9,10}$/, 'Formato non valido'),
			ref_2:
				Yup.string()
				.max(50, 'Lunghezza massima 50 caratteri'),
			ref_type_2:
				Yup.string(),
			phone_number_3:
				Yup.string()
				.matches(/^\d{9,10}$/, 'Formato non valido'),
			ref_3:
				Yup.string()
				.max(50, 'Lunghezza massima 50 caratteri'),
			ref_type_3:
				Yup.string(),
			notes:
				Yup.string()
				.max(1000, 'Lunghezza massima 1000 caratteri'),
		}),
		onSubmit: values => {
			setLoading(true);

			try {
				let currEndpoint = onEdit ? `customer/${apiResponse.id}` : 'customer';
				Api.post({endpoint: currEndpoint, body: values, contentType : 'application/json'})
					.then(response => {
						setDisplayAlert(1);
						//let timer1 = setTimeout(() => {setLoading(false);clearTimeout(timer1)}, 3000);
					})
					.catch(error => {
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						else {
							console.log(error);
							alert("Errore inatteso!");
						}
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}
			setInEdit(false);
		},
		enableReinitialize: true,
	});

	function confirmGoBack(checkChanged) {
		if (checkChanged && Object.keys(formik.touched).length > 0) {
			if (!window.confirm("Uscire senza salvare le modifiche?")) {
				return false;
			}
		}
		return true;
	}

	function gotoBack(checkChanged) {
		return (
			<Link to={`/cms/customers`} style={{margin: 'auto'}}
				onClick={e => !confirmGoBack(checkChanged) && e.preventDefault()}
			>
				<Button
					variant="outlined"
					sx={{ ml: 3 }}
				>
					Torna alla lista!
				</Button>
			</Link>
		);
	}

	useEffect(() => {
        if (optionsColor[0].name !== "") {        
            optionsColor.unshift({ name: "", label: "[Nessuno]", hex: "" });
        }
    }, []);
    
	useEffect(() => {
		try {
			if (onEdit) {
				Api.get({endpoint: `customer/${customer}`, contentType : 'application/json'})
					.then(response => {
						setApiResponse(response.data.customer);
					})
					.catch(error => {
						console.log(error);
						setApiResponse(null);
						setDisplayAlert(-1);
					});
			}
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, [onEdit]);	// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<form
			autoComplete="off"
			noValidate
			onSubmit={formik.handleSubmit}
		>
			<Card>
				<CardHeader
					title="Cliente"
					subheader="Visualizza e modifica i dati del Cliente"
				/>
				<CardContent sx={{ pt: 0 }}>
						<div key="surname_name" className="row styled">
							<div key="surname" className="col-md-6">
								<FastTextField loading={loading} required formik={formik} label="Cognome/Rag.sociale" field="surname"/>
							</div>
							<div key="name" className="col-md-6">
								<FastTextField loading={loading} formik={formik} label="Nome" field="name"/>
							</div>
						</div>
						<div key="fiscalVatCodee_person" className="row styled">
							<div key="fiscalVatCode" className="col-md-6">
								<div key="r_fiscalVatCode" className="row">
									<div key="fiscalVatCode" className="col-md-6">
										<FastTextField loading={loading} formik={formik} label="Codice fiscale/P.Iva" field="fiscalVatCode"/>
									</div>
								</div>
							</div>
							<div key="person" className="col-md-6">
								<FastTextField loading={loading} required formik={formik} label="Tipo cliente" field="person" select
									child = {optionsPerson.map((person) => (
										<MenuItem key={person} value={person}>
											{person}
										</MenuItem>
									))}
								/>
							</div>
						</div>
						<div key="r_address_number_floor" className="row styled">
							<div key="address" className="col-md-6">
								<FastTextField loading={loading} required formik={formik} label="Indirizzo" field="address"/>
							</div>
							<div key="number_floor" className="col-md-6">
								<div key="r_number_floor" className="row">
									<div key="number" className="col-md-6">
										<FastTextField loading={loading} formik={formik} label="Civico" field="number"/>
									</div>
									<div key="floor" className="col-md-6">
										<FastTextField loading={loading} formik={formik} label="Piano/Scala" field="floor"/>
									</div>
								</div>
							</div>
						</div>
						<div key="city_zipCode_area" className="row styled">
							<div key="city" className="col-md-6">
								<FastTextField loading={loading} required formik={formik} label="Città" field="city"/>
							</div>
							<div key="zipCode_area" className="col-md-6">
								<div key="r_zipCode_area" className="row">
									<div key="zipCode" className="col-md-6">
										<FastTextField loading={loading} formik={formik} label="Cap" field="zipCode"/>
									</div>
									<div key="area" className="col-md-6">
										<FastTextField loading={loading} required formik={formik} label="Provincia" field="area" select
											child = {optionsArea.map((area) => (
												<MenuItem key={area} value={area}>
													{area}
												</MenuItem>
											))}
										/>
									</div>
								</div>
							</div>
						</div>
						<div key="r_email" className="row styled">
							<div key="email" className="col-md-6">
								<FastTextField loading={loading} formik={formik} label="Email" field="email"/>
							</div>
						</div>
						{phoneIndex.map(index => (
							<div key={`phone_number_ref_type_ref_${index}`} className="row styled">
								<div key={`phone_number_ref_type_${index}`} className="col-md-6">
									<div key={`r_phone_number_ref_type_${index}`} className="row">
										<div key={`phone_number_${index}`} className="col-md-6">
											<FastTextField loading={loading} formik={formik} label={`Telefono${index===1 ? " (Principale)" : ""}`} field={`phone_number_${index}`}/>
										</div>
										<div key={`ref_type_${index}`} className="col-md-6">
											<FastTextField loading={loading} formik={formik} label="Tipo contatto" field={`ref_type_${index}`} select
												child = {optionsRefType.map((refType) => (
													<MenuItem key={refType} value={refType}>
														{refType}
													</MenuItem>
												))}
											/>
										</div>
									</div>
								</div>
								<div key={`ref_${index}`} className="col-md-6">
									<FastTextField loading={loading} formik={formik} label="Dettagli/Note" field={`ref_${index}`}/>
								</div>
							</div>
						))}
						<div key="r_notes" className="row styled">
							<div key="notes" className="col-md-12">
								<FastTextField loading={loading} formik={formik} label="Note interne" field="notes"
									multiline
									rows={4}
								/>
							</div>
						</div>
						<div key="verified_color" className="row styled">
							<div key="verified" className="col-md-3">
								<FastBoolField loading={loading} formik={formik} label="Cliente verificato" field="verified"/>
							</div>
							<div key="color" className="col-md-3">

                                <FastTextField loading={loading} formik={formik} label="Colore" field="color" select
                                    child = {optionsColor.map((color) => (
                                        <MenuItem key={color.hex} value={color.name}>
                                            {`${color.label} ${color.name?'(' + color.name + ')':''}`}
                                        </MenuItem>
                                    ))}
                                />

							</div>                            
						</div>
						<div className="row styled">
							<div className="col">
								{loading && (
										<>
											<>
											{displayAlert === 0 && (
												<img className="pl-3" src={loadingGif} alt="Loading..."/>
											)}
											</>
											<>
											{displayAlert === -1 && (
												<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
											)}
											</>
											<>
											{displayAlert === 1 && (
												<Alert severity="success">
													{onEdit ? (
														<>Dati modificati con successo!</>
													) : (
														<>Dati salvati con successo!</>
													)}
													{gotoBack()}
												</Alert>
											)}
											</>
										</>
								)}
								{!loading && (
									<>
										<Button
											variant="contained"
											type="submit"
										>
											{onEdit ? (
												<>Modifica dati</>
											) : (
												<>Invia dati</>
											)}
										</Button>
										{gotoBack(true)}
									</>
								)}
							</div>
						</div>
						<FormikValidation formik={formik} />
				</CardContent>
			</Card>
			<FormikValidation formik={formik} tech={true} />
		</form>
	);

}

export default CustomerForm;