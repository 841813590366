import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useState } from 'react';

import { Dialog, DialogActions, DialogContent,DialogContentText, DialogTitle, Button, Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab/';

import InstallationList from "../Components/Installation/InstallationList";
import InstallationForm from "../Components/Installation/InstallationForm";

import Deadline from "./Deadline";

import Api from "../Services/Api";

function Installation() {
	let {installation, customer} = useParams();
	let location = useLocation();

	const pathname = location.pathname;

	const [displayDialog, setDisplayDialog] = useState(0);
	const [lastChildUpdate, setLastChildUpdate] = useState(null);
	const [tab, setTab] = useState("I");	//Installation, Deadline
	
	const [childInEdit, setChildInEdit] = useState(false);
	
	const handleClose = () => {
		setDisplayDialog(0);
	};

	const handleChange = (event, newValue) => {
		if (childInEdit && !window.confirm("Uscire senza salvare i dati sulla scheda attuale?")) {
			return;
		}	
		setChildInEdit(false);
		setTab(newValue);
	};
	
	async function selectedItem(eventType, id) {
		if (eventType === "edit") {
			navigate(`../installations/edit/${id}/${customer}`);
		}

		if (eventType === "create") {
			navigate(`../installations/create/${customer}`);
		}

		if (eventType === "delete") {
			try {
				Api.del({endpoint: `installation/${id}`, contentType : 'application/json'})
					.then(response => {
						setDisplayDialog(1);
						setLastChildUpdate(Date.now());
					})
					.catch(error => {
						console.log(JSON.stringify(error));
						setDisplayDialog(-1);
					});
			} catch (error) {
				console.log(JSON.stringify(error));
				setDisplayDialog(-1);
			}
		}
	};

	let navigate = useNavigate();

	return (
		<>
			{installation && (
				<TabContext value={tab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChange} aria-label="Installation">
							<Tab label="Anagrafica" value="I" />
							<Tab label="Scadenze" value="D" />
						</TabList>
					</Box>
					<TabPanel value="I"><InstallationForm installation={installation} customer={customer} setInEdit={setChildInEdit} /></TabPanel>
					<TabPanel value="D"><Deadline /></TabPanel>
				</TabContext>
			)}

			{!installation && (
				<>
				{pathname.includes("/create/") ? (
					<InstallationForm customer={customer} />
				) : (
					<InstallationList hook={selectedItem} lastUpdate={lastChildUpdate} customer={customer} />
				)}
				</>
			)}
			<Dialog
				open={displayDialog !== 0}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{displayDialog === 1 && "Operazione completata"}
					{displayDialog === -1 && "Errore"}
				</DialogTitle>
				<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{displayDialog === 1 && "Operazione completata con successo!"}
							{displayDialog === -1 && "Si è verificato un errore inatteso"}
						</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Chiudi</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default Installation;
