import { useState, useEffect } from 'react';
import { Button, Alert, MenuItem } from '@mui/material';
import { DialogContentText } from '@mui/material';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import loadingGif from '../../Assets/loading.gif';
import Api from '../../Services/Api';
import FastTextField from "../FastTextField";
import FormikValidation from "../FormikValidation";
import ReplyIcon from '@mui/icons-material/Reply';

function CustomerSmsForm(props) {
	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(0);

	const [listSmsTypesResponse, setListSmsTypesResponse] = useState(null)
	const [listSmsTypes, setListSmsTypes] = useState([])

	const [listInstallationsResponse, setListInstallationsResponse] = useState(null)
	const [listInstallations, setListInstallations] = useState([])

	const [listSmsRecipients, setListSmsRecipients] = useState([])
	const [signature, setSignature] = useState(null);
	const [customerText, setCustomerText] = useState(null);

	const { customerId } = props;

	useEffect(() => {
		try {
			Api.get({endpoint: `message/sms`, contentType : 'application/json'})
			.then(response => {
				setListSmsTypesResponse(response.data.messages);
				setSignature(response.data.signature);
			})
			.catch(error => {
				console.log(error);
				setListSmsTypesResponse(null);
				setDisplayAlert(-1);
			});

			Api.get({endpoint: `customer/smsDetails/${customerId}`, contentType : 'application/json'})
			.then(response => {
				setListSmsRecipients(response.data.recipients);
				setListInstallationsResponse(response.data.installations);
				setCustomerText(response.data.customer);
				if (response.data.installations?.length === 0) {
					setDisplayAlert(-2);
					setLoading(true);
				}
			})
			.catch(error => {	
				console.log(error);
				setListSmsRecipients(null);
				setDisplayAlert(-1);
			});
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		try {
			if (!listSmsTypesResponse) {
				setListSmsTypes(
					<MenuItem key="-1" value="-1">
						Caricamento in corso...
					</MenuItem>
				);
			}
			else {
				setListSmsTypes(
					listSmsTypesResponse.map((template) => (
						<MenuItem key={template.id} value={template.id}>
							<>
								{template.title}
							</>
						</MenuItem>
					))
				);
			}
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, [listSmsTypesResponse]);	// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		try {
			if (!listInstallationsResponse) {
				setListInstallations(
					<MenuItem key="-1" value="-1">
						Caricamento in corso...
					</MenuItem>
				);
			}
			else {
				setListInstallations(
					listInstallationsResponse.map((installation) => (
						<MenuItem key={installation.id} value={installation.id}>
							<>
								{installation.installation}
							</>
						</MenuItem>
					))
				);
			}
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, [listInstallationsResponse]);	// eslint-disable-line react-hooks/exhaustive-deps

	function preselectRecipient(number) {
		formik.setFieldValue("recipient", number);
	}

	function changeTemplate(template) {
		let messageId = template.props.value;
		let message = listSmsTypesResponse.filter(obj => {
			return obj.id === messageId
		})[0];

		formik.setFieldValue("message", message.message);
		formik.setFieldValue("sms_type_id", messageId);
	}

	const formik = useFormik({
		initialValues: {
			customer_id: customerId,
			installation_id: "",
			recipient: "",
			method: "sms",
			message: "",
		},
		validationSchema: Yup.object({
			installation_id:
				Yup.number()
				.integer("Valore non accettato")
				.min(1)
				.max(18446744073709551615, 'Valore non accettato')	//Bigint
				.required('Campo obbligatorio'),
			customer_id:
				Yup.number()
				.integer("Valore non accettato")
				.min(1)
				.max(18446744073709551615, 'Valore non accettato')	//Bigint
				.required('Campo obbligatorio'),
			recipient:
				Yup.string()
				.max(30, 'Lunghezza massima 30 caratteri')
				.required('Campo obbligatorio'),
			message:
				Yup.string()
				.max(1000, 'Lunghezza massima 1000 caratteri')
				.required('Campo obbligatorio'),
		}),
		onSubmit: values => {
			setLoading(true);

			try {
				Api.post({endpoint: `message/send`, body: values, contentType : 'application/json'})
					.then(response => {
						setDisplayAlert(1);
						//let timer1 = setTimeout(() => {setLoading(false);clearTimeout(timer1)}, 3000);
					})
					.catch(error => {
						if (error.response.status === 403) {
							if (error.response.data.code === 1000) {
								alert("A causa di errori del fornitore di servizio, non è stato possibile inviare il messaggio sms");
							}
							setDisplayAlert(0);
							setLoading(false);
							return;
						}							
						if (error.response.status === 412) {
							if (error.response.data.code === 1000) {
								alert("Per questo impianto non ci sono scadenze del tipo selezionato.\nMessaggio non inviato");
							}
							if (error.response.data.code === 1010) {
								alert("Per questo impianto non ci sono appuntamenti pregressi non chiusi e non cancellati.\nMessaggio non inviato");
							}							
							setDisplayAlert(0);
							setLoading(false);
							return;
						}						
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						else {
							console.log(error);
							alert("Errore inatteso!");
						}
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}
		},
		enableReinitialize: true,
	});

	return (
		<DialogContentText id="alert-dialog-description">
			<p className="subtitle">{customerText}</p>
			<form
				autoComplete="off"
				noValidate
				onSubmit={formik.handleSubmit}
			>
				<div className="container" style={{'minWidth': '500px'}}>
					<div key="r_installation_id" className="row styled">
						<div key="installation_id" className="col-md-12">
							<FastTextField loading={loading} required formik={formik} label="Impianto" field="installation_id" select
								child = {listInstallations}
							/>
						</div>
					</div>
					<div key="r_sms_type_id" className="row styled">
						<div key="sms_type_id" className="col-md-12">
							<FastTextField loading={loading} onChange={(e, value) => {changeTemplate(value)}} formik={formik} label="Modello SMS" field="sms_type_id" select
								child = {listSmsTypes}
							/>
						</div>
					</div>
					{listSmsRecipients && listSmsRecipients.length > 0 &&
						<div key="r_recipient_suggest" className="row styled">
							<div key="r_recipient_suggest" className="col-md-12">
								Numeri suggeriti (clicca per selezionare)
								<ul>
									{listSmsRecipients.map((recipient, index) => (
										<li style={{'cursor': 'pointer'}} onClick={() => preselectRecipient(recipient.number)}>{`${recipient.number} (${recipient.ref === '' ? '???' : recipient.ref})`}<ReplyIcon /></li>
									))}
								</ul>
								Modifica il numero qui sotto se necessario
							</div>
						</div>
					}
					<div key="r_recipient" className="row styled">
						<div key="recipient" className="col-md-12">
							<FastTextField loading={loading} required formik={formik} label="Numero telefonico" field="recipient" />
						</div>
					</div>

					<div key="r_message" className="row styled">
						<div key="message" className="col-md-12">
							<FastTextField loading={loading} required formik={formik} label="Testo del messaggio" field="message"
								multiline
								rows={4}
							/>
							<p style={{'fontSize': '0.8em'}}>Non modificare gli eventuali segnaposto [[xxxx]] presenti nel testo.<br />E' invece possibile rimuoverli o cambiargli posizione</p>
							{signature && <p style={{'fontSize': '0.8em'}}>
								In coda ad ogni messaggio verrà aggiunta la dicitura:<br /><strong>{signature}</strong>
							</p>}
						</div>
					</div>

					<div className="row styled">
						<div className="col">
							{loading && (
									<>
										<>
										{displayAlert === 0 && (
											<img className="pl-3" src={loadingGif} alt="Loading..."/>
										)}
										</>
										<>
										{displayAlert === -1 && (
											<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
										)}
										</>
										<>
										{displayAlert === -2 && (
											<Alert severity="warning">Non sono presenti impianti aperti per questo cliente!</Alert>
										)}
										</>
										<>
										{displayAlert === 1 && (
											<Alert severity="success">
												Messaggio inviato con successo!
											</Alert>
										)}
										</>
									</>
							)}
							{!loading && (
								<>
									<Button
										variant="contained"
										type="submit"
									>
										Invia dati
									</Button>
								</>
							)}
						</div>
					</div>
				</div> {/* container */}
				<FormikValidation formik={formik} />
			</form>
			<FormikValidation formik={formik} tech={true} />
		</DialogContentText>
	);
}

export default CustomerSmsForm;