import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid, itIT, GridToolbar, useGridApiRef } from '@mui/x-data-grid';		//https://mui.com/x/react-data-grid/ [MIT version]
import { Box, Card, CardHeader, CardContent, MenuItem, Alert, Button } from '@mui/material';
import moment from 'moment';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import FastInfoBlock from "../Components/FastInfoBlock";

import loadingGif from '../Assets/loading.gif';
import FastTextField from "../Components/FastTextField";
import FormikValidation from "../Components/FormikValidation";
import Api from '../Services/Api';
import {PAGE_SIZE, pageSizeOptions, areas as optionsArea} from '../Config/constants.js';

function Stamp() {
	const apiRef = useGridApiRef();	//https://mui.com/x/api/data-grid/
		
	const [rows, setRows] = useState([]);
	const [uploadedFile, setUploadedFile] = useState(null);
	const [displayAlertUpload, setDisplayAlertUpload] = useState(0);
	const [displayAlertDownload, setDisplayAlertDownload] = useState(0);
	const [displayAlert, setDisplayAlert] = useState(0);
	const [loadingUpload, setLoadingUpload] = useState(false);
	const [loadingDownload, setLoadingDownload] = useState(false);
	const [lastUpdate, setLastUpdate] = useState(null);
	
	const [listStampTypeResponse, setListStampTypeResponse] = useState(null);

	const columns = [
		{
			field: 'area',
			headerName: 'Provincia',
			flex: 1,
		},
		{
			field: 'code',
			headerName: 'Codice',
			flex: 2,
		},
		{
			field: 'uploaded_at',
			headerName: 'Data caricamento',
			flex: 1,
			valueGetter: (params) => {
				return moment(params.row.uploaded_at).format('DD/MM/YYYY HH:mm')
			},
		},
	];

	function setUploadFile(event) {
		if (event.target.files.length > 0) {
			let toUpload = event.target.files[0];
			//formik.setFieldValue("file_xlsx", toUpload.name);
			setUploadedFile(toUpload);
		}
	}
	
	function getStampTypes(area) {
		try {						
			Api.get({endpoint: `stamp/types/${area}`, contentType : 'application/json'})
			.then(response => {
				setListStampTypeResponse(response.data.stampTypes);
			})
			.catch(error => {
				console.log(JSON.stringify(error));
				setDisplayAlertUpload(-1);
			});
		} catch (error) {
			console.log(JSON.stringify(error));
			setDisplayAlertUpload(-1);
		}
	}
	
	function arrayStampType() {
		if (!listStampTypeResponse) {
			return (
				<MenuItem key="-1" value="-1">
					Caricamento in corso...
				</MenuItem>
			);
		}
		else {
			return (			
				listStampTypeResponse.map((stampType) => (
					<MenuItem key={stampType.id} value={stampType.id}>
						<>{stampType.min_power} kW -> {stampType.max_power} kW - {stampType.price} €</>
					</MenuItem>
				))
			);
		}
	}
	
	function resetUploadFile() {
		setUploadedFile(null);
	}

	useEffect(() => {	
		try {
			Api.get({endpoint: `stamp`, contentType : 'application/json'})
			.then(response => {
				setRows(response.data.stamps);
			})
			.catch(error => {
				console.log(JSON.stringify(error));
				setDisplayAlert(-1);
			});
		} catch (error) {
			console.log(JSON.stringify(error));
			setDisplayAlert(-1);
		}
	}, [lastUpdate]);	// eslint-disable-line react-hooks/exhaustive-deps

	const theme = createTheme({
			palette: {
				primary: { main: '#11cb5f' },
			},
		},
		itIT,
	);

	const validFileExtensions = { excel: ['xlsx'] };
	function isValidFileType(fileName, fileType) {
		if (!fileName)
			return true;

		return validFileExtensions[fileType].indexOf(fileName.toLowerCase().split('.').pop()) > -1;
	}	
	const formik = useFormik({
		initialValues: {
			number_from: "",
			number_to: "",
			area: "",
			stamp_type: "",
			file_xlsx: "",
		},
		validate : values => {
            let errors = {}
            if(values.number_from && uploadedFile){
                errors.number_from = "Campi Da..A e File xlsx sono alternativi"
                errors.file_xlsx = "Campi Da..A e File xlsx sono alternativi"
				return errors;
            }
            if(values.number_to && uploadedFile){
                errors.number_to = "Campi Da..A e File xlsx sono alternativi"
                errors.file_xlsx = "Campi Da..A e File xlsx sono alternativi"
				return errors;
            }
            if(values.number_to < values.number_from){
                errors.number_from = "Campi Da..A devono essere sequenziali"
                errors.number_to = "Campi Da..A devono essere sequenziali"
				return errors;
            }			
			if (values.number_to - values.number_from > 150){
                errors.number_from = "Per sicurezza, massimo 150 bollini per volta"
                errors.number_to = "Per sicurezza, massimo 150 bollini per volta"
				return errors;
			}			
			if(!errors.file_xlsx && ((!values.number_from && values.number_to) || (values.number_from && !values.number_to))) {
				errors.number_from = "Campi Da..A vanno popolati entrambi"
				errors.number_to = "Campi Da..A vanno popolati entrambi"
				return errors;
			}
			if(!uploadedFile && !values.number_from && !values.number_to) {
				errors.number_from = "Inserire questi valori o un file"
				errors.number_to = "Inserire questi valori o un file"
				errors.file_xlsx = "Inserire un file o i valori precedenti"
				return errors;
			}
			if(uploadedFile && !isValidFileType(uploadedFile?.name, "excel")) {
				errors.file_xlsx = "Formato file non valido"
				return errors;
			}
        },
		validationSchema: Yup.object({
			number_from:
				Yup.number()
				.integer("Valore non accettato")
				.min(1)
				.max(18446744073709551615, 'Valore non accettato'),	//Bigint
			number_to:
				Yup.number()
				.integer("Valore non accettato")
				.min(1)
				.max(18446744073709551615, 'Valore non accettato'),	//Bigint
			area:
				Yup.string()
				.length(2, 'Lunghezza 2 caratteri')
				.required('Campo obbligatorio'),
			stamp_type:
				Yup.string()
				.required('Campo obbligatorio'),				
		}),
		onSubmit: values => {
			setLoadingUpload(true);			
			try {
				let data = new FormData();
				data.append('number_from', values.number_from);
				data.append('number_to', values.number_to);
				uploadedFile && data.append('file_xlsx', uploadedFile);
				data.append('area', values.area);
				data.append('stamp_type', values.stamp_type);

				Api.post({endpoint: 'stamp', body: data, contentType : 'application/json'}, true)
					.then(response => {
						setDisplayAlertUpload(1);
						setLastUpdate(Date.now());
						//let timer1 = setTimeout(() => {setLoadingUpload(false);clearTimeout(timer1)}, 3000);
					})
					.catch(error => {
						if (error.response.status === 422) {
							if (error.response.data.error === 1000) {
								alert("Il formato del file importato non corrisponde alle specifiche.\nOperazione annullata!");
							}
							else if (error.response.data.error === 1001) {
								alert("Il file importato era già stato caricato su questa Provincia.\nOperazione annullata!");
							}
							else {
								var errors = JSON.parse(error.request.response);
								if (errors.hasOwnProperty('errors')) {
									var alertMessage = "Si sono verificati errori nei dati:";
									for (var errorField in errors.errors) {
										alertMessage += "\n" + errors.errors[errorField].join(',');
									}
									alert(alertMessage);
									setDisplayAlertUpload(0);
									setLoadingUpload(false);
								}
								else {
									console.log(error);
								}
							}

							setDisplayAlertUpload(0);
							setLoadingUpload(false);							
							return;
						}
						else {
							console.log(error);
							alert("Errore inatteso!");
						}
						setDisplayAlertUpload(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlertUpload(-1);
			}
		},
		enableReinitialize: true,
	});

	function download() {
	
		//Create form around input to gain yup validation of fields
	
		setDisplayAlertDownload(1);
		setLoadingDownload(true);
		
	}
	
	return (
		<>
			<h5>Bollini Provincia utilizzabili</h5>
			<Box sx={{ width: '100%', minWidth: '800px' }}>
				<ThemeProvider theme={theme}>
					<DataGrid
						//onRowDoubleClick={hook}
						sx={{ minHeight: '300px' }}
						rows={rows}
						columns={columns}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: PAGE_SIZE,
								},
							},
						}}
						apiRef={apiRef}
						slots={{
							toolbar: GridToolbar,
						}}
						slotProps={{
							columnsPanel: {
								disableHideAllButton: true,
							}
						}}
						pageSizeOptions={pageSizeOptions}
					/>					
					<>
					{displayAlert === -1 && (
						<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
					)}
					</>


				</ThemeProvider>
			</Box>

			<form
				autoComplete="off"
				noValidate
				onSubmit={formik.handleSubmit}
			>
				<Card
					sx={{
						marginTop: '20px',
					}}
				>
					<CardHeader
						title="Carica bollini"
						subheader="Carica nuovi bollini sul sistema"
					/>
					<CardContent sx={{ pt: 0 }}>
						<div key="number_from_number_to_number_alert" className="row styled">
							<div key="number_from_number_to" className="col-md-6">
								<div key="r_number_from_number_to" className="row">
									<div key="number_from" className="col-md-6">
										<FastTextField loading={loadingUpload} formik={formik} label="Numero iniziale" field="number_from" type="number"/>
									</div>
									<div key="number_to" className="col-md-6">
										<FastTextField loading={loadingUpload} formik={formik} label="Numero finale" field="number_to" type="number"/>
									</div>
								</div>
							</div>
							<div key="number_alert" className="col-md-6">
								<p>Numero iniziale e Numero finale sono da utilizzare per i bollini NUMERICI sequenziali: il sistema genererà tutti i bollini compresi tra gli estremi (inclusi) - <em>Questa voce e la successiva sono alternative</em></p>
							</div>
						</div>
						<div key="file_xlsx_file_xlsx_alert" className="row styled">
							<div key="file_xlsx" className="col-md-6">
								{uploadedFile ? (
									<>
										<FastInfoBlock
											label="File excel" content={uploadedFile?.name}
											helperText={formik.touched['file_xlsx'] && formik.errors['file_xlsx'] ? formik.errors['file_xlsx'] : null}
											error={Boolean(formik.touched['file_xlsx'] && formik.errors['file_xlsx'])}
											squeeze={80}
										/>
										<Button
											disabled={loadingUpload}
											onClick={resetUploadFile}
											variant="contained"
											type="submit"
											style={{
												marginLeft: '20px',
											}}
										>
											Cambia file
										</Button>
									</>

								) : (
									<FastTextField
										loading={loadingUpload} formik={formik} label="File excel" field="file_xlsx" type="file"
										InputProps={{
											style: {paddingLeft: '100px'},
										}}
										onChange={(e) => {
											setUploadFile(e);
										}}
									/>
								)}
							</div>
							<div key="file_xlsx_alert" className="col-md-6">
								<p>Il file Excel (xlsx) è da utilizzare per i bollini ALFANUMERICI casuali: il sistema acquisità tutti i bollini presenti da B3 in sotto - <em>Questa voce e la precedente sono alternative</em></p>
							</div>
						</div>
						<div key="area_area_alert" className="row styled">
							<div key="area" className="col-md-6">
								<FastTextField loading={loadingUpload} required formik={formik} label="Provincia" field="area" select
									onChange={ event => {
										const { value } = event.target;
										formik.setFieldValue("area", value);	//Fix MUI not change the displayed value if you select a different element
										getStampTypes(value);										
									}}
									child = {optionsArea.map((area) => (
										<MenuItem key={area} value={area}>
											{area}
										</MenuItem>
									))}
								/>						
							</div>
							<div key="area_alert" className="col-md-6">
								<p>Selezionare la provincia a cui i bollini faranno riferimento - Il dato non è modificabile a posteriori</p>
							</div>
						</div>
						<div key="stamp_type_stamp_type_alert" className="row styled">
							<div key="stamp_type" className="col-md-6">
								<FastTextField loading={loadingUpload} required formik={formik} label="Tipo bollino" field="stamp_type" select
									child = {arrayStampType()}
								/>
							</div>
							<div key="stamp_type_alert" className="col-md-6">
								<p>Selezionare il tipo di bollino per la provincia scelta - Il dato non è modificabile a posteriori</p>
							</div>
						</div>
						<div className="row styled">
							<div className="col">
								{loadingUpload && (
										<>
											<>
											{displayAlertUpload === 0 && (
												<img className="pl-3" src={loadingGif} alt="Loading..."/>
											)}
											</>
											<>
											{displayAlertUpload === -1 && (
												<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
											)}
											</>
											<>
											{displayAlertUpload === 1 && (
												<Alert severity="success">
													Dati caricati con successo!
												</Alert>
											)}
											</>
										</>
								)}
								{!loadingUpload && (
									<>
										<Button
											variant="contained"
											type="submit"
										>
											Invia dati
										</Button>
									</>
								)}
							</div>
						</div>
						<FormikValidation formik={formik} />
					</CardContent>
				</Card>
				<FormikValidation formik={formik} tech={true} />
			</form>					
			<Card
				sx={{
					marginTop: '20px',
				}}
			>
				<CardHeader
					title="Esporta report bollini digitale"
					subheader="Produce il file da caricare su piattaforma della Provincia"
				/>
				<CardContent sx={{ pt: 0 }}>
					<div key="date_from_date_to_area" className="row styled">
						<div key="date_from" className="col-md-4">							
							<FastTextField loading={loadingDownload} required formik={formik} label="Data inizio" field="date_from" type="date"/>
						</div>
						<div key="date_to" className="col-md-4">							
							<FastTextField loading={loadingDownload} required formik={formik} label="Data fine" field="date_to" type="date"/>
						</div>						
						<div key="area" className="col-md-4">							
							<FastTextField loading={loadingDownload} required formik={formik} label="Provincia" field="area" select
								child = {optionsArea.map((area) => (
									<MenuItem key={area} value={area}>
										{area}
									</MenuItem>
								))}
							/>
						</div>																		
					</div>
					<div className="row styled">
						<div className="col">
							{loadingDownload && (
									<>
										<>
										{displayAlertDownload === 0 && (
											<img className="pl-3" src={loadingGif} alt="Loading..."/>
										)}
										</>
										<>
										{displayAlertDownload === -1 && (
											<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
										)}
										</>
										<>
										{displayAlertDownload === 1 && (
											<Alert severity="success">
												Dati esportati con successo!
											</Alert>
										)}
										</>
									</>
							)}
							{!loadingDownload && (
								<>
									<Button
										variant="contained"
										onClick={download}
									>
										Esporta dati
									</Button>
								</>
							)}
						</div>
					</div>

				</CardContent>
			</Card>
			
		</>
	);
}

export default Stamp;
