import { Link, Outlet } from 'react-router-dom';
import { useAuth } from './Auth/ProvideAuth'

import './app.css';
import logoMain from './Assets/logoMain.png';
import {APP_NAME} from './Config/constants.js';

import withClearCache from "./Services/Cache/ClearCache";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
    return (<ClearCacheComponent />)
}

function MainApp() {
	let auth = useAuth();

	return (
		<>
			<div className="welcome">
				<div className="logo">					
					{!auth.lonyUser ? (
						<Link to={`login`}>
							<img src={logoMain} alt={APP_NAME} title={APP_NAME} />
						</Link>
					) : (
						<Link to={`cms`}>
							<img src={logoMain} alt={APP_NAME} title={APP_NAME} />
						</Link>
					)}
				</div>                
			</div>
			<Outlet />
		</>
	)

}

export default App;
