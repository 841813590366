import { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Alert, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import loadingGif from '../../Assets/loading.gif';
import Api from '../../Services/Api';
import FastTextField from "../FastTextField";
import FormikValidation from "../FormikValidation";

function SparePurchaseForm(props) {
	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(0);
	const [listSuppliersResponse, setListSuppliersResponse] = useState(null)

	let spare = props.spare;
	const theme = useTheme();

	function arraySuppliersResponse() {
		if (!listSuppliersResponse) {
			return (
				<MenuItem key="-1" value="-1">
					Caricamento in corso...
				</MenuItem>
			);
		}
		else {
			return (
				listSuppliersResponse.map((supplier) => (
					<MenuItem key={supplier.id} value={supplier.id}>
						{supplier.brand}
					</MenuItem>
				))
			);
		}
	}

	//This form can be called only on "CREATE" mode
	const formik = useFormik({
		initialValues: {
			quantity: "",
			unit_price: "",
			sale_price: "",
			document_number: "",
			document_date: "",
			_method: 'post',
			supplier_id: "",
		},
		validationSchema: Yup.object({
			quantity:
				Yup.number()
				.min(0, 'Valore minimo 0')
				.max(999999.99, 'Valore massimo 999999.99')
				.required('Campo obbligatorio'),
			unit_price:
				Yup.number()
				.min(0, 'Valore minimo 0')
				.max(999999.99, 'Valore massimo 999999.99'),
			sale_price:
				Yup.number()
				.min(0, 'Valore minimo 0')
				.max(999999.99, 'Valore massimo 999999.99'),
			document_number:
				Yup.string()
				.max(10, 'Lunghezza massima 10 caratteri'),
			document_date:
				Yup.date(),
			supplier_id:
				Yup.number()
				.integer("Valore non accettato")
				.min(1)
				.max(18446744073709551615, 'Valore non accettato')	//Bigint
				.required('Campo obbligatorio'),
		}),
		onSubmit: values => {
			setLoading(true);

			try {
				Api.post({endpoint: `spare/purchase/${spare.id}`, body: values, contentType : 'application/json'})
					.then(response => {
						setDisplayAlert(1);
						formik.resetForm();
						let timer1 = setTimeout(() => {
							props.onClose();							
							setLoading(false);
							clearTimeout(timer1)
						}, 2000);
					})
					.catch(error => {
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						else {
							console.log(error);
							alert("Errore inatteso!");
						}
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}
		},
		enableReinitialize: true,
	});

	function onClose() {
		if (Object.keys(formik.touched).length > 0) {
			if (!window.confirm("Uscire senza salvare le modifiche?")) {
				return false;
			}
		}
		props.onClose();
	}

	useEffect(() => {
		try {
			Api.get({endpoint: `supplier`, contentType : 'application/json'})
				.then(response => {
					setListSuppliersResponse(response.data.suppliers);
				})
				.catch(error => {
					console.log(error);
					setListSuppliersResponse(null);
					setDisplayAlert(-1);
				});
		} catch (error) {
			console.log(error);
			return (error.response ? error.response.status : null);
		}
	}, []);


	return (
		<>
			<Dialog
				open={props.open !== 0}
				onClose={props.onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Nuovo acquisto</DialogTitle>
				<DialogContent>
					<strong style={{color: theme.palette.primary.darkMain, fontSize: '0.8em'}}>
						{spare?.description}
					</strong>
					<DialogContentText id="alert-dialog-description">
						<form
							autoComplete="off"
							noValidate
							onSubmit={formik.handleSubmit}
						>
							<div className="container" style={{'minWidth': '400px'}}>
								<div key="r_supplier_id" className="row styled">
									<div key="supplier_id" className="col-md-12">
										<FastTextField loading={loading} required formik={formik} label="Fornitore" field="supplier_id" select
											child = {arraySuppliersResponse()}
										/>
									</div>
								</div>
								<div key="r_quantity" className="row styled">
									<div key="quantity" className="col-md-12">
										<FastTextField required loading={loading} formik={formik} label="Quantità" field="quantity"	type="number" />
									</div>
								</div>
								<div key="r_unit_price" className="row styled">
									<div key="unit_price" className="col-md-12">
										<FastTextField loading={loading} formik={formik} label="Prezzo unitario" field="unit_price" type="number" />
									</div>
								</div>
								<div key="r_sale_price" className="row styled">
									<div key="sale_price" className="col-md-12">
										<FastTextField loading={loading} formik={formik} label="Prezzo unitario vendita" field="sale_price" type="number" />
									</div>
								</div>							
								<div key="r_document_number" className="row styled">
									<div key="document_number" className="col-md-12">
										<FastTextField loading={loading} formik={formik} label="Numero DDT" field="document_number" />
									</div>
								</div>
								<div key="r_document_date" className="row styled">
									<div key="document_date" className="col-md-12">
										<FastTextField loading={loading} formik={formik} label="Data DDT" field="document_date" type="date"/>
									</div>
								</div>
								<div className="row styled">
									<div className="col">
										{loading && (
												<>
													<>
													{displayAlert === 0 && (
														<img className="pl-3" src={loadingGif} alt="Loading..."/>
													)}
													</>
													<>
													{displayAlert === -1 && (
														<Alert severity="error">Si è verificato un errore imprevisto. Riprovare più tardi</Alert>
													)}
													</>
													<>
													{displayAlert === 1 && (
														<Alert severity="success">Dati salvati con successo!</Alert>
													)}
													</>
												</>
										)}
										{!loading && (
											<>
												<Button
													variant="contained"
													type="submit"
												>
													Invia dati
												</Button>
											</>
										)}
									</div>
								</div>
							</div> {/* container */}
							<FormikValidation formik={formik} />
						</form>
						<FormikValidation formik={formik} tech={true} />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Chiudi</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default SparePurchaseForm;