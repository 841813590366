import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useState } from 'react';

import { Dialog, DialogActions, DialogContent,DialogContentText, DialogTitle, Button } from '@mui/material';

import AnnotationList from "../Components/Annotation/AnnotationList";
import AnnotationForm from "../Components/Annotation/AnnotationForm";

import Api from "../Services/Api";

function Annotation() {
	let {annotation, customer} = useParams();
	let location = useLocation();

	const pathname = location.pathname;

	const [displayDialog, setDisplayDialog] = useState(0);
	const [lastChildUpdate, setLastChildUpdate] = useState(null);

	const handleClose = () => {
		setDisplayDialog(0);
	};

	async function selectedItem(eventType, id) {
		if (eventType === "edit") {
			navigate(`../annotations/edit/${id}/${customer}`);
		}
		if (eventType === "create") {
			navigate(`../annotations/create/${customer}`);
		}

		if (eventType === "delete") {
			try {
				Api.del({endpoint: `annotation/${id}`, contentType : 'application/json'})
					.then(response => {
						setDisplayDialog(1);
						setLastChildUpdate(Date.now());
					})
					.catch(error => {
						console.log(JSON.stringify(error));
						setDisplayDialog(-1);
					});
			} catch (error) {
				console.log(JSON.stringify(error));
				setDisplayDialog(-1);
			}
		}
	};

	let navigate = useNavigate();

	return (
		<>
			{annotation && <AnnotationForm annotation={annotation} customer={customer} />}
			{!annotation && (
				<>
				{pathname.includes("/create/") ? (
					<AnnotationForm customer={customer} />
				) : (
					<AnnotationList hook={selectedItem} lastUpdate={lastChildUpdate} customer={customer} />
				)}
				</>
			)}
			<Dialog
				open={displayDialog !== 0}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{displayDialog === 1 && "Operazione completata"}
					{displayDialog === -1 && "Errore"}
				</DialogTitle>
				<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{displayDialog === 1 && "Operazione completata con successo!"}
							{displayDialog === -1 && "Si è verificato un errore inatteso"}
						</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Chiudi</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default Annotation;
